import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useProduct from "../../../hooks/use-shelfive-product";
import { Carousel } from "primereact/carousel";
import SfProductThumb from "./SfProductThumb";

const SfProductSame = (props) => {
  const { gtin } = { ...props };
  const { t } = useTranslation();
  const [sameProducts, setSameProducts] = useState(null);
  const { getSameProducts, isLoading, error } = useProduct();

  const windowWidth = window.innerWidth;

  useEffect(() => {
    const _fetch = async () => {
      const json = await getSameProducts(gtin);
      setSameProducts(json);
    };

    _fetch();
  }, [getSameProducts, gtin]);

  if (isLoading) {
    return;
  }
  if (error) {
    return ;
  }

  if (!sameProducts || sameProducts.length === 0) {
    return <div></div>;
  }

  const sameProduct = (product) => <SfProductThumb product={product} />;

  return (
    <div className="col-12 lg:col-4 pt-3 pl-3 lg:pl-3">
      <Carousel
        header={t("similar_products")+":"}
        containerClassName="mt-2"
        indicatorsContentClassName="pb-0"
        value={sameProducts}
        itemTemplate={sameProduct}
        numVisible={windowWidth < 1366 ? 2 : 3}
        numScroll={1}
        showNavigators={sameProducts.length > 3}
      ></Carousel>
    </div>
  );
};

export default SfProductSame;
