import { Dropdown } from "primereact/dropdown";

const SfSelectBase = (props) => {
    const classes = props.styling ? props.styling : "sf-dropdown";

    return (
        <Dropdown
          {...props}
          className={classes}
        />
      );
};

export default SfSelectBase;