import { useState, useEffect, useCallback } from "react";

const resizeFrequency = 500;

const useResize = () => {
  const [canvass, setCanvass] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const debounce = useCallback((fn, ms) => {
    let timer;

    return (_) => {
      clearTimeout(timer);
      timer = setTimeout((_) => {
        timer = null;
        fn.apply(this);

      }, ms);
    };
  }, []);

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setCanvass({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, resizeFrequency);

    window.addEventListener("resize", debouncedHandleResize);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  return canvass; 
};

export default useResize;
