import { useState, useRef, useCallback, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import useNavigation from "../../../hooks/use-navigation";
import SfButton from "../../ui/wrappers/SfButton";
import SfInput from "../../ui/wrappers/SfInput";
import { OverlayPanel } from "primereact/overlaypanel";
import SfDialog from "../../ui/wrappers/SfDialog";
import SfBarCodeScanner from "../../misc/SfBarCodeScanner";

const SfSearchEngineBar = (props) => {
  const bigBar = props.size === "big";
  const { t } = useTranslation();
  const [search, setSearch] = useState({});
  const [showScanner, setShowScanner] = useState(false);
  const advancedSearchPanel = useRef();
  const refBigBar = useRef();
  const refSmallBar = useRef();
  const onSearchHandler = props.onSearch;
  const giveItFocus = props.focus;
  const { routes, goTo } = useNavigation();

  useEffect(() => {
    if (giveItFocus) {
      refBigBar?.current?.focus();
      refSmallBar?.current?.focus();
    }
  }, [giveItFocus]);

  const toggleAdvancedSearch = useCallback((e) => {
    advancedSearchPanel.current.toggle(e);
  }, []);

  const setFormField = useCallback((e) => {
    setSearch((prev) => {
      return { ...prev, [e.target.id]: e.value ?? e.target.value };
    });
  }, []);

  const startSearchHandler = useCallback(
    (e) => {
      e.preventDefault();

      if (search.q && search.q.trim().length > 0) {
        if (onSearchHandler) {
          onSearchHandler();
        }

        goTo(routes.search, { qs: search });
      }
    },
    [goTo, routes.search, search, onSearchHandler]
  );

  const getOverlayPanel = () => {
    return (
      <>
        <OverlayPanel ref={advancedSearchPanel}>
          <div className="text-sm">
            <div>{t("scanner_head_1")}</div>
            <div>{t("scanner_head_2")}</div>
            <div className="sf-underline" onClick={openScanner}>
              {t("scanner_open_link")}
            </div>
          </div>
        </OverlayPanel>
        <SfDialog
          styling="sf-dialog-nohead"
          visible={showScanner}
          closable={false}
          showHeader={false}
        >
          <SfBarCodeScanner visible={showScanner} onHide={setShowScanner} />
        </SfDialog>
      </>
    );
  };

  const openScanner = () => {
    advancedSearchPanel.current.hide();
    setShowScanner(true);
  };

  if (bigBar) {
    return (
      <div id="theBigShelfiveBar" className="col-12 md:col-6">
        <form onSubmit={startSearchHandler}>
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon border-circle-left">
              <i className="pi pi-search"></i>
            </span>
            <SfInput
              placeholder="search_bar_placeholder"
              id="q"
              value={search.q ?? ""}
              onChange={setFormField}
              styling="text-lg"
              autoComplete={isMobile ? "off" : "on"}
              type="search"
              ref={refBigBar}
            />
            {!isMobile && (
              <>
                <SfButton
                  icon="pi pi-camera"
                  styling="border-circle-right cursor-pointer"
                  onClick={toggleAdvancedSearch}
                  type="button"
                />
                {getOverlayPanel()}
              </>
            )}
            {isMobile && (
              <SfButton
                icon="pi pi-angle-right"
                styling="border-circle-right cursor-pointer"
                type="submit"
              />
            )}
          </div>
        </form>
      </div>
    );
  }

  if (!bigBar) {
    return (
      <div className="w-12 xl:w-8 h-2rem">
        <form onSubmit={startSearchHandler}>
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="pi pi-search text-sm"></i>
            </span>
            <SfInput
              placeholder="search_bar_placeholder"
              id="q"
              value={search.q ?? ""}
              onChange={setFormField}
              styling="text-base sf-top-searchbar mt-0"
              autoComplete={isMobile ? "off" : "on"}
              type="search"
              ref={refSmallBar}
              />
            {!isMobile && (
              <SfButton
                icon="pi pi-camera"
                styling="cursor-pointer p-button-sm p-0 p-button-secondary text-sm"
                onClick={toggleAdvancedSearch}
                style={{ width: "2rem" }}
                type="button"
              />
            )}
            {isMobile && (
              <SfButton
                icon="pi pi-angle-right"
                styling="cursor-pointer p-button-sm p-0 p-button-secondary text-sm"
                type="submit"
              />
            )}
          </div>
        </form>
        {getOverlayPanel()}
      </div>
    );
  }
};

export default SfSearchEngineBar;
