import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { InputText } from "primereact/inputtext";

const SfInput = forwardRef((props, ref) => {
  const { t } = useTranslation("buttons");
  const i18Props = { ...props, placeholder: t(props.placeholder) };
  const classes = props.styling ? props.styling : "text-sm";

  return <InputText {...i18Props} className={classes} ref={ref} />;
});

export default SfInput;
