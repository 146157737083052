import { useState } from "react";
import { useTranslation } from "react-i18next";
import SfSelect from "../ui/wrappers/SfSelect";
import { UI_LANGUAGES, getCurrentLanguage } from "../../scripts/sw-utils";

const SfLangSelector = () => {
  const { i18n } = useTranslation();
  const currentLanguage = getCurrentLanguage(i18n);
  const [language, setLanguage] = useState(currentLanguage);

  const changeLanguage = (e) => {
    setLanguage(e.value);
    i18n.changeLanguage(e.value);
  };

  return (
    <div className="p-2 pt-3 flex align-items-center justify-content-center">
      <SfSelect
        value={language}
        options={UI_LANGUAGES}
        onChange={changeLanguage}
      />
    </div>
  );
};

export default SfLangSelector;
