import { useState } from "react";
import { useTranslation } from "react-i18next";
import useResize from "../../hooks/use-resize";
import SfSorter from "./SfSorter";
import SfChip from "../ui/wrappers/SfChip";
import SfInput from "../ui/wrappers/SfInput";

const SfResultBar = ({ config, filters }) => {
  const [filterText, setFilterText] = useState("");
  const { t } = useTranslation();
  const {
    count,
    title,
    sortFields,
    sortHandler,
    currentSorting,
    onAddFilter,
    onRemoveFilter,
    showTextFilterBar,
  } = {
    ...config,
  };

  const dimensions = useResize();
  const smallScreen = dimensions.width < 700;

  const displayFilters = () => {
    return (
      <>
        {filters?.brand && (
          <span className="pl-3">
            <SfChip
              label={t("filter_brand") + ": " + filters.brand}
              removable
              onRemove={onRemoveFilter}
            />
          </span>
        )}

        {filters?.stars && (
          <span className="pl-3">
            <SfChip
              label={t("filter_stars") + ": " + filters.stars}
              removable
              onRemove={() => onRemoveFilter("star")}
            />
          </span>
        )}

        {filters?.reviewContains && (
          <span className="pl-3">
            <SfChip
              label={
                t("filter_contains") + ': "' + filters.reviewContains + '"'
              }
              removable
              onRemove={() => onRemoveFilter("contains")}
            />
          </span>
        )}
      </>
    );
  };

  const applyTextFilter = (e) => {
    e.preventDefault();

    if (filterText?.trim().length > 0) {
      onAddFilter(filterText.trim());
    }
  };

  const displayTextFilterBar = () => {
    return (
      <form onSubmit={applyTextFilter} className="inline-flex">
      <div className={"p-inputgroup " + (smallScreen ? "ml-5 w-14rem mb-1" : "mr-5 w-15rem")}>
        <span className="p-inputgroup-addon">
          <i className="pi pi-search text-sm"></i>
        </span>
        <SfInput
          placeholder="filter_bar_placeholder"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          styling="text-sm sf-top-searchbar"
          autoComplete="off"
          type="search"
        />
      </div>
      </form>
    );
  };

  return (
    <div className="min-h-2rem w-12 grid align-items-center mb-2 mt-1 md:mt-0">
      <div className="col-12 md:col ml-3 text-base mt-1 md:mt-0 mb-0 font-medium flex align-items-center">
        <div className="w-full md:w-auto">
          {t(title)}:
          <span className="font-semibold text-primary text-lg pl-1">
            {count ?? 0}
          </span>
          {showTextFilterBar && smallScreen && displayTextFilterBar()}
        </div>
        <div>{!smallScreen && displayFilters()}</div>
      </div>
      <div>{smallScreen && displayFilters()}</div>
      <div className="col-12 md:col text-right mr-0 pt-0 flex align-items-center justify-content-end">
        {showTextFilterBar && !smallScreen && displayTextFilterBar()}
        {count > 1 && (
          <SfSorter
            sortFields={sortFields}
            sortHandler={sortHandler}
            currentSorting={currentSorting}
          />
        )}
      </div>
    </div>
  );
};

export default SfResultBar;
