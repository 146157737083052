import { useState, useCallback } from "react";
import useApi from "./use-api";

const useShelfiveProduct = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const invokeApi = useApi(setIsLoading, setError);

  const getProductList = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/get-product-list",
        method: "GET",
        query: query,
        auth: true,
      });
    },
    [invokeApi]
  );

  const getProductDetails = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/get-product-details",
        method: "GET",
        query: query,
        auth: true,
      });
    },
    [invokeApi]
  );

  const getProductPictures = useCallback(
    async (gtin) => {
      return await invokeApi({
        endpoint: "/get-product-pictures",
        method: "GET",
        query: { product_gtin: gtin },
      });
    },
    [invokeApi]
  );

  const getSameProducts = useCallback(
    async (gtin) => {
      return await invokeApi({
        endpoint: "/get-same-products",
        method: "GET",
        query: { product_gtin: gtin },
      });
    },
    [invokeApi]
  );

  const getReviewSummary = useCallback(
    async (gtin) => {
      return await invokeApi({
        endpoint: "/get-review-summary",
        method: "GET",
        query: { product_gtin: gtin },
      });
    },
    [invokeApi]
  );

  const addFavorite = useCallback(
    async (gtin) => {
      return await invokeApi({
        endpoint: "/add-favorite",
        method: "POST",
        query: { product_gtin: gtin },
        auth: true,
      });
    },
    [invokeApi]
  );

  const removeFavorite = useCallback(
    async (gtin) => {
      return await invokeApi({
        endpoint: "/remove-favorite",
        method: "POST",
        query: { product_gtin: gtin },
        auth: true,
      });
    },
    [invokeApi]
  );

  const createProduct = useCallback(
    async (gtin) => {
      return await invokeApi({
        endpoint: "/create-product",
        method: "POST",
        query: { product_gtin: gtin },
        auth: true,
      });
    },
    [invokeApi]
  );
 
  const customizeProduct = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/customize-product",
        method: "POST",
        query: query,
        auth: true,
      });
    },
    [invokeApi]
  );

  const createProductWarning = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/create-product-warning",
        method: "POST",
        query: query,
        auth: true,
      });
    },
    [invokeApi]
  );

  const modifyProduct = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/modify-product",
        method: "POST",
        query: query,
        auth: true,
      });
    },
    [invokeApi]
  );

  const getSEOList = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/get-seo-list",
        method: "GET",
        query: query,
      });
    },
    [invokeApi]
  );

  return {
    isLoading,
    error,
    getProductList,
    getProductDetails,
    getProductPictures,
    getSameProducts,
    getReviewSummary,
    addFavorite,
    removeFavorite,
    createProduct,
    customizeProduct,
    createProductWarning,
    modifyProduct,
    getSEOList
  };
};

export default useShelfiveProduct;
