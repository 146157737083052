import { useState, useEffect, useMemo, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import useRoles from "../../hooks/use-roles";
import useNavigation from "../../hooks/use-navigation";
import useTitle from "../../hooks/use-title";
import useProduct from "../../hooks/use-shelfive-product";
import {
  qsGetFromUrl,
  qsGetParam,
  QS_MAX_START,
  isValidGtin,
} from "../../scripts/sw-utils";
import SfLoading from "../ui/SfLoading";
import SfNodata from "../ui/SfNodata";
import SfResultBar from "../data/SfResultBar";
import SfProductCard from "../app/product/SfProductCard";
import SfPaginator from "../ui/wrappers/SfPaginator";
import SfError from "../ui/SfError";
import SfNoLogin from "../ui/SfNoLogin";
import SfProductSearchFilters from "../app/product/SfProductSearchFilter";
import { PAGE_LIMIT, FILTER_THRESHOLD } from "../../scripts/sw-utils";

const defaultQuery = {
  q: "",
  brand: "",
  start: 1,
  limit: PAGE_LIMIT,
  sorting: 0,
};

let currentRowCount;

const SfRouteSearch = ({ favorite }) => {
  const { isLogged } = useRoles();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getProductList, isLoading, error } = useProduct();
  const [searchResults, setSearchResults] = useState(null);
  const { routes, goTo, setBackUrl } = useNavigation();
  const setTitle = useTitle();
  const showFavorites = favorite && isLogged;
  const pageAllowed = !favorite || isLogged;
  const qs = useMemo(() => qsGetFromUrl(searchParams), [searchParams]);

  const sortFields = useMemo(
    () => [
      {
        field: showFavorites
          ? "oby_fav_relevance"
          : isLogged
          ? "oby_relevance_logged"
          : "oby_relevance",
        label: showFavorites ? "sort_newest_favorite" : "sort_relevance",
      },
      {
        field: showFavorites ? "oby_fav_brand" : "oby_brand",
        label: "sort_brand",
      },
      {
        field: showFavorites ? "oby_fav_rating" : "oby_rating",
        label: "sort_best_rating",
      },
      {
        field: showFavorites ? "oby_fav_popular" : "oby_popular",
        label: "sort_popular",
      },
    ],
    [showFavorites, isLogged]
  );

  const theQuery = useMemo(() => {
    let query = { ...defaultQuery };

    if (showFavorites) {
      query.is_favorite = "1";
    }

    const q = qsGetParam(qs, "q");
    if (q && q !== "*") {
      query.q = q;
    }

    const brand = qsGetParam(qs, "b");
    if (brand) {
      query.brand_name = brand;
    }

    const start = +qsGetParam(qs, "s");
    if (
      start &&
      Number.isInteger(start) &&
      start >= 1 &&
      start < QS_MAX_START
    ) {
      query.start = start;
    }

    const sort = +qsGetParam(qs, "o");
    if (Number.isInteger(sort) && sort >= 0 && sort < sortFields.length) {
      query.oby = sortFields[sort].field;
      query.sorting = sort;
    } else {
      query.oby = sortFields[query.sorting].field;
    }

    const count = qsGetParam(qs, "c");
    if (count !== "0" || !currentRowCount) {
      query.row_count = 1;
    }

    return query;
  }, [qs, showFavorites, sortFields]);

  useEffect(() => {
    const subTitle =
      theQuery.q + (theQuery.brand_name ? " " + theQuery.brand_name : "");

    setTitle(showFavorites ? "title_favorites" : "title_search", subTitle);
  }, [setTitle, showFavorites, theQuery]);

  useEffect(() => {
    setBackUrl(showFavorites ? routes.favorite : routes.search, qs);
  }, [setBackUrl, showFavorites, routes, qs]);

  useEffect(() => {
    const _fetch = async () => {
      const json = await getProductList(theQuery);
      if (theQuery.row_count) {
        currentRowCount = json.row_count ?? 0;
      } else {
        json.row_count = currentRowCount;
      }

      setSearchResults(json);
    };

    if (theQuery.q.length > 0 || showFavorites) {
      _fetch();
    }
  }, [getProductList, showFavorites, theQuery]);

  const paginateHandler = useCallback(
    (e) => {
      setSearchParams({ ...qs, s: e.first + 1, c: 0 });
    },
    [setSearchParams, qs]
  );

  const sortHandler = useCallback(
    (n) => {
      setSearchParams({ ...qs, s: 1, o: n, c: 0 });
    },
    [setSearchParams, qs]
  );

  const removeFilterHandler = useCallback(() => {
    setSearchParams({ ...qs, b: "", s: 1, c: 1 });
  }, [setSearchParams, qs]);

  const goToProduct = useCallback(
    (e) => {
      const productGtin = e.currentTarget?.id?.substring(2) ?? e.gtin;
      if (productGtin) {
        goTo(routes.product + "/" + productGtin);
      }
    },
    [goTo, routes.product]
  );

  useEffect(() => {
    if (isValidGtin(theQuery.q) && searchResults?.results) {
      setBackUrl();
      goToProduct({ gtin: theQuery.q });
    }
  }, [setBackUrl, goToProduct, searchResults?.results, theQuery.q]);

  const resultBarConfig = {
    count: searchResults?.row_count,
    title: showFavorites ? "found_favorites" : "found_products",
    sortFields: sortFields,
    sortHandler: sortHandler,
    currentSorting: theQuery.sorting,
    onRemoveFilter: removeFilterHandler
  };

  if (!pageAllowed) {
    return <SfNoLogin />;
  }

  if (isLoading) {
    return <SfLoading />;
  }

  if (
    !showFavorites &&
    (searchParams?.get("q") === null ||
      searchParams?.get("q") === "" ||
      searchParams?.get("q") === "*")
  ) {
    return <SfNodata message={"empty_search"} />;
  }

  if (searchResults === null) {
    return <div></div>;
  }

  if (error) {
    return <SfError mode="screen" error={error} />;
  }

  if (isValidGtin(theQuery.q) && searchResults?.results) {
    return;
  }

  if (!searchResults.results || searchResults.results.length === 0) {
    if (showFavorites || theQuery.brand_name === "") {
      return (
        <SfNodata
          message={showFavorites ? "no_favorites" : "no_data_message"}
        />
      );
    }
  }

  return (
    <div className="pt-2 pb-6 md:pb-3">
      <SfResultBar
        config={resultBarConfig}
        filters={{ brand: theQuery.brand_name }}
      />
      <div className="mt-2">
        {searchResults.results.map((product) => (
          <div key={product.product_gtin}>
            <SfProductCard
              mode="list"
              product={product}
              onClick={goToProduct}
            />
          </div>
        ))}
      </div>
      <SfPaginator
        first={theQuery.start}
        rows={theQuery.limit}
        totalRecords={searchResults.row_count}
        onPageChange={paginateHandler}
      />
      {(searchResults?.row_count > FILTER_THRESHOLD || theQuery.brand_name?.length > 0) && !showFavorites && (
        <SfProductSearchFilters query={qs} />
      )}
    </div>
  );
};

export default SfRouteSearch;
