import SfFormField from "../ui/wrappers/SfFormField";
import SfPassword from "../ui/wrappers/SfPassword";

const SfPasswordFields = ({ form, setValue }) => {
  const displayOkMark = () => {
    return (
      <i className="pi pi-verified text-xl font-bold text-green-500 pl-2" />
    );
  };

  const isStrongPassword = (p) => {
    if (!p || p.trim().length < 8) return false;
    if (!/[0-9]/.test(p)) return false;
    if (!/[A-Z]/.test(p)) return false;
    if (!/[a-z]/.test(p)) return false;
    
    return true;
  };

  return (
    <>
      <SfFormField
        id="user_pass"
        label="form_user_pass_create"
        icon="pi-key"
        mandatory
      >
        <div className="flex align-items-center justify-content-center">
          <SfPassword
            type="password"
            inputId="user_pass"
            toggleMask
            value={form.user_pass ?? ""}
            onChange={setValue}
          />
          {isStrongPassword(form?.user_pass) && displayOkMark()}
        </div>
      </SfFormField>
      <SfFormField
        id="user_pass_2"
        label="form_user_pass_check"
        icon="pi-key"
        mandatory
      >
        <div className="flex align-items-center justify-content-center">
          <SfPassword
            type="password"
            inputId="user_pass_2"
            toggleMask
            value={form.user_pass_2 ?? ""}
            onChange={setValue}
          />
          {isStrongPassword(form?.user_pass_2) &&
            form.user_pass.trim() === form.user_pass_2.trim() &&
            displayOkMark()}
        </div>
      </SfFormField>
    </>
  );
};

export default SfPasswordFields;
