import { useState, useEffect, useCallback, useMemo } from "react";
import useRoles from "../../../hooks/use-roles";
import useNavigation from "../../../hooks/use-navigation";
import useShelfiveReview from "../../../hooks/use-shelfive-review";
import useMessage from "../../../hooks/use-message";
import useTitle from "../../../hooks/use-title";
import SfReviewAbuseForm from "./SfReviewAbuseForm";
import SfReviewAnswerForm from "./SfReviewAnswerForm";
import SfNodata from "../../ui/SfNodata";
import SfNoLogin from "../../ui/SfNoLogin";
import SfResultBar from "../../data/SfResultBar";
import SfReviewCard from "./SfReviewCard";
import SfDialog from "../../ui/wrappers/SfDialog";
import SfPaginator from "../../ui/wrappers/SfPaginator";
import SfLoading from "../../ui/SfLoading";
import SfError from "../../ui/SfError";
import { PAGE_LIMIT, SHOW_PICS, isValidLink } from "../../../scripts/sw-utils";

const MYREVIEW = "myreview";
const MYANSWER = "myanswer";

const defaultQuery = {
  start: 1,
  limit: PAGE_LIMIT,
  sorting: 0,
  row_count: 1,
};

let currentRowCount;

const SfReviewList = ({ gtin, review, link, reviewType, stars, setStars }) => {
  const { isLogged } = useRoles();
  const [reviewList, setReviewList] = useState(null);
  const [answerForm, setAnswerForm] = useState(false);
  const [warningForm, setWarningForm] = useState(false);
  const [reviewProps, setReviewProps] = useState({});
  const { getReviewList, isLoading, error } = useShelfiveReview();
  const { messageTag, showMessage } = useMessage();
  const setTitle = useTitle();
  const { routes, setBackUrl } = useNavigation();
  const pageAllowed =
    isLogged || (reviewType !== MYREVIEW && reviewType !== MYANSWER);

  const sortFields = useMemo(() => {
    let fields = [
      { field: "oby_newest", label: "sort_newest" },
      { field: "oby_oldest", label: "sort_oldest" },
      {
        field: "oby_best",
        label: "sort_best_review",
      },
      { field: "oby_worst", label: "sort_worst_review" },
    ];

    if (reviewType || link) {
      fields.push({
        field: "oby_product",
        label: "sort_product_name",
      });
    }
    return fields;
  }, [reviewType, link]);

  const [theQuery, setTheQuery] = useState(() => {
    let query = { ...defaultQuery };

    if (gtin) {
      query.product_gtin = gtin;
    }
    if (review && isValidLink(review)) {
      query.review_link = review;
    }
    if (link) {
      query.user_link = link;
    }
    if (reviewType === MYANSWER) {
      query.review_type = "answer";
    }
    query.oby = sortFields[query.sorting].field;

    if (!gtin && SHOW_PICS) {
      query.add_pics = 1;
    }

    return query;
  });

  useEffect(() => {
    if (stars && stars !== theQuery.review_stars) {
      setTheQuery((prev) => ({ ...prev, review_stars: stars }));
    } else if (!stars && theQuery.review_stars) {
      setTheQuery((prev) => {
        const { review_stars, ...rest } = prev;
        return rest;
      });
    }
  }, [stars, theQuery.review_stars]);

  useEffect(() => {
    if (reviewType === MYREVIEW) {
      setTitle("title_myreview");
    } else if (reviewType === MYANSWER) {
      setTitle("title_myanswer");
    }
  }, [setTitle, reviewType]);

  useEffect(() => {
    if (reviewType === MYREVIEW) {
      setBackUrl(routes.reviews);
    } else if (reviewType === MYANSWER) {
      setBackUrl(routes.answers);
    } else if (link) {
      setBackUrl(routes.user + "/" + link);
    }
  }, [setBackUrl, reviewType, routes, link]);

  useEffect(() => {
    const _fetch = async () => {
      const json = await getReviewList(theQuery);

      if (theQuery.row_count) {
        currentRowCount = json.row_count ?? 0;
      } else {
        json.row_count = currentRowCount;
      }

      setReviewList(json);
    };

    if (pageAllowed) {
      _fetch();
    }
  }, [getReviewList, theQuery, isLogged, pageAllowed]);

  const paginateHandler = useCallback((e) => {
    setTheQuery((prev) => ({ ...prev, start: e.first + 1, row_count: 0 }));
  }, []);

  const sortHandler = useCallback(
    (n) => {
      setTheQuery((prev) => ({
        ...prev,
        oby: sortFields[n].field,
        sorting: n,
        row_count: 0,
        start: 1,
      }));
    },
    [sortFields]
  );

  const answerHandler = useCallback((review) => {
    setReviewProps(review);
    setAnswerForm(true);
  }, []);

  const warningHandler = useCallback((link, type) => {
    setReviewProps({ link, type });
    setWarningForm(true);
  }, []);

  const closeWarningForm = useCallback(() => {
    showMessage("msg_warning_creation", "msg_warning_inserted");
    setWarningForm(false);
  }, [showMessage]);

  const setReviewContains = useCallback((text) => {
    setTheQuery((prev) => ({
      ...prev,
      ...defaultQuery,
      review_contains: text ?? "",
    }));
  }, []);

  const removeFiltersHandler = useCallback(
    (filter) => {
      if (filter === "star") {
        setStars();
      }
      if (filter === "contains") {
        setReviewContains();
      }
    },
    [setStars, setReviewContains]
  );

  const resultBarConfig = {
    count: reviewList?.row_count,
    title: reviewType === MYANSWER ? "found_answers" : "found_reviews",
    sortFields: sortFields,
    sortHandler: sortHandler,
    currentSorting: theQuery.sorting,
    onAddFilter: setReviewContains,
    onRemoveFilter: removeFiltersHandler,
    showTextFilterBar: true,
  };

  if (!pageAllowed) {
    return <SfNoLogin />;
  }

  if (isLoading) {
    return <SfLoading />;
  }

  if (reviewList === null) {
    return <div></div>;
  }

  if (error) {
    return <SfError mode="screen" error={{ code: "e_err_000" }} />;
  }

  if (
    (!reviewList.results || reviewList.results.length === 0) &&
    !theQuery.review_contains
  ) {
    // no message for users without reviews, or wrong review links
    if (link || review) return <></>;

    let noDataMessage = "";
    if (isLogged) {
      if (reviewType === MYREVIEW) {
        noDataMessage = "no_myreview_message";
      } else if (reviewType === MYANSWER) {
        noDataMessage = "no_myanswer_message";
      } else {
        noDataMessage = "no_review_message";
      }
    } else {
      noDataMessage = "no_review_message_signup";
    }

    return <SfNodata message={noDataMessage} />;
  }

  return (
    <div className="pt-2 mb-6 md:mb-4">
      {!review && (
        <SfResultBar
          config={resultBarConfig}
          filters={{
            stars: theQuery.review_stars,
            reviewContains: theQuery.review_contains,
          }}
        />
      )}
      <div className="mt-2">
        {reviewList.results.map((review) => (
          <SfReviewCard
            key={review.review_link}
            mode={reviewType ? MYREVIEW : link ? "user" : "product"}
            review={review}
            warningHandler={warningHandler}
            answerHandler={answerHandler}
          />
        ))}
      </div>
      <SfPaginator
        first={theQuery.start}
        rows={theQuery.limit}
        totalRecords={reviewList.row_count}
        onPageChange={paginateHandler}
      />
      <SfDialog
        styling="md:w-26rem sf-dialog"
        header="header_form_review_warning"
        visible={warningForm}
        onHide={() => {
          setWarningForm(false);
        }}
        draggable
      >
        <SfReviewAbuseForm review={reviewProps} onSuccess={closeWarningForm} />
      </SfDialog>
      <SfDialog
        header="header_form_review_answer"
        visible={answerForm}
        onHide={() => {
          setAnswerForm(false);
        }}
        draggable
      >
        <SfReviewAnswerForm review={reviewProps} gtin={gtin} link={link} />
      </SfDialog>
      {messageTag}
    </div>
  );
};

export default SfReviewList;
