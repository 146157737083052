import { useTranslation } from "react-i18next";
import useRoles from "../../../hooks/use-roles";
import useNavigation from "../../../hooks/use-navigation";
import SfButton from "../../ui/wrappers/SfButton";
import SfNoLogin from "../../ui/SfNoLogin";

const SfRouteGames = () => {
  const { t } = useTranslation();
  const { isLogged } = useRoles();
  const { routes, goTo } = useNavigation();

  const newGameHandler = () => {
    goTo(routes.play);
  };

  const highestScoresHandler = () => {
    goTo(routes.scores);
  };

  if (!isLogged) {
    return <SfNoLogin />;
  }

  return (
    <div className="h-full flex flex-column align-items-center justify-content-center">
      <div>
        <SfButton
          onClick={newGameHandler}
          styling="p-button border-round-sm pt-1 pb-1 pl-2 pr-2"
          label="play_gamefive"
        />
      </div>
      <div className="mt-5 mb-4 pl-3 pr-3 text-sm text-justify md:flex md:flex-column md:align-items-center">
        <div className="inline">{t("gf_help_1")}</div>
        <div className="inline pl-1 md:pl-0">{t("gf_help_2")}</div>
        <div className="inline pl-1 md:pl-0">{t("gf_help_3")}</div>
        <div className="inline pl-1 md:pl-0">{t("gf_help_4")}</div>
        </div>
      <div onClick={highestScoresHandler} className="sf-underline">{t("highest_scores")}</div>
    </div>
  );
};

export default SfRouteGames;
