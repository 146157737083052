import { useState, useEffect, useCallback } from "react";

const checkFrequency = 3000;

const useNetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const debounce = useCallback((fn, ms) => {
    let timer;

    return (_) => {
      clearTimeout(timer);
      timer = setTimeout((_) => {
        timer = null;
        fn.apply(this);

      }, ms);
    };
  }, []);

  useEffect(() => {
    const debouncedStatusChange = debounce(function handleChange() {
      setIsOnline(navigator.onLine);
    }, checkFrequency);

    window.addEventListener("online", debouncedStatusChange);
    window.addEventListener("offline", debouncedStatusChange);

    return () => {
      window.removeEventListener("online", debouncedStatusChange);
      window.removeEventListener("offline", debouncedStatusChange);
    };
  });

  return isOnline;
};

export default useNetworkStatus;
