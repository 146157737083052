import { forwardRef, memo } from "react";
import useNavigation from "../../../hooks/use-navigation";
import useRoles from "../../../hooks/use-roles";
import SfMenu from "../../ui/wrappers/SfMenu";

const SfHeaderMyMenu = forwardRef((props, ref) => {
  const { routes, goTo } = useNavigation();
  const { isBrandAdmin } = useRoles();
  const userIsBrandAdmin = isBrandAdmin();

  const navigateHandler = (link) => {
    goTo(link);
  };

  let myMenuItems = [
    {
      label: "my_favorites",
      icon: "pi pi-fw pi-heart",
      command: () => {
        navigateHandler(routes.favorite);
      },
    },
    {
      label: "my_reviews",
      icon: "pi pi-fw pi-pencil",
      command: () => {
        navigateHandler(routes.reviews);
      },
    },
    {
      label: "my_lists",
      icon: "pi pi-fw pi-list",
      command: () => {
        navigateHandler(routes.lists);
      },
    },
    {
      label: "my_games",
      icon: "pi pi-fw pi-play",
      command: () => {
        navigateHandler(routes.games);
      },
    },
  ];

  if (userIsBrandAdmin) {
    myMenuItems.push({
      label: "my_answers",
      icon: "pi pi-fw pi-pencil",
      command: () => {
        navigateHandler(routes.answers);
      },
    });
  }

  myMenuItems.push(
    {
      label: "my_profile",
      icon: "pi pi-fw pi-id-card",
      command: () => {
        navigateHandler(routes.profile);
      },
    },
    {
      label: "share_the_link",
      icon: "pi pi-fw pi-share-alt",
      command: () => {
        navigateHandler(routes.share);
      },
    },
  );

  return <SfMenu model={myMenuItems} popup ref={ref} />;
});

export default memo(SfHeaderMyMenu);
