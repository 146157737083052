import { useState, useCallback } from "react";
import useApi from "./use-api";

const useShelfiveGames = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const invokeApi = useApi(setIsLoading, setError);

  const gameStart = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/game-start",
        method: "POST",
        query: query,
        auth: true,
      });
    },
    [invokeApi]
  );

  const gameEnd = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/game-end",
        method: "POST",
        query: query,
        auth: true,
      });
    },
    [invokeApi]
  );

  const gameScores = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/game-scores",
        method: "GET",
        query: query,
        auth: true,
      });
    },
    [invokeApi]
  );

  const gameAllScores = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/game-scores",
        method: "GET",
        query: query,
      });
    },
    [invokeApi]
  );

  return {
    isLoading,
    error,
    gameStart,
    gameEnd,
    gameScores,
    gameAllScores,
  };
};

export default useShelfiveGames;
