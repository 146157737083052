import { useRef, memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useResize from "../../../hooks/use-resize";
import useNetworkStatus from "../../../hooks/use-network-status";
import useNavigation from "../../../hooks/use-navigation";
import logo from "../../../resources/img/logo_256.png";
import SfButton from "../../ui/wrappers/SfButton";
import SfOverlay from "../../ui/wrappers/SfOverlay";
import SfDialog from "../../ui/wrappers/SfDialog";
import SfSearchEngineBar from "../../app/product/SfSearchEngineBar";

const SfHeaderHome = () => {
  const ref = useRef();
  const { t } = useTranslation();
  const location = useLocation();
  const renderlogo = location.pathname !== "/";

  const dimensions = useResize();
  const showSearchBtn = dimensions.width < 700 && renderlogo;

  const isOnline = useNetworkStatus();

  const { goTo } = useNavigation();
  const navigateHandler = () => {
    goTo("/");
  };

  const closeSearchHandler = useCallback((e) => {
    ref.current.hide(e);
  }, []);

  return (
    <>
      <div className="flex align-items-center justify-content-start pl-2 pr-2 font-bold text-primary">
        {renderlogo && (
          <img
            src={logo}
            alt="shelfive logo"
            className="cursor-pointer h-2rem sf-shelfive-logo"
            onClick={navigateHandler}
            title={t("home_page")}
          />
        )}
        {!showSearchBtn && (
          <div
            className="cursor-pointer ml-2 text-primary"
            onClick={navigateHandler}
            title={t("home_page")}
          >
            Shelfive
          </div>
        )}
        {showSearchBtn && (
          <SfButton
            label="search_btn"
            icon="pi pi-search"
            styling="p-button-sm border-round-sm p-1 pl-2 pr-2 ml-2 white-space-nowrap p-button-secondary"
            onClick={(e) => ref.current.toggle(e)}
          />
        )}
      </div>
      <SfOverlay styling="w-full h-4rem surface-100 p-0" ref={ref}>
        <SfSearchEngineBar size="small" focus onSearch={closeSearchHandler} />
      </SfOverlay>

      <SfDialog
        styling="sf-dialog"
        visible={!isOnline}
        closable={false}
        showHeader={false}
        position="bottom"
        appendTo={"self"}
      >
        <div className="text-red-600 font-bold mb-3"><i className="mr-2 pi pi-wifi" />{t("you_are_offline")}</div>
      </SfDialog>
    </>
  );
};

export default memo(SfHeaderHome);
