import { useState, useContext } from "react";
import useShelfiveUser from "../../hooks/use-shelfive-user";
import ShelfiveAuthContext from "../../contexts/shelfive-auth";
import SfFormField from "../ui/wrappers/SfFormField";
import SfInput from "../ui/wrappers/SfInput";
import SfButton from "../ui/wrappers/SfButton";
import SfError from "../ui/SfError";

const SfDeleteAccount = () => {
  const authCtx = useContext(ShelfiveAuthContext);
  const userName = authCtx.userData?.profile?.[0].user_name;
  const { deleteUser, isLoading, error } = useShelfiveUser();
  const [form, setForm] = useState({});

  const setValue = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.id]: e.target.value ?? e.checked,
    }));
  };

  const deleteAccountHandler = async (e) => {
    e.preventDefault();

    const json = await deleteUser();

    if (json?.operation === "deleted") {
        authCtx.logout();
    }
  };

  return (
    <form onSubmit={deleteAccountHandler}>
      <SfFormField
        id="confirm_user_name"
        label="form_confirm_user_name"
        icon="pi-user"
        mandatory
      >
        <SfInput
          type="text"
          id="confirm_user_name"
          value={form.confirm_user_name ?? ""}
          onChange={setValue}
        />
      </SfFormField>

      <div className="field text-right flex align-items-center justify-content-end">
        {error && <SfError error={error} />}
        <SfButton
          label="delete_account"
          loading={isLoading}
          iconPos="right"
          type="submit"
          styling="p-button-sm border-round-sm p-1 pl-2 pr-2 mt-2 ml-1 bg-red-700"
          disabled={form.confirm_user_name !== userName}
        />
      </div>
    </form>
  );
};

export default SfDeleteAccount;
