import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import useDate from "../../../hooks/use-date";
import SfIcon from "../../ui/wrappers/SfIcon";

const SfListCard = ({ list, updating, onClick, onEdit, onDelete, onShare }) => {
  const {
    list_owner,
    user_name,
    list_name,
    list_link,
    list_create,
    list_public,
    list_writable,
    tot_products,
  } = {
    ...list,
  };
  const { t } = useTranslation();
  const { i18date } = useDate();
  const displayShareButton = navigator.canShare;

  const getTotalProducts = () => {
    return tot_products ? (
      <>
        <span className="font-light">{t("list_tot_products")}</span>:{" "}
        <span className="text-primary font-semibold">{tot_products}</span>
      </>
    ) : (
      <span className="font-light">{t("list_no_products")}</span>
    );
  };

  return (
    <div
      id={isMobile ? "l-" + list_link : undefined}
      onClick={isMobile ? onClick : undefined}
      className={"mt-3 pr-0 mr-2 ml-2" + (isMobile ? " sf-item-mobile" : "")}
    >
      <div className="flex align-items-center">
        <div
          id={!isMobile ? "l-" + list_link : undefined}
          onClick={!isMobile ? onClick : undefined}
          className="font-semibold sf-underline mr-1 md:mr-3 flex-grow-1 md:flex-grow-0"
        >
          &#128205; {list_name}
          {list_owner === "n" && (
            <span className="ml-1 font-semibold text-color-secondary text-sm">
              ({user_name})
            </span>
          )}
          {!!list_public && list_owner === "y" && (
            <span className="ml-1 font-normal text-sm text-color-secondary">
              (
              {t("public_list") +
                ", " +
                t(list_writable ? "writable_list" : "readonly_list")}
              )
            </span>
          )}
        </div>
        {list_owner === "y" && (
          <div className="flex-none flex">
            {updating && <i className="ml-2 mr-2 pi pi-cog pi-spin" />}
            {!updating && !!list_public && displayShareButton && (
              <SfIcon
                icon="pi-share-alt"
                title="list_share"
                onClick={(e) => onShare(e, list)}
              />
            )}
            {!updating && (
              <SfIcon
                icon="pi-pencil"
                title="list_edit"
                onClick={(e) => onEdit(e, list)}
              />
            )}
            {!updating && (
              <SfIcon
                icon="pi-trash"
                title="list_delete"
                onClick={(e) => onDelete(e, list)}
              />
            )}
          </div>
        )}
        {list_owner === "n" && (
          <div className="flex-none flex">
            {updating && <i className="ml-2 mr-2 pi pi-cog pi-spin" />}
            {!updating && (
              <SfIcon
                icon="pi-trash"
                title="list_unfollow"
                onClick={(e) => onDelete(e, list)}
              />
            )}
          </div>
        )}
      </div>
      <div className="flex mt-1">
        <div className="font-light text-sm text-color-secondary mr-3">
          {i18date(list_create, "relative")}
        </div>
        <div className="text-sm">{getTotalProducts()}</div>
      </div>
    </div>
  );
};

export default SfListCard;
