import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useTitle from "../../../hooks/use-title";
import ShelfiveAuthContext from "../../../contexts/shelfive-auth";
import { TabMenu } from "primereact/tabmenu";
import SfUserProfile from "../../app/user/SfUserProfile";
import SfUserBadges from "../../app/user/SfUserBadges";
import SfUserSettings from "../../app/user/SfUserSettings";
import SfUserRoles from "../../app/user/SfUserRoles";
import SfNoLogin from "../../ui/SfNoLogin";

const SfRouteProfile = () => {
  const { t } = useTranslation("buttons");
  const authCtx = useContext(ShelfiveAuthContext);
  const isLogged = authCtx.isLogged;
  const { roles } = { ...authCtx.userData };
  const [menuIndex, setMenuIndex] = useState(0);
  const setTitle = useTitle();

  let items = [
    { label: t("tab_profile"), icon: "pi pi-fw pi-id-card" },
    { label: t("tab_badges"), icon: "pi pi-fw pi-star" },
    { label: t("tab_settings"), icon: "pi pi-fw pi-cog" },
  ];
  if (roles?.length > 0) {
    items.push({ label: t("tab_roles"), icon: "pi pi-fw pi-verified" });
  }

  useEffect(() => {
    setTitle("title_myprofile");
  }, [setTitle]);

  if (!isLogged) {
    return <SfNoLogin />;
  };

  return (
    <>
      <TabMenu
        className="sf-menutab"
        model={items}
        activeIndex={menuIndex}
        onTabChange={(e) => setMenuIndex(e.index)}
      />
      {menuIndex === 0 && <SfUserProfile />}
      {menuIndex === 1 && <SfUserBadges />}
      {menuIndex === 2 && <SfUserSettings />}
      {menuIndex === 3 && <SfUserRoles />}
    </>
  );
};

export default SfRouteProfile;
