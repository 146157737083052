import { useState } from "react";
import { useTranslation } from "react-i18next";
import useShelfiveUser from "../../hooks/use-shelfive-user";
import SfInput from "../ui/wrappers/SfInput";
import SfButton from "../ui/wrappers/SfButton";
import SfError from "../ui/SfError";
import { CopyToClipboard } from "react-copy-to-clipboard";
import useMessage from "../../hooks/use-message";
import { API_KEY_EXPIRE_DAYS } from "../../scripts/sw-utils";

const SfCreateApiKey = () => {
  const [apiKey, setApiKey] = useState('');
  const { t } = useTranslation();
  const { createApiKey, isLoading, error } = useShelfiveUser();
  const { messageTag, showMessage } = useMessage();

  const createApiKeyHandler = async (e) => {
    e.preventDefault();

    const json = await createApiKey();

    if (json?.api_key) {
      setApiKey(json.api_key);
    }
  };

  const copyKeyHandler = () => {
    if (apiKey.length > 0) {
        showMessage("api_key_copied");
    }
  };

  return (
    <form onSubmit={createApiKeyHandler}>
      <div className="field text-sm">{t("api_key_help", {day: API_KEY_EXPIRE_DAYS})}</div>
      <div className="flex align-items-center mt-3 mb-3">
        <SfInput type="text" value={apiKey} disabled styling="w-full pl-1 pr-1 md:pl-2 md:pr-2" />
        <CopyToClipboard
            text={apiKey}
            onCopy={() => {
                copyKeyHandler();
            }}
          >
            <i className="pi pi-copy cursor-pointer pl-2" />
          </CopyToClipboard>
      </div>
      <div className="field text-sm font-semibold">{t("api_key_warning")}</div>
      <div className="flex align-items-center justify-content-center mt-3 mb-2">
        <SfButton
          label="create_api_key"
          loading={isLoading}
          iconPos="right"
          type="submit"
          disabled={apiKey.length > 0}
        />
      </div>
      <div className="h-2rem">{error && <SfError error={error} />}</div>
      {messageTag}
    </form>
  );
};

export default SfCreateApiKey;
