import { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ShelfiveEnvContext from "../contexts/shelfive-env";
import { qsBuild, SEO_URL } from "../scripts/sw-utils";

const routes = {
  product: "/product",
  search: "/search",
  random: "/random",
  favorite: "/favorites",
  lists: "/lists",
  list: "/list-items",
  reviews: "/reviews",
  answers: "/answers",
  profile: "/profile",
  share: "/share",
  user: "/user",
  faq: "/faq",
  reset: "/reset-password",
  activate: "/activate-user",
  games: "/games",
  play: "/play-game",
  scores: "/highest-scores",
  seo: SEO_URL
};

const useNavigation = () => {
  const navigate = useNavigate();
  const envCtx = useContext(ShelfiveEnvContext);

  const goTo = useCallback(
    (url, props) => {
      if (props?.qs) {
        url += qsBuild(props.qs);
      }

      if (props?.state) {
        navigate(url, { state: props.state });
      } else {
        navigate(url);
      }
    },
    [navigate]
  );

  const setBackUrl = useCallback(
    (url, qs) => {
      if (qs) {
        url += qsBuild(qs);
      }

      envCtx.setBackUrl(url);
    },
    [envCtx]
  );

  const hasBackUrl = useCallback(() => {
    return !!envCtx.backUrl;
  }, [envCtx.backUrl]);

  const goBack = useCallback(() => {
    if (envCtx.backUrl) {
      navigate(envCtx.backUrl);
    }
  }, [navigate, envCtx.backUrl]);

  return { routes, goTo, setBackUrl, hasBackUrl, goBack };
};

export default useNavigation;
