export const getNameLength = (p) => {
  const name_length = p && p.product_name ? p.product_name.trim().length : 0;
  const brand_length = p && p.brand_name ? p.brand_name.trim().length : 0;

  return name_length + brand_length;
};

export const getName = (p) => {
  const name = p && p.product_name ? p.product_name.trim() : "";
  const brand = p && p.brand_name ? " " + p.brand_name.trim() : "";

  return name + brand;
};

export const getRoundedScorePercent = (n) => {
  return Math.round(n * 10000) / 100;
};

export const sortByLength = (products) => {
  return products?.slice().sort(function (a, b) {
    return getNameLength(b) - getNameLength(a);
  });
};

export const highlightPicked = (products, picked) => {
  return products?.map((product, idx) => {
    const h = picked.includes(idx);
    return {...product, picked: h}
  });
};

const shortVibrationMs = 50;

export const vibrate = () => {
  if (navigator.vibrate) {
    navigator.vibrate(shortVibrationMs);
  }
}
