const isTestEnv = process.env.REACT_APP_SF_ENV !== "prod";
const apiStage = "v1";
const devSuffix = isTestEnv ? "-dev" : "";
export const API_URL = "https://api" + devSuffix + ".shelfive.com/" + apiStage;

// build number (to avoid browser cache problems)
// Change it in some way at every build production
export const APP_BUILD = "1.2.022";

// show product thumbnails in product lists
export const SHOW_PICS = true;

// timeout to call backend APIs (this should be a safe limit, every operation
// should take less than 1 second)
export const API_TIMEOUT = 15000;

// default theme (if a setting has not been previously stored in the application
// storage). Changing the theme (between light and dark) dinamically load a CSS
export const UI_THEME = "light";
const CSS_LIGHT = "saga-purple/theme.css";
const CSS_DARK = "vela-purple/theme.css";

// max length for a parameter (e.g. "q" for the product search query)
export const QS_MAX_PARAM_LEN = 100;

// max "start" parameter that can be specified on the querystring (used only to
// paginate the product search and product are much less). This parameter is
// just to avoid that users insert a too big number tampering the querystring
export const QS_MAX_START = 2000000;

// "limit" parameter to paginate products, favorites and reviews
export const PAGE_LIMIT = SHOW_PICS ? 20 : 30;

// if there are more than FILTER_THRESHOLD results (for product search only),
// a filter is displayed to limit the search (by brand, etc.)
export const FILTER_THRESHOLD = 10;

// duration (in days) for an API key generated by the user
export const API_KEY_EXPIRE_DAYS = 90;

// links to social media
export const LINK_SOCIAL = {
  "facebook": "https://www.facebook.com/Shelfive",
  "instagram": "https://www.instagram.com/shelfive_official",
  "twitter": "https://twitter.com/ShelfiveReviews"
};

// mail address displayed in the home page
export const MAIL_SUPPORT = "support@shelfive.com";

// page URL for SEO
export const SEO_URL = "/product-indexing";

export const isSEO = () => {
  return window.location.href.indexOf(SEO_URL) > 0;
};

// UI
export const UI_NOTE_COLORS = [
  { label: "note_grey", value: 0},
  { label: "note_green", value: 1},
  { label: "note_red", value: 2},
  { label: "note_yellow", value: 3},
  { label: "note_blue", value: 4},
];

export const UI_LANGUAGES = [
  { label: "English", value: "en" },
  { label: "Italiano", value: "it" },
];

export const getCurrentLanguage = (i18n) => {
  return (i18n.language ?? "en").substring(0, 2);
};

export const getFromStorage = (k) => {
  return localStorage.getItem(k);
};

export const saveToStorage = (k, v) => {
  localStorage.setItem(k, v);
};

export const clearStorage = (k) => {
  localStorage.removeItem(k);
};

export const applyUITheme = (theme) => {
  const themeFolder = process.env.PUBLIC_URL + "/themes/";

  // This is the main 3rd party theme (dark or light)
  const themeLink = document.getElementById("app-theme");
  themeLink.href = themeFolder + (theme === "light" ? CSS_LIGHT : CSS_DARK);

  // These are custom CSS classes, theme dependent
  const themeLinkSf = document.getElementById("app-theme-sf");
  themeLinkSf.href = themeFolder + "sf-" + theme + ".css?" + APP_BUILD;
};

// querystring handling
export const qsBuild = (obj) => {
  let qs = "";

  for (const key in obj) {
    qs += (qs ? "&" : "?") + key + "=" + encodeURIComponent(obj[key]);
  }

  return qs;
};

export const qsGetFromUrl = (searchParams) => {
  if (!searchParams) return {};

  // do not use Object.fromEntries for old browser compatibility
  let obj = {};
  for (let [key, value] of searchParams) {
    obj[key] = value;
  }
  return obj;
};

export const qsGetParam = (qs, param) => {
  if (qs[param]?.length > 0) {
    return qs[param].substring(0, QS_MAX_PARAM_LEN)?.trim();
  }
};

// app related constants and functions
export const FOOD_CATEGORY = "Food";

export const isValidGtin = (gtin) => {
  if (isNaN(gtin)) return false; // Not a number
  if (gtin.length !== 8 && gtin.length !== 13) return false; // Not a GTIN-8 nor a GTIN-13
  if (gtin.substring(0, 1) === "2") return false; // Weighted products
  if (gtin.length === 13 && gtin.substring(0, 2) === "98") return false; // Coupon
  if (gtin.length === 13 && gtin.substring(0, 2) === "99") return false; // Coupon
  if (gtin.length === 8 && gtin.substring(0, 1) === "0") return false; // Internal EAN-8 codes

  return _GtinHasValidChecksum(gtin);
};

const _GtinHasValidChecksum = (gtin) => {
  let total = 0;
  const zgtin = gtin.padStart(14, "0").slice(-14);

  for (let i = 0; i < 13; i++) {
    total +=
      i % 2 === 1 ? +zgtin.substring(i, i + 1) : 3 * +zgtin.substring(i, i + 1);
  }

  const checkDigit = (10 - (total % 10)) % 10;
  return +gtin.slice(-1) === checkDigit;
};

export const isValidLink = (link) => {
  return !!link && link.length === 16;
};
