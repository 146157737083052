import { useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Toast } from "primereact/toast";

const life = 4000; // toast display time, in milliseconds

const useMessage = () => {
  const { t } = useTranslation();
  const ref = useRef();

  const messageTag = <Toast ref={ref} position="bottom-right" appendTo={document.body} className="sf-toast mb-3 lg:mb-0" />;

  const showMessage = useCallback((summary, detail, severity = "success") => {
    ref.current.show({
      severity: severity,
      life: life,
      summary: t(summary),
      detail: t(detail),
    });
  }, [t]);

  return { messageTag, showMessage };
};

export default useMessage;
