import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";

const SfButton = forwardRef((props, ref) => {
  const { t } = useTranslation("buttons");
  const i18Props = {
    ...props,
    label: props.label ? t(props.label) : null,
    title: props.title ? t(props.title) : null,
  };
  const classes = props.styling
    ? props.styling
    : "p-button-sm border-round-sm p-1 pl-2 pr-2 ml-1 mr-1 white-space-nowrap";

  return <Button {...i18Props} className={classes} ref={ref} />;
});

export default SfButton;
