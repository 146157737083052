import { lazy, Suspense, useEffect, useContext, memo } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import ShelfiveAuthContext from "../../contexts/shelfive-auth";
import useTitle from "../../hooks/use-title";
import useNavigation from "../../hooks/use-navigation";
import SfSearchEngineBar from "../app/product/SfSearchEngineBar";
import logo from "../../resources/img/logo_256.png";
import { getCurrentLanguage } from "../../scripts/sw-utils";
import SfFortune from "../misc/SfFortune";
import SfA2hsLink from "../misc/SfA2hsLink";

const SwRouteHome = () => {
  const authCtx = useContext(ShelfiveAuthContext);
  const whoAmI = authCtx.userData?.profile?.[0].user_name;
  const { t, i18n } = useTranslation();
  const currentLanguage = getCurrentLanguage(i18n);
  const { routes, goTo, setBackUrl } = useNavigation();
  const setTitle = useTitle();
  const Home = lazy(() =>
    import("../../resources/locales/" + currentLanguage + "/Home")
  );
  const appSubtitle = "read.shop.write.";
  const welcomeMessage =
    whoAmI && isMobile
      ? t("hello_message") + " " + whoAmI
      : t("welcome_to_shelfive");

  useEffect(() => {
    setTitle("title_home");
  }, [setTitle]);

  const pickAproduct = () => {
    setBackUrl();
    goTo(routes.random);
  };

  return (
    <div className="flex flex-column min-h-full">
      <div className="flex flex-none flex-column align-items-center justify-content-center h-11rem">
        <div className="h-8rem w-8rem flex align-items-center justify-content-center">
          <img
            src={logo}
            alt="shelfive logo"
            className="h-8rem sf-shelfive-logo"
          />
        </div>
        <div className="font-italic text-primary mt-1">{appSubtitle}</div>
      </div>
      <div className="flex flex-none align-items-center justify-content-center h-3rem">
        <SfSearchEngineBar size="big" />
      </div>
      <div className="flex flex-column flex-grow-1 align-items-start md:align-items-center pt-0 justify-content-center">
        <div className="flex flex-column align-items-center justify-content-start">
          <Suspense fallback={<div />}>
            <Home welcome={welcomeMessage} pickAproduct={pickAproduct} />
          </Suspense>
        </div>
      </div>
      <div className="flex flex-none flex-column align-items-top justify-content-center text-center text-color-secondary text-sm mb-6 pl-1 pr-1">
        <SfA2hsLink />
        <SfFortune lang={currentLanguage} />
      </div>
    </div>
  );
};

export default memo(SwRouteHome);
