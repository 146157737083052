import { memo } from "react";
import useNavigation from "../../../hooks/use-navigation";
import SfReviewBadge from "../review/SfReviewBadge";
import SfProductGtin from "./SfProductGtin";
import imgNotFoundProduct from "../../../resources/img/picture_not_found.png";

const SfProductThumb = ({ product }) => {
  const { routes, goTo } = useNavigation();
  const {
    product_gtin,
    product_size,
    product_pic_url,
    avg_stars,
    reviews_tot,
  } = {
    ...product,
  };

  const goToProduct = (e) => {
    const productGtin = e.currentTarget?.id?.substring(2);
    if (productGtin) {
      goTo(routes.product + "/" + productGtin);
    }
  };

  return (
    <div
      className="pt-2 pb-2 flex flex-column align-items-center justify-content-center cursor-pointer"
      id={"s-" + product_gtin}
      onClick={goToProduct}
    >
      <div className="flex shadow-5 w-6rem h-6rem border-round-md">
        <img
          src={product_pic_url || imgNotFoundProduct}
          className="w-6rem h-6rem surface-200 border-round-md"
          alt={product_gtin}
          style={{ aspectRatio: "1/1", objectFit: "cover" }}
        />
      </div>
      <div className="flex pt-1 text-color-secondary text-sm">
        <SfProductGtin
          styling="text-sm text-color-secondary"
          gtin={product_gtin}
        />
      </div>
      <div className="flex">
        <div className="pr-1">{product_size}</div>
        <SfReviewBadge rating={avg_stars} tot={reviews_tot} mode="star-small" />
      </div>
    </div>
  );
};

export default memo(SfProductThumb);
