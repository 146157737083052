import { useEffect, useState, memo } from "react";
import useShelfiveCommon from "../../hooks/use-shelfive-common";
import SfError from "../../components/ui/SfError";

let loadedMessages = {};

const SfFortune = ({ lang }) => {
  const { getFortune, isLoading, error } = useShelfiveCommon();
  const [message, setMessage] = useState();

  useEffect(() => {
    const _fortune = async () => {
      const json = await getFortune({ lang });
      if (json?.quote) {
        setMessage(json.quote);
        loadedMessages[lang] = json.quote;
      }
    };

    if (loadedMessages[lang]) {
      setMessage(loadedMessages[lang]);
    }
    else {
      _fortune();
    }
  }, [getFortune, lang]);

  if (isLoading) return;

  if (error) {
    return <SfError error={error} mode="screen" />;
  }
  
  if (message) {
    return <span>&ldquo;{message}&rdquo;</span>;
  }
};

export default memo(SfFortune);
