import { useState, useCallback } from "react";
import useApi from "./use-api";

const useShelfiveCommon = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const invokeApi = useApi(setIsLoading, setError);

  const getBrandList = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/get-brand-list",
        method: "GET",
        query: query,
      });
    },
    [invokeApi]
  );

  const getConfigList = useCallback(
    async (query, auth=false) => {
      return await invokeApi({
        endpoint: "/get-config-list",
        method: "GET",
        query: query,
        auth: auth,
      });
    },
    [invokeApi]
  );

  const getAppInfo = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/get-app-info",
        method: "GET",
        query: query,
      });
    },
    [invokeApi]
  );

  const getFortune = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/get-fortune",
        method: "GET",
        query: query,
      });
    },
    [invokeApi]
  );

  return {
    isLoading,
    error,
    getBrandList,
    getConfigList,
    getAppInfo,
    getFortune,
  };
};

export default useShelfiveCommon;
