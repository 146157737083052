import { useState, useEffect, memo } from "react";
import useError from "../../hooks/use-error";

const autoHideSeconds = 5;

const SfError = ({ mode = "inline", error }) => {
  const { tError } = useError();
  const [showError, setShowError] = useState(false);
  const message = tError(error ?? "");

  useEffect(() => {
    setShowError(true);
    let timer = setTimeout(() => setShowError(false), autoHideSeconds * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [error]);

  if (mode === "inline") {
    return (
      <div className="p-error font-medium pl-1 pr-1">
        {showError && <span>{message}</span>}
      </div>
    );
  }

  if (mode === "screen") {
    return (
      <div className="p-error font-semibold p-2 md:p-5 text-center">
        <span>{message}</span>
      </div>);
  }
};

export default memo(SfError);
