import { createContext, useState } from "react";
import { isIOS, isAndroid } from "react-device-detect";
import useFetch from "../hooks/use-fetch";
import {
  getFromStorage,
  saveToStorage,
  clearStorage,
} from "../scripts/sw-utils";

const ShelfiveAuthContext = createContext({
  isAuthenticating: false,
  isLogged: false,
  autologin: () => {},
  login: (user_mail, user_pass, remember) => {},
  logout: () => {},
  userData: null,
  setUserData: () => {},
});

export const ShelfiveAuthContextProvider = ({ children }) => {
  const fetchData = useFetch();
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [userData, setUserData] = useState(null);

  let accessToken = getFromStorage("access_token");
  let refreshToken = getFromStorage("refresh_token");
  let expiresIn, timeoutHandler;

  const autologin = () => {
    if (accessToken || refreshToken) {
      login(null, null, false);
    }
  };

  const login = async (user_mail, user_pass, remember) => {
    setIsAuthenticating(true);
    const login_platform = isIOS ? 'web-ios' : (isAndroid ? 'web-android' : 'web-pc');

    // 1. Check user mail / password or a previously saved token
    const authJson = await fetchData({
      endpoint: "/authenticate-user",
      method: "POST",
      query: { user_mail, user_pass, login_platform },
      token: refreshToken ?? accessToken,
    });

    if (!authJson || authJson?.error_code) {
      setIsAuthenticating(false);
      clearStorage("access_token");
      return authJson;
    }

    accessToken = authJson.access_token;
    refreshToken = authJson.refresh_token;

    // 2. Load user profile
    const userJson = await fetchData({
      endpoint: "/get-user-info",
      method: "GET",
      token: accessToken,
    });

    if (!userJson || userJson?.error_code) {
      setIsAuthenticating(false);
      return userJson;
    }

    setUserData(userJson);

    // 3. Save tokens in local storage
    saveToStorage("access_token", accessToken);
    if (remember) {
      saveToStorage("refresh_token", authJson.refresh_token);
    }
    expiresIn = (+authJson.expires_in - 1) * 1000 * 60;
    timeoutHandler = setTimeout(getNewTokens, expiresIn);

    setIsLogged(true);
    setIsAuthenticating(false);
  };

  const getNewTokens = async () => {
    clearTimeout(timeoutHandler);

    const json = await fetchData({
      endpoint: "/authenticate-user",
      method: "POST",
      token: refreshToken,
    });

    if (json.access_token) {
      saveToStorage("access_token", json.access_token);
      accessToken = json.access_token;
      refreshToken = json.refresh_token;
      expiresIn = (+json.expires_in - 1) * 1000 * 60;
      timeoutHandler = setTimeout(getNewTokens, expiresIn);
    } else {
      // The access token seems expired, better to force the user to login again
      clearStorage("access_token");
      setIsLogged(false);
    }
  };

  const logout = () => {
    clearStorage("access_token");
    clearStorage("refresh_token");
    setIsLogged(false);
  };

  const context = {
    isAuthenticating,
    isLogged,
    autologin,
    login,
    logout,
    userData,
    setUserData,
  };

  return (
    <ShelfiveAuthContext.Provider value={context}>
      {children}
    </ShelfiveAuthContext.Provider>
  );
};

export default ShelfiveAuthContext;
