import { useContext, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import ShelfiveAuthContext from "../../../contexts/shelfive-auth";
import useShelfiveUser from "../../../hooks/use-shelfive-user";
import useNavigation from "../../../hooks/use-navigation";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getCurrentLanguage } from "../../../scripts/sw-utils";
import SfFormField from "../../ui/wrappers/SfFormField";
import SfInput from "../../ui/wrappers/SfInput";
import SfButton from "../../ui/wrappers/SfButton";
import SfCheckbox from "../../ui/wrappers/SfCheckbox";
import SfSelect from "../../ui/wrappers/SfSelect";
import SfError from "../../ui/SfError";
import SfChip from "../../ui/wrappers/SfChip";
import useMessage from "../../../hooks/use-message";

const SfUserProfile = () => {
  const { t, i18n } = useTranslation("buttons");
  const currentLanguage = getCurrentLanguage(i18n);
  const authCtx = useContext(ShelfiveAuthContext);
  const { profile } = { ...authCtx.userData };
  const [form, setForm] = useState(profile?.[0]);
  const { getUserInfo, modifyUser, isLoading, error } = useShelfiveUser();
  const { messageTag, showMessage } = useMessage();
  const ref_btn = useRef();
  const { routes } = useNavigation();

  const setValue = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.id]: e.target.value ?? e.checked,
    }));
  };

  const modifyUserHandler = (e) => {
    e.preventDefault(e);

    const _modify = async () => {
      const json = await modifyUser({ ...form, user_lang: currentLanguage });

      if (json?.operation === "updated") {
        const newUserInfo = await getUserInfo();

        if (newUserInfo?.profile) {
          authCtx.setUserData(newUserInfo);
          showMessage("msg_profile_update", "msg_status_completed");

          setForm((prev) => ({
            ...prev,
            user_lang: currentLanguage.toUpperCase(),
          }));
        }
      }
    };

    _modify();
  };

  const getPublicPageLink = () => {
    const url = window.location.origin + routes.user + "/" + form.user_link;

    return (
      <>
        <div className="mt-3 mb-1">
          {t("profile_page_url")}
          <CopyToClipboard
            text={url}
            onCopy={() => {
              copyLinkHandler();
            }}
          >
            <i className="pi pi-copy cursor-pointer pl-1" />
          </CopyToClipboard>
          :
        </div>
        <div className="font-light text-sm mb-4">{url}</div>
      </>
    );
  };

  const copyLinkHandler = () => {
    showMessage("url_copied");
  };

  if (!authCtx.isLogged) return;

  return (
    <div className="w-12 grid grid-nogutter flex">
      <div className="col-12 md:col-5">
        <form className="p-2 pr-1 md:p-4" onSubmit={modifyUserHandler}>
          <SfFormField
            id="user_name"
            label="form_user_name"
            icon="pi-user"
            mandatory
          >
            <SfInput
              type="text"
              id="user_name"
              value={form.user_name ?? ""}
              onChange={setValue}
            />
          </SfFormField>
          <SfFormField id="user_age" label="form_user_age" icon="pi-user">
            <SfInput
              type="text"
              id="user_age"
              value={form.user_age ?? ""}
              onChange={setValue}
              keyfilter="pint"
              maxLength="4"
              styling="w-4rem flex-none text-sm"
            />
          </SfFormField>
          <SfFormField id="user_gender" label="form_user_gender" icon="pi-user">
            <SfSelect
              id="user_gender"
              value={form.user_gender}
              options={[
                { label: t("gender_male"), value: "m" },
                { label: t("gender_female"), value: "f" },
                { label: t("gender_other"), value: "o" },
              ]}
              onChange={setValue}
            />
          </SfFormField>
          <SfFormField
            id="user_country"
            label="form_user_country"
            icon="pi-map"
          >
            <SfSelect
              id="user_country"
              value={form.user_country}
              list="countries"
              listLabel="country_name"
              listValue="country_id"
              onChange={setValue}
            />
          </SfFormField>
          {form?.user_country === "IT" && (
            <SfFormField
              id="user_region"
              label="form_user_region"
              icon="pi-map"
            >
              <SfSelect
                id="user_region"
                value={form.user_region}
                list="regions"
                listLabel="region_name"
                listValue="region_id"
                onChange={setValue}
              />
            </SfFormField>
          )}
          <div className="field mt-4 text-sm mb-2">
            <SfCheckbox
              id="user_priv_notify"
              checked={form.user_priv_notify ?? true}
              onChange={setValue}
              icon="pi pi-envelope"
              label={t("user_priv_notify")}
            />
          </div>
          <div className="field text-sm mb-2">
            <SfCheckbox
              id="user_priv_rewards"
              checked={form.user_priv_rewards ?? true}
              onChange={setValue}
              icon="pi pi-envelope"
              label={t("user_priv_rewards")}
            />
          </div>
          <div className="field text-sm">
            <SfCheckbox
              id="user_priv_info"
              checked={form.user_priv_info ?? true}
              onChange={setValue}
              icon="pi pi-envelope"
              label={t("user_priv_info")}
            />
          </div>
          <div className="field text-sm pb-1 text-color-secondary">
            <b>{t("note_label")}</b>: {t("user_profile_note")}
          </div>
          <div className="field text-right flex align-items-center justify-content-end">
            {error && <SfError error={error} />}
            <SfButton
              label="update_profile"
              loading={isLoading}
              iconPos="right"
              type="submit"
              disabled={!form.user_name}
              ref={ref_btn}
            />
          </div>
        </form>
      </div>
      <div className="col-12 md:col-7 p-1 md:p-5 text-right">
        {/* <div class="field">
            // user picture TODO
        </div> */}
        <div className="field m-0 p-1 text-sm flex align-items-center justify-content-end">
          <span className="mr-2">{t("your_profile")}:</span>
          <SfChip
            icon="pi pi-user"
            styling={
              form.user_profile === "self registered"
                ? ""
                : "bg-green-500 text-white"
            }
            label={t("profile " + form.user_profile)}
          />
        </div>
        <div className="field m-0 p-1 mt-1 text-sm flex align-items-center justify-content-end">
          <span className="mr-2">{t("saved_language")}:</span>
          <SfChip icon="pi pi-globe" label={form.user_lang?.toUpperCase()} />
        </div>
        <div className="field m-0 mb-2 p-1 text-sm">{getPublicPageLink()}</div>
      </div>
      {messageTag}
    </div>
  );
};

export default SfUserProfile;
