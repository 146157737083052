import { Avatar } from "primereact/avatar";

const SfAvatar = ({ name, picture, styling }) => {
  const classes = styling;
  const image = picture;
  const label = name ? name.substring(0, 1) : undefined;

  if (!label && !image) return <></>;

  return (
    <Avatar label={label} image={image} className={classes} shape="circle" />
  );
};

export default SfAvatar;
