import { useTranslation } from "react-i18next";
//import { FOOD_CATEGORY } from "../../../scripts/sw-utils";
import SfChip from "../../ui/wrappers/SfChip";

const SfProductCategory = ({ category2 }) => {
  const { t } = useTranslation("categories");

  return (
    <SfChip
      // styling={
      //   category1 === FOOD_CATEGORY ? "" : "surface-ground border-1 sf-chip-nofood"
      // }
      label={t(category2)}
    />
  );
};

export default SfProductCategory;
