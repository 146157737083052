import { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import SfProductDetail from "../app/product/SfProductDetail";
import SfProductSame from "../app/product/SfProductSame";
import SfReviewSummary from "../app/review/SfReviewSummary";
import SfReviewList from "../app/review/SfReviewList";
import SfInvalid from "../ui/SfInvalid";
import { isValidGtin } from "../../scripts/sw-utils";

const SfRouteProduct = ({ random }) => {
  const { gtin, review } = useParams();
  const [productNotFound, setProductNotFound] = useState(true);
  const [gtinToSearch, setGtinToSearch] = useState(gtin);
  const [starFilter, setStarFilter] = useState();
  const location = useLocation();

  if (!isValidGtin(gtin) && !random) {
    return <SfInvalid />;
  }

  const logSearch = location.state?.fromScanner ? 1 : 0;

  return (
    <div className="flex flex-column min-h-full">
      <div className="flex flex-none w-12 grid grid-nogutter min-h-17rem">
        <SfProductDetail
          gtin={gtin}
          random={random}
          logSearch={logSearch}
          onFound={setGtinToSearch}
          onNotFound={setProductNotFound}
        />
        {gtinToSearch && (
          <SfReviewSummary
            gtin={gtinToSearch}
            stars={starFilter}
            setStars={setStarFilter}
          />
        )}
        {gtinToSearch && <SfProductSame gtin={gtinToSearch} />}
      </div>
      {!productNotFound && (
        <div className="pt-2"><SfReviewList
          gtin={gtinToSearch}
          review={review}
          stars={starFilter}
          setStars={setStarFilter}
        /></div>
      )}
    </div>
  );
};

export default SfRouteProduct;
