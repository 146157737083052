import { useContext, useCallback } from "react";
import ShelfiveAuthContext from "../contexts/shelfive-auth";

const useRoles = () => {
  const authCtx = useContext(ShelfiveAuthContext);
  const isLogged = authCtx.isLogged;
  const profile = authCtx.userData?.profile?.[0]?.user_profile;
  const userLink = authCtx.userData?.profile?.[0]?.user_link;
  const roles = authCtx.userData?.roles;

  const canReview = useCallback(() => {
    return isLogged;
  }, [isLogged]);

  const canReportReview = useCallback(
    (review) => {
      return isLogged && !review.is_review_author && !review.is_answer_author;
    },
    [isLogged]
  );

  const canReportAnswer = useCallback(
    (review) => {
      return isLogged && !review.is_review_author && !review.is_answer_author;
    },
    [isLogged]
  );

  const canAnswer = useCallback(
    (review) => {
      if (!isLogged || !roles || roles.length === 0) return false;
      if (review.is_review_author) return false;
      if (profile !== "brand admin") return false;

      for (const role of roles) {
        if (role.role_type !== "editor") {
          if (
            role.role_id_brand === review.product_id_brand ||
            role.role_id_brand === review.product_id_brand_2 ||
            role.role_id_brand === review.product_id_brand_3
          ) {
            return true;
          }
        }
      }

      return false;
    },
    [isLogged, roles, profile]
  );

  const canEdit = useCallback(
    (product) => {
      if (!isLogged || !product) return false;
      if (profile === "sys admin" || profile === "data editor") return true;
      if (profile !== "brand admin") return false;

      for (const role of roles) {
        if (role.role_type !== "answerer") {
          if (
            role.role_id_brand === product.product_id_brand ||
            role.role_id_brand === product.product_id_brand_2 ||
            role.role_id_brand === product.product_id_brand_3
          ) {
            return true;
          }
        }
      }

      return false;
    },
    [isLogged, profile, roles]
  );

  const canCreate = useCallback(() => {
    if (!isLogged) return false;
    if (profile === "sys admin" || profile === "data editor") return true;

    return false;
  }, [isLogged, profile]);

  const isDataEditor = useCallback(() => {
    if (!isLogged) return false;
    return profile === "sys admin" || profile === "data editor";
  }, [isLogged, profile]);

  const isBrandAdmin = useCallback(() => {
    if (!isLogged) return false;
    return profile === "brand admin";
  }, [isLogged, profile]);

  return {
    isLogged,
    profile,
    userLink,
    canReview,
    canAnswer,
    canReportReview,
    canReportAnswer,
    canEdit,
    canCreate,
    isDataEditor,
    isBrandAdmin,
  };
};

export default useRoles;
