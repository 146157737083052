import { useCallback, useState, useEffect, useContext } from "react";
import { isMobile } from "react-device-detect";
import ShelfiveEnvContext from "../../../contexts/shelfive-env";
import useShelfiveGames from "../../../hooks/use-shelfive-games";
import useRoles from "../../../hooks/use-roles";
import useNavigation from "../../../hooks/use-navigation";
import SfButton from "../../ui/wrappers/SfButton";
import SfDiv from "../../ui/wrappers/SfDiv";
import SfError from "../../ui/SfError";
import SfNoLogin from "../../ui/SfNoLogin";
import SfGameBoardScore from "./ui/SfGameBoardScore";
import SfGameActiveProduct from "./ui/SfGameActiveProduct";
import SfGamePickedProducts from "./ui/SfGamePickedProducts";
import SfGameResults from "./ui/SfGameResults";
import {
  getNameLength,
  sortByLength,
  vibrate,
} from "../../../scripts/sw-games";

const audioBgMusic = new Audio("res/gamefive15s.mp3");
const audioPickedProd = new Audio("res/picked.mp3");

const gameId = 1;
const productsInList = 15;
const timePerProduct = 1000;
const productsToPick = 5;
let timeoutHandler;

const SfGamefive = () => {
  const { gameStart, gameEnd, isLoading, error } = useShelfiveGames();
  const { isLogged } = useRoles();
  const [gameStarting, setGameStarting] = useState(false);
  const [gameRunning, setGameRunning] = useState(false);
  const [gameEnded, setGameEnded] = useState(false);
  const [playLink, setPlayLink] = useState();
  const [productList, setProductList] = useState();
  const [pickedProducts, setPickedProducts] = useState([]);
  const [productsLeft, setProductsLeft] = useState(productsInList);
  const [score, setScore] = useState(0);
  const [maxScore, setMaxScore] = useState(0);
  const [scorePercent, setScorePercent] = useState(0);
  const envCtx = useContext(ShelfiveEnvContext);
  const [soundsEnabled, setSoundsEnabled] = useState(envCtx.soundsEnabled);
  const { routes, goTo } = useNavigation();
  const playSounds = soundsEnabled === "play";

  useEffect(() => {
    audioBgMusic.load();
    audioBgMusic.volume = 0.5;
    audioPickedProd.load();
    audioPickedProd.volume = 0.5;

    return () => {
      audioBgMusic.pause();
      audioPickedProd.pause();
    };
  }, []);

  const toggleSoundHandler = useCallback(() => {
    if (gameRunning) {
      if (playSounds) {
        audioBgMusic.pause();
      } else {
        audioBgMusic.play();
      }
    }

    const newSoundState = playSounds ? "stop" : "play";
    setSoundsEnabled(newSoundState);
    envCtx.setSounds(newSoundState);
  }, [envCtx, gameRunning, playSounds]);

  const newGameHandler = useCallback(() => {
    goTo(routes.play);
  }, [goTo, routes.play]);

  const displayNextProduct = useCallback(() => {
    setProductsLeft((prev) => {
      return prev - 1;
    });
  }, []);

  const pickProductHandler = useCallback(() => {
    if (
      pickedProducts.length < productsToPick &&
      !pickedProducts.includes(productsLeft)
    ) {
      setPickedProducts((oldArray) => [...oldArray, productsLeft]);
      setScore((prev) => prev + getNameLength(productList[productsLeft]));
      if (playSounds) {
        audioPickedProd.currentTime = 0;
        audioPickedProd.play();
      }
      vibrate();
    }
  }, [pickedProducts, productsLeft, productList, playSounds]);

  const startGameHandler = useCallback(async () => {
    if (!gameStarting && !gameRunning && !gameEnded) {
      setGameStarting(true);

      const json = await gameStart({ game_id: gameId, n: productsInList });
      setProductList(json?.results);
      setPlayLink(json?.play_link);

      json?.results.forEach((product) => {
        new Image().src = product.product_pic_url;
      });

      if (playSounds) {
        audioBgMusic.play();
      }

      timeoutHandler = setInterval(() => {
        displayNextProduct();
      }, timePerProduct);

      setGameStarting(false);
      setGameRunning(true);
    }
  }, [
    displayNextProduct,
    gameStarting,
    gameRunning,
    gameEnded,
    gameStart,
    playSounds,
  ]);

  useEffect(() => {
    const handleKeyUp = (e) => {
      if (e.keyCode === 32) {
        if (!gameStarting && !gameRunning && !gameEnded) {
          startGameHandler();
        }
        if (gameRunning) {
          pickProductHandler();
        }
        if (gameEnded) {
          newGameHandler();
        }
      }
    };

    document.addEventListener("keyup", handleKeyUp);
    return () => document.removeEventListener("keyup", handleKeyUp);
  }, [
    gameStarting,
    gameRunning,
    gameEnded,
    startGameHandler,
    pickProductHandler,
    newGameHandler,
  ]);

  const calculateScores = useCallback(() => {
    // Calculate max score
    const longestProducts = sortByLength(productList);

    let m = 0;
    for (let i = 0; i < productsToPick; i++) {
      m += getNameLength(longestProducts[i]);
    }
    setMaxScore(m);

    // Calculate final percentage
    let p = score / m;
    setScorePercent(p);

    return m;
  }, [productList, score]);

  useEffect(() => {
    const saveGame = async () => {
      const m = calculateScores();
      const usedGtins = pickedProducts
        .map((n) => productList[n].product_gtin)
        .join(",");

      await gameEnd({
        play_link: playLink,
        score: score,
        max_score: m,
        gtins: usedGtins,
      });
    };

    if (productsLeft < 0 && !gameEnded) {
      clearInterval(timeoutHandler);
      setGameRunning(false);
      setGameEnded(true);
      saveGame();
    }
  }, [
    productsLeft,
    calculateScores,
    gameEnd,
    gameEnded,
    score,
    maxScore,
    playLink,
    pickedProducts,
    productList,
  ]);

  const btnClass =
    "p-button border-round-sm white-space-nowrap" +
    (isMobile ? " p-2 pl-3 pr-3 text-xl" : " p-1 pl-2 pr-2");

  if (!isLogged) {
    return <SfNoLogin />;
  }

  return (
    <div>
      <div className="flex p-3 align-items-center">
        <div className="w-6 text-primary mt-0 ml-0 font-bold text-2xl flex align-items-center">
          Gamefive
          <SfDiv
            icon={playSounds ? "pi-volume-up" : "pi-volume-off"}
            title={playSounds ? "stop_music" : "play_music"}
            click={toggleSoundHandler}
            styling={
              "cursor-pointer p-button-rounded p-button-text ml-2" +
              (playSounds ? " text-primary" : " font-light text-400")
            }
          />
        </div>
        <SfGameBoardScore score={score} />
      </div>
      <SfGamePickedProducts
        products={productList}
        picked={pickedProducts}
        max={productsToPick}
      />
      <SfGameActiveProduct
        product={productList?.[productsLeft]}
        left={productsLeft}
        toPick={productsToPick - (pickedProducts?.length ?? 0)}
        gameEnded={gameEnded}
      />
      <div className="flex align-items-center justify-content-center mt-2 mb-2">
        {!gameRunning && !gameEnded && (
          <SfButton
            onClick={startGameHandler}
            styling={btnClass}
            label={isMobile ? "game_click_to_start" : "game_press_bar_to_start"}
            loading={isLoading || gameStarting}
          />
        )}
        {gameRunning && (
          <SfButton
            onClick={pickProductHandler}
            styling={btnClass}
            label={
              isMobile ? "game_click_to_choose" : "game_press_bar_to_choose"
            }
          />
        )}
        {gameEnded && (
          <SfButton
            onClick={newGameHandler}
            styling={btnClass}
            label="game_new"
          />
        )}
      </div>
      {gameEnded && (
        <SfGameResults
          gameId={gameId}
          score={score}
          maxScore={maxScore}
          scorePercent={scorePercent}
          products={productList}
          picked={pickedProducts}
        />
      )}
      {error && <SfError error={error} />}
    </div>
  );
};

export default SfGamefive;
