import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import useShelfiveLists from "../../../hooks/use-shelfive-lists";
import useNavigation from "../../../hooks/use-navigation";
import SfError from "../../ui/SfError";
import SfSelectBase from "../../ui/wrappers/SfSelectBase";
import SfFormField from "../../ui/wrappers/SfFormField";
import SfButton from "../../ui/wrappers/SfButton";
import SfIcon from "../../ui/wrappers/SfIcon";

const SfAddToListForm = ({ gtin, onSave }) => {
  const { routes, goTo } = useNavigation();
  const { t } = useTranslation();
  const [listInfo, setListInfo] = useState(null);
  const [usedLists, setUsedLists] = useState(null);
  const [unusedLists, setUnusedLists] = useState(null);
  const [form, setForm] = useState({});
  const { listGet, isLoading, error } = useShelfiveLists();
  const {
    listAddProduct,
    listRemoveProduct,
    isLoading: isSaving,
    error: errorSaving,
  } = useShelfiveLists();

  const getListName = useCallback(
    (list) => {
      let name = list.list_name;

      if (list.list_owner === "n") {
        name += " (" + list.user_name + ")";
      }
      else if (list.list_public) {
        name += " (" + t("public_list") + ")";
      }

      return name;
    },
    [t]
  );

  useEffect(() => {
    const _fetch = async () => {
      const json = await listGet({ product_gtin: gtin, linked: 1 });

      if (json?.results) {
        const allLists = json.results;
        const used = allLists.filter((list) => list.list_used);
        const unused = allLists
          .filter((list) => !list.list_used && (list.list_owner === "y" || list.list_writable))
          .map((list) => ({
            value: list.list_link,
            label: getListName(list),
          }));

        setListInfo(allLists);
        setUsedLists(used);
        setUnusedLists(unused);

        if (unused?.[0]) {
          setForm((prev) => ({ ...prev, list_link: unused[0].value }));
        }
      }
    };

    if (listInfo === null) {
      _fetch();
    }
  }, [listGet, gtin, listInfo, getListName]);

  const setValue = (e) => {
    setForm((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const addToListHandler = async (e) => {
    e.preventDefault();
    if (!form.list_link) return;

    const json = await listAddProduct(form.list_link, gtin);

    if (json?.added === "ok") {
      onSave();
    }
  };

  const removeFromList = async (e, link) => {
    e.preventDefault();

    const json = await listRemoveProduct(link, gtin);

    if (json?.removed === "ok") {
      setListInfo(null);
    }
  };

  return (
    <div className="h-full flex flex-column">
      <form onSubmit={addToListHandler}>
        <div className="mt-0 pt-0 mb-2 text-sm font-light">
          {t("my_lists_form_header")}
        </div>
        {usedLists?.length > 0 && (
          <div className="mt-2 mb-3">
            <div className="text-color-secondary mt-3 mb-2 text-sm">
              {t("product_in_lists")}:
            </div>
            {usedLists?.map((list) => (
              <div
                key={list.list_link}
                className="text-sm mt-1 flex align-items-center"
              >
                <span className="mr-2 ml-1">&#128205; {getListName(list)}</span>
                {!isSaving && (
                  <SfIcon
                    icon="pi-trash"
                    title="remove_from_list"
                    onClick={(e) => removeFromList(e, list.list_link)}
                  />
                )}
              </div>
            ))}
          </div>
        )}
        {usedLists?.length === 0 && (
          <div className="font-semibold text-color-secondary mb-3 mt-3 text-sm">
            {t("product_in_no_list")}
          </div>
        )}
        {unusedLists?.length > 0 && (
          <SfFormField
            id="list_link"
            label="list_name"
            mandatory
            icon="pi-list"
          >
            <SfSelectBase
              id="list_link"
              value={form.list_link ?? ""}
              onChange={setValue}
              options={unusedLists}
              styling="sf-dropdown sf-brand-filter"
            />
          </SfFormField>
        )}
        {unusedLists?.length > 0 && (
          <div className="flex align-items-center justify-content-end pt-2 pb-3">
            {error && <SfError error={error} />}
            {errorSaving && <SfError error={errorSaving} />}
            <SfButton
              label="add_to_list"
              iconPos="right"
              type="submit"
              loading={isSaving}
              disabled={!form.list_link || isLoading}
            />
          </div>
        )}
        {!isLoading && unusedLists?.length === 0 && (
          <div className="font-semibold mb-2 text-sm">
            {t("no_lists_available")}
          </div>
        )}
      </form>
      <div className="ml-0 pl-0 pt-2 pb-2 text-sm flex-grow-1 flex flex-column justify-content-end">
        <div className="flex align-items-center">
          <i className="pr-1 pi pi-external-link sf-linkactive" />
          <span className="sf-underline" onClick={() => goTo(routes.lists)}>
            {t("goto_lists_page")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SfAddToListForm;
