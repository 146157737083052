import { Paginator } from "primereact/paginator";
import { isMobile } from "react-device-detect";

const SfPaginator = (props) => {
  const { first, rows, totalRecords, onPageChange } = { ...props };

  if (!totalRecords || totalRecords <= rows) return;

  return (
    <div className={isMobile ? "relative" : ""} style={{ zIndex: 1003 }}>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={totalRecords}
        onPageChange={(e) => {
          onPageChange(e);
        }}
        className="sf-paginator surface-ground"
        pageLinkSize={isMobile ? 4 : 5}
      />
    </div>
  );
};

export default SfPaginator;
