import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const useError = () => {
  const { t } = useTranslation("errors");

  const tError = useCallback(
    (error) => {
      if (!error || error === "" || (!error.code && !error.error_code && !error.error_raw)) {
        return "";
      }

      const error_code = error.code ?? error.error_code ?? "e_err_000";
      const error_params = error.params ?? error.error_params ?? {};

      return t(error_code, error_params);
    },
    [t]
  );

  return { tError };
};

export default useError;
