import { useTranslation } from "react-i18next";

const SfGameBoardScore = ({ score }) => {
  const { t } = useTranslation();

  return (
    <div className="w-6">
      <div className="flex flex-column">
        <div className="text-sm text-right font-light">{t("score")}:</div>
        <div className="font-bold text-right text-5xl line-height-1">
          {score}
        </div>
      </div>
    </div>
  );
};

export default SfGameBoardScore;
