import { useState, useEffect } from "react";
import useProduct from "../../../hooks/use-shelfive-product";
import useTitle from "../../../hooks/use-title";
import SfLoading from "../../ui/SfLoading";
import SfError from "../../ui/SfError";
import SfProductCard from "./SfProductCard";

const SfProductDetail = ({ gtin, random, logSearch, onFound, onNotFound }) => {
  const [productDetails, setProductDetails] = useState();
  const { getProductDetails, isLoading, error } = useProduct();
  const setTitle = useTitle();

  useEffect(() => {
    setTitle(
      "",
      productDetails?.product_name?.length > 0
        ? productDetails.product_name
        : gtin
    );
  }, [setTitle, productDetails, gtin]);

  useEffect(() => {
    const _fetch = async () => {
      const json = await getProductDetails({
        product_gtin: gtin ?? "",
        random_gtin: random && !gtin ? 1 : 0,
        log_search: logSearch ? 1 : 0,
      });
      setProductDetails(json?.[0] ?? {});

      if (json?.length > 0) {
        onNotFound(false);
        onFound(json?.[0]?.product_gtin);
      }
    };

    _fetch();
  }, [getProductDetails, gtin, random, logSearch, onFound, onNotFound]);

  if (isLoading) {
    return <SfLoading mode="corner" />;
  }
  if (error) {
    return <SfError mode="screen" error={{ code: "e_err_000" }} />;
  }

  return (
    <div className="col-12 md:col-8 lg:col-6">
      <SfProductCard
        mode="details"
        gtin={gtin ?? productDetails?.product_gtin}
        product={productDetails}
      />
    </div>
  );
};

export default SfProductDetail;
