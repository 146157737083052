import {
  isChrome,
  isFirefox,
  isSamsungBrowser,
  isMobileSafari,
} from "react-device-detect";
import { useTranslation } from "react-i18next";

const SfA2hs = () => {
  const { t } = useTranslation("a2hs");

  const displayBrowserSpecificHelp = () => {
    if (isChrome || isFirefox) {
      return (
        <div>
          <div className="mt-2">
            {t("chrome_1")}
            <i className="ml-1 pi pi-ellipsis-v" />
          </div>
          <div className="mt-2">{t("chrome_2")}</div>
          <div className="mt-2">{t("chrome_3")}</div>
        </div>
      );
    }

    if (isSamsungBrowser) {
      return (
        <div>
          <div className="mt-2">
            {t("samsung_1")}
            <i className="ml-2 pi pi-bars" />
          </div>
          <div className="mt-2">
            {t("samsung_2")}
            <i className="ml-2 pi pi-plus" />
          </div>
          <div className="mt-2">{t("samsung_3")}</div>
          <div className="mt-2">{t("samsung_4")}</div>
        </div>
      );
    }

    if (isMobileSafari) {
      return (
        <div>
          <div className="mt-2">{t("safari_1")}</div>
          <div className="mt-2">{t("safari_2")}</div>
          <div className="mt-2">{t("safari_3")}</div>
        </div>
      );
    }

    // Generic browser...
    return (
        <div>
          <div className="mt-2">{t("other_1")}</div>
          <div className="mt-2">{t("other_2")}</div>
          <div className="mt-2">{t("other_3")}</div>
        </div>
      );
  };

  return (
    <div>
      <div className="text-sm mb-4 mt-2">
        {t("header_1")}
        <br />
        {t("header_2")}
      </div>
      <div>{displayBrowserSpecificHelp()}</div>
      <div className="mt-4 text-sm">{t("footer_1")}</div>
    </div>
  );
};

export default SfA2hs;
