import { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import useShelfiveProduct from "../../../hooks/use-shelfive-product";
import SfReviewBadge from "./SfReviewBadge";

const SfReviewSummary = ({gtin, stars, setStars}) => {
  const [reviewSummary, setReviewSummary] = useState(null);
  const { getReviewSummary, isLoading } = useShelfiveProduct();

  useEffect(() => {
    const _fetch = async () => {
      const json = await getReviewSummary(gtin);
      setReviewSummary(json?.[0]);
    };

    _fetch();
  }, [gtin, getReviewSummary]);

  if (isLoading) {
    return;
  }

  if ((!reviewSummary || reviewSummary.length === 0) && isMobile) return;

  return (
    <div className="col-12 md:col-4 lg:col-2 pl-2 md:p-2 lg:pl-4">
      <div className="pl-2 md:p-2 flex flex-row h-10rem md:h-14rem">
        {reviewSummary && (
          <div>
            <SfReviewBadge
              mode="star-big"
              rating={reviewSummary.avg_stars}
              tot={reviewSummary.count_reviews_tot}
            />
            {[5, 4, 3, 2, 1].map((n) => {
              return (
                <SfReviewBadge
                  key={n}
                  mode="bar-big"
                  n={n}
                  tot={reviewSummary["count_reviews_" + n]}
                  perc={reviewSummary["perc_reviews_" + n]}
                  stars={stars}
                  setStars={setStars}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SfReviewSummary;
