import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import ShelfiveEnvContext from "../../contexts/shelfive-env";
import SfSwitch from "../ui/wrappers/SfSwitch";

const SfThemeSelector = () => {
  const envCtx = useContext(ShelfiveEnvContext);
  const currentUITheme = envCtx.currentUITheme;
  const [darkMode, setDarkMode] = useState(currentUITheme);
  const { t } = useTranslation();

  const changeThemeHandler = (e) => {
    setDarkMode(e.value);
    envCtx.setUITheme(e.value);
  };

  return (
    <div className="p-2 pt-3 flex align-items-center">
      <div className="pr-2">{t("theme_selector")}:</div>
      <SfSwitch
        checked={darkMode}
        onChange={changeThemeHandler}
        trueValue="dark"
        falseValue="light"
      />
      <div className="pl-2">
        <i className={"pi pi-" + (darkMode === "dark" ? "moon" : "sun")} />
      </div>
    </div>
  );
};

export default SfThemeSelector;
