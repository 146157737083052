import { useCallback } from "react";
import { API_URL, API_TIMEOUT } from "../scripts/sw-utils";

const getQueryString = (query) => {
  let queryString = "";

  for (const key in query) {
    const sep = queryString === "" ? "?" : "&";
    const queryStringParam = key + "=" + encodeURIComponent(query[key]);
    queryString += sep + queryStringParam;
  }

  return queryString;
};

const useFetch = () => {
  const _fetchData = useCallback(async (params) => {
    const {
      method,
      endpoint,
      query,
      token,
      multipart = false,
      timeout = API_TIMEOUT,
    } = { ...params };

    let headers = params.headers ?? {};
    let url = API_URL + endpoint;
    let body = null;

    if (method === "GET") {
      url += getQueryString(query);
    } else {
      body = multipart ? query : JSON.stringify(query);
    }

    if (!multipart) {
      headers["Content-Type"] = "application/json";
    }
    if (token) {
      headers["Authorization"] = "Bearer " + token;
    }

    const timeoutController = new AbortController();
    const timeoutHandler = setTimeout(() => timeoutController.abort(), timeout);

    const reqParams = {
      method,
      body,
      headers,
      signal: timeoutController.signal,
    };

    let data = {};

    try {
      const response = await fetch(url, reqParams);

      data = await response.json();

      if (!response.ok) {
        data.http_error_code = response.status;

        if (response.status === 401 && !data.error_code) {
          // error 401 from JWT authorizer
          data.error_code = "e_auth_011";
        } else if (response.status === 500) {
          data.error_code = "e_err_000";
        }
      }
    } catch (err) {
      if (
        err instanceof TypeError &&
        !data?.http_error_code &&
        !data?.error_code
      ) {
        data.error_code = "e_web_001";
      } else {
        data.error_code =
          err instanceof DOMException ? "e_web_000" : "e_err_000";
      }
      data.error_params = { error: err.message };
    }

    clearTimeout(timeoutHandler);
    return data;
  }, []);

  return _fetchData;
};

export default useFetch;
