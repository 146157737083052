import { useEffect, useState, useCallback } from "react";
import useShelfiveLists from "../../../hooks/use-shelfive-lists";
import useRoles from "../../../hooks/use-roles";
import useNavigation from "../../../hooks/use-navigation";
import useTitle from "../../../hooks/use-title";
import useMessage from "../../../hooks/use-message";
import SfListCard from "../../app/lists/SfListCard";
import SfLoading from "../../ui/SfLoading";
import SfError from "../../ui/SfError";
import SfNodata from "../../ui/SfNodata";
import SfNoLogin from "../../ui/SfNoLogin";
import SfConfirm from "../../ui/wrappers/SfConfirm";
import SfDialog from "../../ui/wrappers/SfDialog";
import SfButton from "../../ui/wrappers/SfButton";
import SfEditListForm from "../../app/lists/SfEditListForm";

const SfRouteLists = () => {
  const { isLogged } = useRoles();
  const [lists, setLists] = useState(null);
  const [listToDelete, setListToDelete] = useState();
  const [listToUpdate, setListToUpdate] = useState();
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const setTitle = useTitle();
  const { listGet, isLoading, error } = useShelfiveLists();
  const { listDelete, listCreate, isLoading: isUpdating } = useShelfiveLists();
  const { messageTag, showMessage } = useMessage();
  const { routes, goTo } = useNavigation();

  useEffect(() => {
    setTitle("title_mylists");
  }, [setTitle]);

  useEffect(() => {
    const _fetch = async () => {
      const json = await listGet({ linked: 1 });

      if (json?.results) {
        setLists(json);
      }
    };

    if (lists === null && isLogged) {
      _fetch();
    }
  }, [listGet, lists, isLogged]);

  const goToList = useCallback(
    (e) => {
      const listLink = e.currentTarget?.id?.substring(2);
      if (listLink) {
        goTo(routes.list + "/" + listLink);
      }
    },
    [goTo, routes.list]
  );

  const editListHandler = async (e, form) => {
    e.preventDefault();

    if (!form.list_name || form.list_name.trim().length === 0) return;

    const json = await listCreate(form);
    if (json?.updated === "ok") {
      goTo(routes.lists);
    }
  };

  const openEditModal = (e, list) => {
    e.stopPropagation();

    setListToUpdate(list);
  };

  const openDeleteModal = (e, list) => {
    e.stopPropagation();

    setListToDelete(list);
    setDeleteDialogVisible(true);
  };

  const deleteListHandler = async () => {
    const json = await listDelete({
      list_link: listToDelete.list_link,
      linked: listToDelete.list_owner === "y" ? 0 : 1,
    });

    if (json?.removed === "ok") {
      setLists((prev) => {
        return {
          row_count: prev.row_count - 1,
          results: prev.results.filter((list) => {
            return list.list_link !== listToDelete.list_link;
          }),
        };
      });
      showMessage("msg_list_removed");
    }

    setListToDelete();
  };

  const shareListHandler = async (e, list) => {
    e.stopPropagation();

    try {
      const shareObj = {
        title: list.list_name,
        url: window.location.origin + routes.list + "/" + list.list_link,
      };

      await navigator.share(shareObj);
    } catch (ignore) {}
  };

  if (!isLogged) {
    return <SfNoLogin />
  };

  return (
    <>
      <div className="mb-3 mt-3 ml-3 md:mt-2 md:ml-1">
        <SfButton
          label="create_list_btn"
          icon="pi pi-list"
          iconPos="left"
          onClick={() => {
            setListToUpdate({});
          }}
        />
      </div>

      {error && <SfError mode="screen" error={error} />}
      {isLoading && <SfLoading />}

      <div className="mt-5 mb-6">
        {lists?.results.map((list) => (
          <div key={list.list_link}>
            <SfListCard
              list={list}
              updating={
                isUpdating &&
                (listToDelete?.list_link === list.list_link ||
                  listToUpdate?.list_link === list.list_link)
              }
              onClick={goToList}
              onEdit={openEditModal}
              onDelete={openDeleteModal}
              onShare={shareListHandler}
            />
          </div>
        ))}
        {lists?.results?.length === 0 && (
          <SfNodata message="no_lists_message" />
        )}
      </div>
      <SfDialog
        styling="w-auto md:w-26rem sf-dialog"
        header={
          listToUpdate?.list_link
            ? "header_form_update_list"
            : listToUpdate
            ? "header_form_create_list"
            : ""
        }
        visible={!!listToUpdate}
        onHide={setListToUpdate}
      >
        <SfEditListForm
          list={listToUpdate}
          onSubmit={editListHandler}
          isLoading={isUpdating}
        />
      </SfDialog>
      <SfConfirm
        header="confirm_delete_list_head"
        message={
          listToDelete?.list_owner === "y"
            ? "confirm_delete_list_msg"
            : "confirm_unfollow_list_msg"
        }
        visible={deleteDialogVisible}
        onHide={setDeleteDialogVisible}
        onAccept={deleteListHandler}
      />
      {messageTag}
    </>
  );
};

export default SfRouteLists;
