import { Checkbox } from "primereact/checkbox";

const SfCheckbox = (props) => {
  const { id, checked, onChange, styling, icon, label, mandatory } = {
    ...props,
  };
  const classes = styling ? styling : "mr-1";

  return (
    <>
      <Checkbox
        id={id}
        checked={checked}
        onChange={onChange}
        className={classes}
        trueValue={1}
        falseValue={0}
      />
      {mandatory && <span className="vertical-align-top text-primary">*</span>}
      {icon && <i className={icon + " text-sm pl-1 pr-2"} />}
      {label}
    </>
  );
};

export default SfCheckbox;
