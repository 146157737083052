import { useState } from "react";
import { useTranslation } from "react-i18next";
import useShelfiveUser from "../../hooks/use-shelfive-user";
import SfFormField from "../ui/wrappers/SfFormField";
import SfPassword from "../ui/wrappers/SfPassword";
import SfButton from "../ui/wrappers/SfButton";
import SfError from "../ui/SfError";
import SfPasswordFields from "./SfPasswordFields";

const SfChangePassword = ({ onSuccess }) => {
  const [form, setForm] = useState({});
  const { t } = useTranslation();
  const { changePassword, isLoading, error } = useShelfiveUser();

  const setValue = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const changePasswordHandler = async (e) => {
    e.preventDefault();

    const json = await changePassword({
      user_pass: form.user_pass_old?.trim(),
      user_pass_new: form.user_pass?.trim(),
    });

    if (json.operation === "updated") {
      onSuccess();
    }
  };

  return (
    <form onSubmit={changePasswordHandler}>
      <div className="field text-base">{t("reset_your_password")}</div>
      <SfFormField
        id="user_pass_old"
        label="form_user_pass_current"
        icon="pi-key"
        mandatory
      >
        <div className="flex align-items-center justify-content-center">
          <SfPassword
            type="password"
            inputId="user_pass_old"
            toggleMask
            value={form.user_pass_old ?? ""}
            onChange={setValue}
          />
        </div>
      </SfFormField>
      <SfPasswordFields form={form} setValue={setValue} />
      <div className="flex align-items-center justify-content-center mt-3 mb-2">
        <SfButton
          label="change_password"
          loading={isLoading}
          iconPos="right"
          type="submit"
          disabled={
            !form.user_pass_old ||
            !form.user_pass ||
            form.user_pass?.trim().length < 8 ||
            form.user_pass?.trim() !== form.user_pass_2?.trim()
          }
        />
      </div>
      <div className="h-2rem">{error && <SfError error={error} />}</div>
    </form>
  );
};

export default SfChangePassword;
