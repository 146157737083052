import { useState } from "react";
import { useTranslation } from "react-i18next";
import useNavigation from "../../../hooks/use-navigation";
import useShelfiveReview from "../../../hooks/use-shelfive-review";
import useMessage from "../../../hooks/use-message";
import SfFormField from "../../ui/wrappers/SfFormField";
import SfButton from "../../ui/wrappers/SfButton";
import SfTextarea from "../../ui/wrappers/SfTextarea";
import SfError from "../../ui/SfError";
import SfConfirm from "../../ui/wrappers/SfConfirm";

const SfReviewAnswerForm = ({ review, gtin, link }) => {
  const { review_link, review_ans_text } = review || {};
  const [deleteMode, setDeleteMode] = useState(false);
  const [form, setForm] = useState({
    review_link: review_link,
    answer_desc: review_ans_text,
  });
  const { t } = useTranslation();
  const { routes, goTo } = useNavigation();
  const { createAnswer, deleteAnswer, isLoading, error } = useShelfiveReview();
  const { messageTag, showMessage } = useMessage();

  const setValue = (e) => {
    setForm((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const saveAnswerHandler = async (e) => {
    e.preventDefault();

    const json = await createAnswer(form);

    if (json?.review_link) {
      showMessage("msg_answer_creation", "msg_answer_inserted");
      refreshPage();
    }
  };

  const openDeleteConfirm = () => {
    setDeleteMode(true);
  };

  const deleteAnswerHandler = async () => {
    setDeleteMode(false);
    const json = await deleteAnswer({ review_link: review_link });

    if (json?.operation === "deleted") {
      showMessage("msg_answer_deletion", "msg_answer_deleted");
      refreshPage();
    }
  };

  const refreshPage = () => {
    if (gtin) {
      goTo(routes.product + "/" + gtin);
    }
    if (link) {
      goTo(routes.user + "/" + link);
    }
  };

  return (
    <>
      <form onSubmit={saveAnswerHandler} className="">
        <SfFormField id="answer_desc" label="answer_text_label" mandatory>
          <SfTextarea
            id="answer_desc"
            styling="w-auto md:w-30rem h-10rem"
            value={form.answer_desc ?? ""}
            onChange={setValue}
          />
        </SfFormField>
        <div className="field text-left text-sm">
          {t("answer_moderation_help")}
        </div>
        <div className="field text-right flex align-items-center justify-content-end">
          {error && <SfError error={error} />}
          <SfButton
            label="save_answer"
            loading={isLoading}
            iconPos="right"
            type="submit"
            disabled={!form.answer_desc}
          />
        </div>
        {review_ans_text && (
          <>
            <div
              onClick={openDeleteConfirm}
              className="pb-2 text-sm font-light sf-underline"
            >
              {t("delete_answer")}
            </div>
            <SfConfirm
              header="confirm_delete_answer_head"
              message="confirm_delete_answer_msg"
              visible={deleteMode}
              onHide={setDeleteMode}
              onAccept={deleteAnswerHandler}
            />
          </>
        )}
      </form>
      {messageTag}
    </>
  );
};

export default SfReviewAnswerForm;
