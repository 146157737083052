import { useTranslation } from "react-i18next";

const SfNodata = (props) => {
  const { message, position = "center" } = { ...props };
  const { t } = useTranslation();

  const getMessage = () => {
    return <div>{t(message)}</div>;
  };

  if (position === "center") {
    return (
      <div className="w-full h-full flex flex-none pl-1 pr-1 pt-5 md:flex-grow-1 align-items-center justify-content-center text-color-secondary text-center">
        {getMessage()}
      </div>
    );
  }

  if (position === "corner") {
    return <div className="mt-2 md:mt-5 ml-5 text-color-secondary">{getMessage()}</div>;
  }
};

export default SfNodata;
