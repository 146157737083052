import { useState } from "react";
import { useTranslation } from "react-i18next";
import useNavigation from "../../../hooks/use-navigation";
import SfButton from "../../ui/wrappers/SfButton";
import SfSidebar from "../../ui/wrappers/SfSidebar";
import {
  MAIL_SUPPORT,
  LINK_SOCIAL,
  getCurrentLanguage,
  isSEO,
} from "../../../scripts/sw-utils";

const SfAbout = () => {
  const { routes, goTo } = useNavigation();
  const { t, i18n } = useTranslation();
  const currentLanguage = getCurrentLanguage(i18n);
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const mailSupportEnc = MAIL_SUPPORT.replace("@", "<!--m-->@<!--s-->");

  const gotoFaqHandler = () => {
    setSidebarVisible(false);
    goTo(routes.faq);
  };

  const termsAndConditionsLink = () => {
    return (
      process.env.PUBLIC_URL +
      "/locales/" +
      currentLanguage +
      "/terms-of-use.html"
    );
  };

  const getCopyright = () => {
    const year = new Date().getFullYear();
    return (
      <span>
        &copy; {year} Shelfive - {t("all_rights_reserved")}
      </span>
    );
  };

  if (isSEO()) return;
  
  return (
    <>
      <div className="absolute bottom-0 left-0 opacity-80 p-1 pl-0">
        <SfButton
          icon="pi pi-question"
          onClick={() => setSidebarVisible(true)}
        />
      </div>
      <SfSidebar
        visible={sidebarVisible}
        position="bottom"
        onHide={() => setSidebarVisible(false)}
        modal={false}
        dismissable
        icons={() => (
          <span className="w-full text-left font-bold text-lg">
            {t("help_sidebar_title")}
          </span>
        )}
        styling="h-16rem md:h-auto"
      >
        <div>
          {t("sidebar_faq_label")}
          <span onClick={gotoFaqHandler} className="sf-underline pl-1">
            {t("sidebar_faq_link")}
          </span>
        </div>
        <div>
          {t("sidebar_terms_label")}
          <a
            className="sf-underline pl-1"
            href={termsAndConditionsLink()}
            rel="noreferrer"
            target="_blank"
          >
            {t("sidebar_terms_link")}
          </a>
        </div>
        <div>
          {t("sidebar_mail_label")}
          <a
            className="sf-underline pl-1"
            href={"mailto:" + MAIL_SUPPORT}
            dangerouslySetInnerHTML={{ __html: mailSupportEnc }}
          />
        </div>
        <div className="mr-0 mt-3 md:mt-2 flex align-items-center justify-content-end">
          <div className="pr-2 flex">
            <a href={LINK_SOCIAL.instagram} title="Instagram" target="_blank" rel="noreferrer">
              <i className="pi pi-instagram" style={{ fontSize: "1.5rem" }} />
            </a>
          </div>
          <div className="pr-2 flex">
            <a href={LINK_SOCIAL.facebook} title="Facebook" target="_blank" rel="noreferrer">
              <i className="pi pi-facebook" style={{ fontSize: "1.5rem" }} />
            </a>
          </div>
          <div className="pr-3 flex">
            <a href={LINK_SOCIAL.twitter} title="Twitter" target="_blank" rel="noreferrer">
              <i className="pi pi-twitter" style={{ fontSize: "1.5rem" }} />
            </a>
          </div>
          <div className="text-sm font-light flex">{getCopyright()}</div>
        </div>
      </SfSidebar>
    </>
  );
};

export default SfAbout;
