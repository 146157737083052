import { useState } from "react";
import { useTranslation } from "react-i18next";
import useRoles from "../../../hooks/use-roles";
import useNavigation from "../../../hooks/use-navigation";
import useShelfiveProduct from "../../../hooks/use-shelfive-product";
import SfButton from "../../ui/wrappers/SfButton";
import SfError from "../../ui/SfError";
import SfFormField from "../../ui/wrappers/SfFormField";
import SfSelect from "../../ui/wrappers/SfSelect";
import SfInput from "../../ui/wrappers/SfInput";
import SfCheckbox from "../../ui/wrappers/SfCheckbox";
import SfDiv from "../../ui/wrappers/SfDiv";

const SfProductEditForm = ({ product }) => {
  const [form, setForm] = useState({
    product_gtin: product.product_gtin,
    product_name: product.product_name,
    product_size: product.product_size,
    product_on_market: product.product_on_market,
    product_id_brand: product.product_id_brand,
    product_id_brand_2: product.product_id_brand_2,
    product_id_brand_3: product.product_id_brand_3,
  });
  const { t } = useTranslation();
  const { routes, goTo } = useNavigation();
  const { modifyProduct, isLoading, error } = useShelfiveProduct();
  const { canEdit, isDataEditor } = useRoles();
  const userCanEdit = canEdit(product);
  const userisEditor = isDataEditor();

  const setValue = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.id]: e.target.value ?? e.checked ?? "",
    }));
  };

  const modifyProductHandler = async (e) => {
    e.preventDefault();
    const json = await modifyProduct(form);

    if (!!json?.affected_rows) {
      goTo(routes.product + "/" + product.product_gtin);
    }
  };

  if (!userCanEdit) return;

  return (
    <form onSubmit={modifyProductHandler}>
      <div className="pt-0 pb-4 text-sm">{t("modify_product_name_help")}</div>
      <SfFormField
        id="product_name"
        label="form_product_name"
        icon="pi-book"
        mandatory
      >
        <SfInput
          type="text"
          id="product_name"
          value={form.product_name ?? ""}
          onChange={setValue}
        />
      </SfFormField>
      <SfFormField id="product_size" label="form_product_size" icon="pi-box">
        <SfInput
          type="text"
          id="product_size"
          value={form.product_size ?? ""}
          onChange={setValue}
        />
      </SfFormField>
      {userisEditor && (
        <>
        <SfDiv
          label="form_product_brands"
          styling="mt-3 text-sm mb-1"
        />
        <SfFormField icon="pi-tags">
          <SfSelect
            id="product_id_brand"
            value={form.product_id_brand}
            list="brands"
            table=""
            listLabel="brand_name"
            listValue="brand_id"
            onChange={setValue}
            auth
            showClear
            virtualScroller
          />
        </SfFormField>
        <SfFormField icon="pi-tags">
        <SfSelect
          id="product_id_brand_2"
          value={form.product_id_brand_2}
          list="brands"
          table=""
          listLabel="brand_name"
          listValue="brand_id"
          onChange={setValue}
          auth
          showClear
          virtualScroller
        />
      </SfFormField>
        <SfFormField icon="pi-tags">
        <SfSelect
          id="product_id_brand_3"
          value={form.product_id_brand_3}
          list="brands"
          table=""
          listLabel="brand_name"
          listValue="brand_id"
          onChange={setValue}
          auth
          showClear
          virtualScroller
        />
      </SfFormField></>
  )}
      <div className="field mt-4 text-sm mb-3 md:mb-0 pb-0">
        <SfCheckbox
          id="product_on_market"
          checked={form.product_on_market ?? false}
          onChange={setValue}
          icon="pi pi-shopping-cart text-red-600"
          label={t("product_out_of_market")}
        />
      </div>
      <div className="field text-right flex align-items-center justify-content-end pt-2">
        {error && <SfError error={error} />}
        <SfButton
          label="modify_product_btn"
          loading={isLoading}
          iconPos="right"
          type="submit"
          disabled={!form.product_name || form.product_name.trim().length === 0}
        />
      </div>
    </form>
  );
};

export default SfProductEditForm;
