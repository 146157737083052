import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useRoles from "../../hooks/use-roles";
import useNavigation from "../../hooks/use-navigation";
import useTitle from "../../hooks/use-title";
import useShelfiveUser from "../../hooks/use-shelfive-user";
import { qsGetFromUrl } from "../../scripts/sw-utils";
import SfError from "../ui/SfError";

const SfActivateAccount = () => {
  const { isLogged } = useRoles();
  const { link } = useParams();
  const [searchParams] = useSearchParams();
  const linkPass = qsGetFromUrl(searchParams)["link_pass"];
  const { t } = useTranslation();
  const { activateUser, isLoading, error } = useShelfiveUser();
  const { goTo } = useNavigation();
  const setTitle = useTitle();
  const [activated, setActivated] = useState(false);

  useEffect(() => {
    setTitle("title_activateuser");
  }, [setTitle]);

  useEffect(() => {
    const _activate = async () => {
      const json = await activateUser({
        link_pass: linkPass,
        user_link: link,
      });

      if (json?.status === "activated") {
        setActivated(true);
      }
    };

    if (link && linkPass && !isLogged) {
      _activate();
    }
  }, [link, linkPass, activateUser, isLogged]);

  useEffect(() => {
    if (activated || error || isLogged) {
      setTimeout(() => {
        goTo("/");
      }, 4000);
    }
  }, [goTo, activated, error, isLogged]);

  if (isLoading) return;

  if (isLogged) {
    return <div className="p-5">{t("activate_user_logged")}</div>;
  }

  if (error) {
    return <SfError error={error} mode="screen" />;
  }

  if (activated) {
    return (<div className="p-5 flex align-items-center">
      <i className="pi pi-check mr-2 font-bold text-green-500 text-4xl"></i>
      {t("user_activated")}
    </div>);
  }
};

export default SfActivateAccount;
