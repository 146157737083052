import { useState, useContext, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ShelfiveAuthContext from "../../contexts/shelfive-auth";
import useShelfiveUser from "../../hooks/use-shelfive-user";
import SfFormField from "../ui/wrappers/SfFormField";
import SfInput from "../ui/wrappers/SfInput";
import SfPassword from "../ui/wrappers/SfPassword";
import SfCheckbox from "../ui/wrappers/SfCheckbox";
import SfButton from "../ui/wrappers/SfButton";
import SfError from "../ui/SfError";

const SfLocalSignInForm = ({onLogin}) => {
  const { t } = useTranslation();
  const authCtx = useContext(ShelfiveAuthContext);
  const {
    forgotPassword,
    isLoading,
    error: forgotPasswordError,
  } = useShelfiveUser();
  const [form, setForm] = useState({});
  const [loginError, setLoginError] = useState(null);
  const [forgotMode, setForgotMode] = useState(false); // true after clicking "forgot password" link
  const [resetMode, setResetMode] = useState(false); // true after calling "forgotPassword" function
  const ref_user = useRef();
  const ref_btn = useRef();

  useEffect(() => {
    if (ref_user?.current) {
      ref_user.current.focus();
    }
  }, []);

  const setValue = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.id]: e.target.value ?? e.checked,
    }));
  };

  const signInHandler = (e) => {
    e.preventDefault();
    if (ref_btn.current.disabled) return;

    const _login = async () => {
      const userMail = form.user_mail.trim();
      const userPass = form.user_pass.trim();
      const rememberMe = form.remember_me !== 0;

      const authJson = await authCtx.login(userMail, userPass, rememberMe);
      setLoginError(authJson);

      if (!authJson) {
        closePopupHandler();
      }
    };

    _login();
  };

  const forgotPasswordHandler = () => {
    setForgotMode(true);
  };

  const resetPasswordHandler = async () => {
    const json = await forgotPassword({ user_mail: form.user_mail?.trim() });

    if (json?.operation === "done") {
      setResetMode(true);
    }
  };

  const closePopupHandler = () => {
    onLogin(false); // close popup
  };

  if (resetMode) {
    return (
      <div className="flex flex-column h-15rem align-items-center justify-content-center">
        <div className="mb-5"><i className="pi pi-send pr-1" />{t("reset_password_1")}</div>
        <div className="text-sm mb-2">{t("reset_password_2")}</div>
        <div className="text-sm">{t("reset_password_3")}</div>
        <div className="flex justify-content-center mt-5">
          <SfButton label="close_popup" onClick={closePopupHandler} />
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={signInHandler}>
      <SfFormField
        id="user_mail"
        label="form_user_mail"
        icon="pi-envelope"
        mandatory
      >
        <SfInput
          type="text"
          id="user_mail"
          ref={ref_user}
          value={form.user_mail ?? ""}
          onChange={setValue}
        />
      </SfFormField>
      {!forgotMode && (
        <>
          <SfFormField
            id="user_pass"
            label="form_user_pass"
            icon="pi-key"
            mandatory
          >
            <SfPassword
              type="password"
              inputId="user_pass"
              toggleMask
              value={form.user_pass ?? ""}
              onChange={setValue}
            />
          </SfFormField>
          <div className="field text-sm mt-3">
            <SfCheckbox
              id="remember_me"
              checked={form.remember_me ?? 1}
              onChange={setValue}
              label={t("remember_me")}
            />
          </div>
          <div className="field text-center">
            <SfButton
              label="sign_in"
              styling="p-button-lg border-round-md p-1 pl-3 pr-3"
              loading={authCtx.isAuthenticating}
              iconPos="right"
              type="submit"
              disabled={!form.user_mail || !form.user_pass}
              ref={ref_btn}
            />
          </div>
        </>
      )}
      {forgotMode && (
        <>
          <div className="field text-sm mt-3">{t("forgot_password_1")}</div>
          <div className="field text-sm">{t("forgot_password_2")}</div>
          <div className="field text-center">
            <SfButton
              label="send_recover_link"
              styling="border-round-md p-1 pl-3 pr-3"
              loading={isLoading}
              iconPos="right"
              disabled={!form.user_mail}
              onClick={resetPasswordHandler}
            />
          </div>
        </>
      )}
      <div className="h-2rem">
        {(loginError || forgotPasswordError) && (
          <SfError error={loginError ?? forgotPasswordError} />
        )}
      </div>
      {!forgotMode && (
        <div
          className="text-right text-sm sf-underline pb-1"
          onClick={forgotPasswordHandler}
        >
          {t("forgot_password")}
        </div>
      )}
    </form>
  );
};

export default SfLocalSignInForm;
