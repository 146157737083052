import { useRef, memo } from "react";
import SfOverlay from "../../ui/wrappers/SfOverlay";
import SfButton from "../../ui/wrappers/SfButton";
import SfThemeSelector from "../../misc/SfThemeSelector";
import SfLangSelector from "../../misc/SfLangSelector";

const SfHeaderSettings = () => {
  const ref = useRef();

  return (
    <>
      <div className="p-1 pr-2">
        <div
          className="flex align-items-center justify-content-center"
        >
          <SfButton
            onClick={(e) => ref.current.toggle(e)}
            icon="pi pi-cog"
            styling="p-button-rounded p-button-text"
          />
        </div>
      </div>
      <SfOverlay styling="w-12rem h-10rem p-0" ref={ref}>
        <div>
          <SfThemeSelector />
          <SfLangSelector />
        </div>
      </SfOverlay>
    </>
  );
};

export default memo(SfHeaderSettings);
