import { useTranslation } from "react-i18next";
import imgNotFoundProduct from "../../../../resources/img/picture_not_found.png";
import { getName } from "../../../../scripts/sw-games";

const SfGameActiveProduct = ({ product, gameEnded, left, toPick }) => {
  const { t } = useTranslation();

  const getLeftColor = () => {
    if (left < toPick) {
      return "var(--red-700)";
    }
    else if ((toPick > 0) && (left < toPick + 3)) {
      return "var(--orange-500)";
    }
    else {
      return "";
    }
  };

  return (
    <div className="flex justify-content-center mt-5 mb-5 relative">
      <div className="flex shadow-6 w-6rem h-6rem border-round-md relative ml-1 mr-1">
        {product && (
          <img
            src={product.product_pic_url || imgNotFoundProduct}
            className="w-6rem h-6rem surface-200 border-round-md"
            alt={product.product_gtin}
            style={{ aspectRatio: "1/1", objectFit: "cover" }}
          />
        )}
        {left >= 0 && (<div
          className="absolute top-0 left-0 w-full h-full flex align-items-center justify-content-center"
        >
          <div className="font-semibold text-5xl shadow-1 w-4rem h-4rem border-circle surface-100 opacity-80 flex align-items-center justify-content-center"
          style={{ color: getLeftColor(), textShadow: "0 0 1px #000000cc" }}
          >{left}</div>
        </div>)}
      </div>
      <div className="ml-1 pl-2 w-17rem shadow-1 border-round-md flex">
        {!gameEnded && (
          <div className="font-semibold text-primary">{getName(product)}</div>
        )}
        {gameEnded && (
          <div className="font-light text-400 text-sm flex w-full align-items-center justify-content-center">
            {t("game_ended")}
          </div>
        )}
      </div>
    </div>
  );
};

export default SfGameActiveProduct;
