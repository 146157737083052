import { useState } from "react";
import { useTranslation } from "react-i18next";
import useShelfiveProduct from "../../../hooks/use-shelfive-product";
import SfButton from "../../ui/wrappers/SfButton";
import SfError from "../../ui/SfError";
import SfFormField from "../../ui/wrappers/SfFormField";
import SfSelect from "../../ui/wrappers/SfSelect";
import SfInput from "../../ui/wrappers/SfInput";

const SfWrongProductForm = ({ gtin, onSuccess }) => {
  const [form, setForm] = useState({ product_gtin: gtin });
  const { t } = useTranslation();
  const { createProductWarning, isLoading, error } = useShelfiveProduct();

  const setValue = (e) => {
    setForm((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const createWarningHandler = async (e) => {
    e.preventDefault();
    const json = await createProductWarning(form);

    if (json?.operation === "added") {
      onSuccess(); // close popup
    }
  };

  return (
    <form onSubmit={createWarningHandler}>
      <div className="pt-2 pb-4 text-sm">{t("product_warning_help")}</div>
      <SfFormField
        id="warning_cause"
        label="form_warning_cause"
        icon="pi-exclamation-circle"
        mandatory
      >
        <SfSelect
          id="warning_cause"
          value={form.warning_cause}
          list="lookup"
          table="t_l_product_warning_cause"
          listLabel="l_key"
          listValue="l_value"
          onChange={setValue}
        />
      </SfFormField>
      <SfFormField
        id="warning_rvalue"
        label="form_warning_rvalue"
        icon="pi-check"
      >
        <SfInput
          type="text"
          id="warning_rvalue"
          value={form.warning_rvalue ?? ""}
          onChange={setValue}
        />
      </SfFormField>
      <div className="field text-right flex align-items-center justify-content-end pt-2">
        {error && <SfError error={error} />}
        <SfButton
          label="create_warning"
          loading={isLoading}
          iconPos="right"
          type="submit"
          disabled={!form.warning_cause}
        />
      </div>
    </form>
  );
};

export default SfWrongProductForm;
