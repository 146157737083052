import useProduct from "../../../hooks/use-shelfive-product";
import SfDiv from "../../ui/wrappers/SfDiv";

const SfFavoriteToggler = (props) => {
  const { gtin, isFavorite, toggleHandler } = { ...props };
  const { addFavorite, removeFavorite, error, isLoading } = useProduct();

  const addToFavoriteHandler = async (e) => {
    e.stopPropagation();

    await addFavorite(gtin);
    if (!error) {
      toggleHandler(true);
    }
  };

  const removeFavoriteHandler = async (e) => {
    e.stopPropagation();

    await removeFavorite(gtin);
    if (!error) {
      toggleHandler(false);
    }
  };

  if (!gtin) {
    return <div></div>;
  }

  if (isLoading) {
    return <SfDiv styling="ml-2 mr-1 pt-0" icon="pi-spin pi-cog" />;
  }

  if (isFavorite) {
    return (
      <SfDiv
        title="remove_favorite"
        click={removeFavoriteHandler}
        styling="cursor-pointer ml-2 mr-1 pt-0"
        icon="pi-heart-fill"
      />
    );
  } else {
    return (
      <SfDiv
        title="add_favorite"
        click={addToFavoriteHandler}
        styling="cursor-pointer ml-2 mr-1 pt-0"
        icon="pi-heart"
      />
    );
  }
};

export default SfFavoriteToggler;
