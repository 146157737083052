import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useProduct from "../../hooks/use-shelfive-product";
import useTitle from "../../hooks/use-title";
import useNavigation from "../../hooks/use-navigation";
import { useTranslation } from "react-i18next";
import SfLoading from "../../components/ui/SfLoading";
import SfError from "../../components/ui/SfError";

const limit = 100;

const SfRouteSEO = () => {
  const { page } = useParams();
  const { t } = useTranslation();
  const { getSEOList, isLoading, error } = useProduct();
  const setTitle = useTitle();
  const [names, setNames] = useState();
  const [rowCount, setRowCount] = useState();
  const { routes } = useNavigation();

  useEffect(() => {
    setTitle("title_seo", page ? " - " + page : "");
  }, [setTitle, page]);

  useEffect(() => {
    const _fetchNames = async () => {
      const query = { limit: limit, start: (page - 1) * limit + 1 };
      const json = await getSEOList(query);
      setNames(json?.results);
    };

    const _fetchCount = async () => {
      const query = { row_count: 1 };
      const json = await getSEOList(query);
      setRowCount(json?.row_count);
    };

    if (!names && page) {
      _fetchNames();
    }
    if (!rowCount && !page) {
      _fetchCount();
    }
  }, [getSEOList, page, names, rowCount]);

  if (isLoading) {
    return <SfLoading />;
  }

  if (error) {
    return <SfError mode="screen" error={error} />;
  }

  const getPageLink = (i) => {
    return (
      <div key={i + 1} className="shadow-1 mb-3 mt-1 p-1">
        <a
          className="sf-underline"
          title={t("seo_index_link", { page: i + 1 })}
          href={window.location.href + "/" + (i + 1)}
        >
          {t("seo_index_link", { page: i + 1 })}
        </a>
      </div>
    );
  };

  if (rowCount) {
    return (
      <>
        {[...Array(Math.ceil(rowCount / limit))].map((p, i) => {
          return getPageLink(i);
        })}
      </>
    );
  }

  if (names) {
    const baseURL = window.location.origin + routes.search + "?q=";
    const prevPage = (
      <span className="p-2 shadow-2 m-2 ml-4 mr-4">
        <a
          className="sf-underline"
          href={window.location.href.replace("/" + page, "/" + (page - 1))}
        >
          &laquo; {t("seo_prev_page")}
        </a>
      </span>
    );
    const nextPage = (
      <span className="p-2 shadow-2 m-2 ml-4 mr-4">
        <a
          className="sf-underline"
          href={window.location.href.replace("/" + page, "/" + (page + 1))}
        >
          {t("seo_next_page")} &raquo;
        </a>
      </span>
    );

    return (
      <>
        {names.map((name, idx) => {
          return (
            <div key={idx} className="shadow-1 mb-3 p-1">
              <a
                rel="nofollow"
                className="sf-underline mt-1"
                href={baseURL + name.product_name}
                title={t("seo_page_link", { name: name.product_name })}
              >
                {name.product_name}
              </a>
            </div>
          );
        })}
        {names?.length > 0 && (<div className="mt-4 mb-6 flex justify-content-center">
          {page > 1 && prevPage}{nextPage}
        </div>)}
      </>
    );
  }

  return <div>Product indexing...</div>;
};

export default SfRouteSEO;
