import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useShelfiveCommon from "../../../hooks/use-shelfive-common";
import { Dropdown } from "primereact/dropdown";
import { getCurrentLanguage } from "../../../scripts/sw-utils";

let prefilledOptions = {};

const SfSelect = (props) => {
  const {
    id,
    value,
    options,
    onChange,
    styling,
    list,
    table,
    listLabel,
    listValue,
    translate,
    auth=false,
    showClear=false,
    virtualScroller=false,
  } = { ...props };
  const { t, i18n } = useTranslation("buttons");
  const currentLanguage = getCurrentLanguage(i18n);
  const classes = styling ? styling : "sf-dropdown";
  const { getConfigList } = useShelfiveCommon();
  const [dbOptions, setDbOptions] = useState(options);
  const dbProps = { id, value, onChange, showClear, options: dbOptions };
  if (virtualScroller) {
    dbProps['virtualScrollerOptions'] = {itemSize: 30};
  }


  useEffect(() => {
    const _list = async () => {
      const json = await getConfigList({
        list_type: list,
        table_name: table,
        list_lang: currentLanguage,
      }, auth);

      if (json?.length > 0 || table) {
        let jsonOptions = [];

        if (table) {
          // Data from a lookup table {label1: value1, label2: value2, ...}
          for (const option in json) {
            jsonOptions.push({
              label: [t("lookup " + option)],
              value: json[option],
            });
          }
        } else {
          jsonOptions = json.map((option) => {
            return {
              label: option[listLabel],
              value: option[listValue],
            };
          });
        }

        setDbOptions(jsonOptions);
        prefilledOptions[
          list + (table ? "_" + table : "") + "_" + currentLanguage
        ] = jsonOptions;
      }
    };

    if (list) {
      if (prefilledOptions[list + "_" + currentLanguage]) {
        setDbOptions(prefilledOptions[list + "_" + currentLanguage]);
      } else {
        _list();
      }
    } else {
      if (translate) {
        setDbOptions(
          options.map((option) => {
            return {
              label: t(option.label),
              value: option.value,
            };
          })
        );
      } else {
        setDbOptions(options);
      }
    }
  }, [
    currentLanguage,
    getConfigList,
    t,
    list,
    table,
    listLabel,
    listValue,
    options,
    translate,
    auth,
  ]);

  return (
    <Dropdown
      {...dbProps}
      filter={dbOptions?.length > 10}
      className={classes}
    />
  );
};

export default SfSelect;
