import { useState, useRef, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useShelfiveUser from "../../hooks/use-shelfive-user";
import SfButton from "../ui/wrappers/SfButton";
import SfInput from "../ui/wrappers/SfInput";
import SfPasswordFields from "./SfPasswordFields";
import SfCheckbox from "../ui/wrappers/SfCheckbox";
import SfFormField from "../ui/wrappers/SfFormField";
import SfError from "../ui/SfError";
import {
  getCurrentLanguage,
  qsGetFromUrl,
  isValidLink,
} from "../../scripts/sw-utils";

const steps = ["usermail", "password", "username"];

const SfLocalSignUpForm = ({ onHide }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = getCurrentLanguage(i18n);
  const { createUser, isLoading, error } = useShelfiveUser();
  const [form, setForm] = useState({});
  const [signUpStep, setSignUpStep] = useState(0);
  const [userCreated, setUserCreated] = useState(false);
  const ref_user = useRef();

  // Actions (sign up invitations)
  const searchParams = useSearchParams()[0];
  const qs = useMemo(() => qsGetFromUrl(searchParams), [searchParams]);

  useEffect(() => {
    if (qs?.act === "signup" && isValidLink(qs?.by)) {
      setForm((prev) => ({ ...prev, user_inviting: qs.by.trim() }));
    }
  }, [qs]);

  useEffect(() => {
    ref_user?.current?.focus();
    setSignUpStep(0);
  }, []);

  const setValue = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.id]: e.target.value ?? e.checked,
    }));
  };

  const signUpHandler = async () => {
    const trimmedForm = {
      user_mail: form.user_mail.trim(),
      user_pass: form.user_pass.trim(),
      user_name: form.user_name.trim(),
      user_lang: currentLanguage,
      user_inviting: form.user_inviting ?? "",
    };

    const json = await createUser(trimmedForm);

    if (json.user_link) {
      setUserCreated(true);
    }
  };

  const closeWizardHandler = () => {
    onHide(false); // close popup
  };

  const termsAndConditionsLink = () => {
    return (
      <>
        {t("term_of_use_link_1")}&nbsp;
        <a
          href={
            process.env.PUBLIC_URL +
            "/locales/" +
            currentLanguage +
            "/terms-of-use.html"
          }
          className="sf-underline text-sm"
          rel="noreferrer"
          target="_blank"
        >
          {t("term_of_use_link_2")}
        </a>
      </>
    );
  };

  const isValidUsername = () => {
    if (!form?.user_name) return false;
    if (form.user_name.trim().length === 0) return false;
    if (/[^0-9a-zA-Z _-]/.test(form.user_name)) return false;

    return true;
  };

  if (userCreated) {
    return (
      <div className="h-12rem text-base">
        <div>
          <div className="flex align-items-center">
            <i className="pi pi-check mr-2 font-bold text-green-500 text-4xl"></i>
            {t("account_created_msg_1")}
            <br />
            {t("account_created_msg_2")}
          </div>
          <div className="mt-5">{t("check_your_inbox_msg")}</div>
        </div>
        <div className="flex justify-content-center mt-5">
          <SfButton label="close_popup" onClick={closeWizardHandler} />
        </div>
      </div>
    );
  }

  const getStepFields = () => {
    if (steps[signUpStep] === "usermail") {
      return (
        <>
          <div className="text-base">{t("create_your_account")}</div>
          <div className="pt-5">
            <SfFormField
              id="user_mail"
              label="form_user_mail"
              icon="pi-envelope"
              mandatory
            >
              <SfInput
                type="text"
                id="user_mail"
                ref={ref_user}
                value={form.user_mail ?? ""}
                onChange={setValue}
              />
            </SfFormField>
          </div>
          {form.user_inviting && (
            <div className="pt-4 text-primary text-sm font-semibold">
              <i className="pi pi-users pr-1" />{t("signup_with_invitation")}
            </div>
          )}
        </>
      );
    }

    if (steps[signUpStep] === "password") {
      return (
        <div className="fadeinright">
          <SfPasswordFields form={form} setValue={setValue} />
        </div>
      );
    }

    if (steps[signUpStep] === "username") {
      return (
        <div className="fadeinright">
          <SfFormField
            id="user_name"
            label="form_user_name"
            icon="pi-user"
            mandatory
          >
            <SfInput
              type="text"
              id="user_name"
              value={form.user_name ?? ""}
              onChange={setValue}
              keyfilter={/^[0-9a-zA-Z _-]/}
            />
          </SfFormField>
          <div className="text-sm mt-0 pt-0 mb-5">{t("user_name_note")}</div>
          <div className="text-left text-sm mb-5 ml-1 md:ml-0">
            <SfCheckbox
              id="accept"
              checked={form.accept ?? false}
              onChange={setValue}
              label={termsAndConditionsLink()}
              mandatory={true}
            />
          </div>
          <div className="h-2rem">{error && <SfError error={error} />}</div>
          <div className="absolute bottom-0 right-0 mb-3 mr-1">
            <SfButton
              label="sign_up"
              styling="p-button-lg border-round-md p-1 pl-3 pr-3"
              loading={isLoading}
              iconPos="right"
              onClick={signUpHandler}
              disabled={!isValidUsername() || !form.accept}
            />
          </div>
        </div>
      );
    }
  };

  const getPrevButton = () => {
    if (signUpStep > 0) {
      return (
        <SfButton
          styling="p-button-sm border-round-sm p-1 ml-1 mb-3 pr-2 absolute bottom-0 left-0"
          label="step_previous"
          iconPos="left"
          icon="pi pi-angle-left"
          onClick={() => setSignUpStep((prev) => prev - 1)}
        />
      );
    }
  };

  const getNextButton = () => {
    if (signUpStep < steps.length - 1) {
      const disabled =
        (signUpStep === 0 && !form.user_mail) ||
        (signUpStep === 1 &&
          (!form.user_pass ||
            !form.user_pass_2 ||
            form.user_pass.trim().length < 8 ||
            form.user_pass.trim() !== form.user_pass_2.trim()));

      return (
        <SfButton
          styling="p-button-sm border-round-sm p-1 mr-1 mb-3 pl-2 absolute bottom-0 right-0"
          label="step_next"
          iconPos="right"
          icon="pi pi-angle-right"
          onClick={() => setSignUpStep((prev) => prev + 1)}
          disabled={disabled}
        />
      );
    }
  };

  return (
    <div className="relative h-full overflow-hidden">
      {getStepFields()}
      {getPrevButton()}
      {getNextButton()}
    </div>
  );
};

export default SfLocalSignUpForm;
