import useShelfiveLists from "../../../hooks/use-shelfive-lists";
import SfDiv from "../../ui/wrappers/SfDiv";

const SfListItemToggler = (props) => {
  const { link, gtin, isInList, toggleHandler } = { ...props };
  const { listAddProduct, listRemoveProduct, error, isLoading } = useShelfiveLists();

  const addToListHandler = async (e) => {
    e.stopPropagation();

    await listAddProduct(link, gtin);
    if (!error) {
      toggleHandler(true);
    }
  };

  const removeFromListHandler = async (e) => {
    e.stopPropagation();

    await listRemoveProduct(link, gtin);
    if (!error) {
      toggleHandler(false);
    }
  };

  if (!gtin || !link) {
    return <div></div>;
  }

  if (isLoading) {
    return <SfDiv styling="ml-1 mr-1 pt-0" icon="pi-spin pi-cog" />;
  }

  if (isInList) {
    return (
      <SfDiv
        title="remove_from_list"
        click={removeFromListHandler}
        styling="cursor-pointer ml-1 mr-1 pt-0"
        icon="pi-trash"
      />
    );
  } else {
    return (
      <SfDiv
        title="add_to_this_list"
        click={addToListHandler}
        styling="cursor-pointer ml-1 mr-1 pt-0"
        icon="pi-plus-circle"
      />
    );
  }
};

export default SfListItemToggler;
