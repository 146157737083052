import { useTranslation } from "react-i18next";

const SfIcon = (props) => {
  const { t } = useTranslation("buttons");
  const i18title = props.title ? t(props.title) : null;
  const classes =
    "mr-2 text-color-secondary text-light cursor-pointer pi " +
    props.icon +
    (props.styling ? " " + props.styling : "");
  const onClick = props.onClick;

  return <i className={classes} title={i18title} onClick={onClick} />;
};

export default SfIcon;
