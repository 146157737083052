import { useState } from "react";
import { useTranslation } from "react-i18next";
import useShelfiveProduct from "../../../hooks/use-shelfive-product";
import useNavigation from "../../../hooks/use-navigation";
import SfConfirm from "../../ui/wrappers/SfConfirm";
import SfError from "../../ui/SfError";

const SfProductCreate = ({ gtin }) => {
  const { t } = useTranslation();
  const { routes, goTo } = useNavigation();
  const { createProduct, isLoading, error } = useShelfiveProduct();
  const [showConfirm, setShowConfirm] = useState(false);

  const createProductHandler = async () => {
    const json = await createProduct(gtin);
    if (json?.created === "ok") {
      goTo(routes.product + "/" + gtin);
    }
  };

  return (
    <>
      <div className="mt-6">
        <span
          onClick={(e) => {
            setShowConfirm(true);
          }}
          className="sf-underline"
        >
          {isLoading && <i className="mr-1 pi pi-spin pi-cog" />}
          {t("ask_to_create_product")}
        </span>
      </div>
      {error && <SfError error={error} />}
      <SfConfirm
        header="confirm_create_product_head"
        message="confirm_create_product_msg"
        visible={showConfirm}
        icon="pi-database"
        onHide={() => setShowConfirm(false)}
        onAccept={createProductHandler}
      />
    </>
  );
};

export default SfProductCreate;
