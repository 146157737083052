import { useTranslation } from "react-i18next";
import useNavigation from "../../../hooks/use-navigation";

const SfUserLink = ({ name, link, self }) => {
  const { routes, goTo } = useNavigation();
  const { t } = useTranslation();

  const showUser = () => {
    goTo(routes.user + "/" + link);
  };

  if (!name) return;

  if (self) {
    return (
      <span className="text-bold text-color-secondary text-sm">{t("this_is_mine")}</span>
    );
  } else if (link) {
    return (
      <span
        className="sf-underline text-sm"
        onClick={showUser}
      >
        {name}
      </span>
    );
  } else if (name === "no user") {
    return <span className="text-sm">({t("deleted_user")})</span>;
  } else {
    return <span className="text-sm">{name}</span>;
  }
};

export default SfUserLink;
