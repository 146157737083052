import { memo } from "react";

const SfProductGtin = ({ gtin, styling }) => {
  if (!gtin || gtin.length === 0) return;

  const isGtin8 = gtin.length === 8;
  const isGtin13 = gtin.length === 13;

  const classes = (styling ?? "") +  " white-space-nowrap";
  const vBars = <span className="sf-vbar">&#8214;</span>;

  if (isGtin8) {
    const partA = gtin.substring(0, 4);
    const partB = gtin.substring(4);

    return (
      <span className={classes}>
        {vBars}
        <span>{partA}</span>
        {vBars}
        <span>{partB}</span>
        {vBars}
      </span>
    );
  }

  if (isGtin13) {
    const partA = gtin.substring(0, 1);
    const partB = gtin.substring(1, 7);
    const partC = gtin.substring(7);

    return (
      <span className={classes}>
        <span>{partA}</span>
        {vBars}
        <span>{partB}</span>
        {vBars}
        <span>{partC}</span>
        {vBars}
      </span>
    );
  }
};

export default memo(SfProductGtin);
