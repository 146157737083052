import { useState } from "react";
import { useTranslation } from "react-i18next";
import useShelfiveReview from "../../../hooks/use-shelfive-review";
import SfButton from "../../ui/wrappers/SfButton";
import SfError from "../../ui/SfError";
import SfFormField from "../../ui/wrappers/SfFormField";
import SfSelect from "../../ui/wrappers/SfSelect";

const SfReviewAbuseForm = ({ review, onSuccess }) => {
  const { link, type } = review || {};
  const [form, setForm] = useState({
    review_link: link,
    review_type: type,
  });
  const { t } = useTranslation();
  const { createReviewWarning, isLoading, error } = useShelfiveReview();

  const setValue = (e) => {
    setForm((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const createWarningHandler = async (e) => {
    e.preventDefault();
    const json = await createReviewWarning(form);

    if (json?.operation === "added") {
        onSuccess(); // close popup
    }
  };

  return (
    <form onSubmit={createWarningHandler}>
      <div className="pt-2 pb-4 text-sm">{t("review_report_warning_help")}</div>
      <SfFormField
        id="warning_cause"
        label="form_warning_cause"
        icon="pi-exclamation-circle"
        mandatory
      >
        <SfSelect
          id="warning_cause"
          value={form.warning_cause}
          list="lookup"
          table="t_l_review_warning_cause"
          listLabel="l_key"
          listValue="l_value"
          onChange={setValue}
        />
      </SfFormField>

      <div className="field text-right flex align-items-center justify-content-end pt-2">
        {error && <SfError error={error} />}
        <SfButton
          label="create_warning"
          loading={isLoading}
          iconPos="right"
          type="submit"
          disabled={!form.warning_cause}
        />
      </div>
    </form>
  );
};

export default SfReviewAbuseForm;
