import { lazy, Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useTitle from "../../hooks/use-title";
import { getCurrentLanguage } from "../../scripts/sw-utils";

const SfFAQ = () => {
  const { i18n } = useTranslation();
  const currentLanguage = getCurrentLanguage(i18n);
  const setTitle = useTitle();

  useEffect(() => {
    setTitle("title_faq");
  }, [setTitle]);

  const FAQ = lazy(() =>
    import("../../resources/locales/" + currentLanguage + "/FAQ")
  );

  return (
    <div className="flex flex-column flex-grow-1 p-2">
      <Suspense fallback={<div />}>
        <FAQ />
      </Suspense>
    </div>
  );
};

export default SfFAQ;
