import { useState, memo } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import useNavigation from "../../../hooks/use-navigation";
import useRoles from "../../../hooks/use-roles";
import useMessage from "../../../hooks/use-message";
import SfReviewBadge from "../review/SfReviewBadge";
import SfReviewHandler from "../review/SfReviewHandler";
import SfFavoriteToggler from "./SfFavoriteToggler";
import imgNotFoundProduct from "../../../resources/img/picture_not_found.png";
import { Image } from "primereact/image";
import SfDialog from "../../ui/wrappers/SfDialog";
import SfChip from "../../ui/wrappers/SfChip";
import SfDiv from "../../ui/wrappers/SfDiv";
import SfWrongProductForm from "./SfWrongProductForm";
import SfProductEditForm from "./SfProductEditForm";
import SfProductCustomizeForm from "./SfProductCustomizeForm";
import SfProductCategory from "./SfProductCategory";
import SfProductCreate from "./SfProductCreate";
import SfProductGtin from "./SfProductGtin";
import SfAddToListHandler from "../lists/SfAddToListHandler";
import SfListItemToggler from "../lists/SfListItemToggler";
import { UI_NOTE_COLORS, SHOW_PICS } from "../../../scripts/sw-utils";

const SfProductCard = ({ gtin, product, mode, onClick, listLink }) => {
  const { isLogged, canReview, canEdit, canCreate } = useRoles();
  const { messageTag, showMessage } = useMessage();
  const { hasBackUrl, goBack } = useNavigation();
  const { t } = useTranslation();
  const {
    product_gtin,
    product_name,
    product_desc,
    product_pic_url,
    brand_name,
    brand_name_2,
    brand_name_3,
    brand_pic,
    brand_pic_2,
    brand_pic_3,
    product_size,
    category_group_1,
    category_group_2,
    product_year,
    is_favorite,
    custom_notes,
    custom_color,
    review_link,
    avg_stars,
    reviews_tot,
    datasource_name,
    datasource_url,
    product_on_market,
    user_review_stars,
  } = { ...product };

  const [isFavorite, setIsFavorite] = useState(is_favorite === "y");
  const [isInList, setIsInList] = useState(!!listLink);
  const [customNotes, setCustomNotes] = useState(custom_notes ?? "");
  const [customColor, setCustomColor] = useState(custom_color ?? 0);
  const [editForm, setEditForm] = useState(false);
  const [warningForm, setWarningForm] = useState(false);
  const [customizeForm, setCustomizeForm] = useState(false);
  const userCanReview = canReview();
  const userCanCreate = canCreate();
  const userCanEdit = canEdit(product);
  const displayShareButton = navigator.canShare;

  const favoriteDiv = (
    <SfFavoriteToggler
      gtin={product_gtin}
      isFavorite={isFavorite}
      toggleHandler={setIsFavorite}
    />
  );

  const deleteItemDiv = (
    <SfListItemToggler
      link={listLink}
      gtin={product_gtin}
      isInList={isInList}
      toggleHandler={setIsInList}
    />
  );

  if (product === undefined) return;

  if (!product_gtin) {
    if (userCanReview) {
      return (
        <div className="mt-5 mr-2 ml-3 md:ml-5">
          <div className="mb-5">
            <SfProductGtin styling="pr-1 font-semibold" gtin={gtin} />
            {t("product_not_found_logged")}
          </div>
          <SfReviewHandler mode="add" gtin={gtin} isNew={true} />
          {userCanCreate && <SfProductCreate gtin={gtin} />}
        </div>
      );
    } else {
      return (
        <div className="p-4 md:p-6">
          <SfProductGtin styling="pr-1 font-semibold" gtin={gtin} />
          {t("product_not_found_not_logged")}
        </div>
      );
    }
  }

  const getProductName = () => {
    let the_name;

    if (product_name?.length > 0) {
      the_name = product_name;

      if (product_on_market) {
        the_name += " (" + t("out_of_market") + ")";
      }
    } else {
      the_name = "(" + t("noname_product") + ")";
    }

    return the_name;
  };

  const getProductReviewed = () => {
    if (user_review_stars) {
      return (
        <span
          className="sf-review-mark mr-1 font-bold sf-linkactive fadein animation-duration-500"
          title={t("your_vote", {
            vote: user_review_stars,
          })}
        >
          &#x2713;
        </span>
      );
    }
  };

  const getProductTitle = () => {
    let title = getProductName();
    const titleLC = title.toLowerCase();

    let brands = "";
    if (brand_name && titleLC.indexOf(brand_name.toLowerCase()) === -1) {
      brands = " " + brand_name;
    }
    if (brand_name_2 && titleLC.indexOf(brand_name_2.toLowerCase()) === -1) {
      brands += brands === "" ? " " + brand_name_2 : " (" + brand_name_2 + ")";
    }
    if (brand_name_3 && titleLC.indexOf(brand_name_3.toLowerCase()) === -1) {
      brands += brands === "" ? " " + brand_name_3 : " (" + brand_name_3 + ")";
    }
    title += brands;

    return title;
  };

  const getProductSize = () => {
    if (product_size) {
      return <span className="text-sm pl-1 font-normal">- {product_size}</span>;
    }
  };

  const getProductSubtitle = () => {
    let title = "";
    if (product_desc) {
      title += product_desc.substring(0, 150);
      if (product_desc.length > 150) {
        title += "...";
      }
    }
    // product_year;

    return title;
  };

  const getProductCategory = () => {
    if (category_group_2) {
      return (
        <SfProductCategory
          category1={category_group_1}
          category2={category_group_2}
        />
      );
    }
  };

  const getBackUrlDiv = (
    <i
      className="pi pi-arrow-left text-2xl pr-2 pl-1 pt-1 cursor-pointer"
      title={t("back_to_list")}
      onClick={goBack}
      style={{ color: "#F59E0B" }}
    />
  );

  const getCustomNotes = () => {
    if (customNotes) {
      if (mode === "list") {
        return (
          <span
            style={{
              marginTop: "0.5rem",
              paddingTop: "0.1rem",
              paddingBottom: "0.1rem",
            }}
            className={
              "block md:inline-block mt-1 ml-1 pl-1 pr-1 border-1 border-round sf-" +
              UI_NOTE_COLORS[customColor].label
            }
          >
            {customNotes}
          </span>
        );
      } else {
        return (
          <div
            style={{ whiteSpace: "pre-wrap" }}
            className={
              "mb-3 mr-1 p-1 border-1 border-round text-sm sf-" +
              UI_NOTE_COLORS[customColor].label
            }
          >
            {customNotes}
          </div>
        );
      }
    }
  };

  const closeWarningForm = () => {
    showMessage("msg_product_warning_creation", "msg_product_warning_inserted");
    setWarningForm(false);
  };

  const openCustomizeForm = () => {
    setCustomizeForm(true);
  };

  const closeCustomizeForm = (form) => {
    showMessage("msg_product_customized");
    setCustomNotes(form.product_notes);
    setCustomColor(form.note_color);
    setCustomizeForm(false);
  };

  const shareProductHandler = async () => {
    try {
      const shareObj = {
        title: document.title,
        text: product_name + (brand_name ? " " + brand_name : ""),
        url: document.URL,
      };

      await navigator.share(shareObj);
    } catch (ignore) {}
  };

  const getActionProductDiv = () => {
    return (
      <div className="flex flex-grow-1 justify-content-end pr-2 md:pr-1 pt-1 pl-3">
        {displayShareButton && (
          <SfDiv
            styling="text-400 cursor-pointer ml-1 mr-3"
            icon="pi-share-alt"
            title="share_product"
            click={shareProductHandler}
          />
        )}
        {isLogged && (
          <SfDiv
            styling="text-400 cursor-pointer"
            icon="pi-tag"
            title="customize_product"
            click={openCustomizeForm}
          />
        )}
      </div>
    );
  };

  if (mode === "list" && !SHOW_PICS) {
    const productReviewed = getProductReviewed();
    const productTitle = getProductTitle();
    const productSize = getProductSize();
    const productSubtitle = getProductSubtitle();
    const productCategory = getProductCategory();

    return (
      <div
        id={isMobile ? "p-" + product_gtin : undefined}
        onClick={isMobile ? onClick : undefined}
        className={
          "mb-2 ml-2 mr-2 md:ml-3 md:mr-3 pl-1 pr-1 flex flex-column" +
          (isMobile ? " sf-item-mobile" : "")
        }
      >
        <div className="flex align-items-center align-content-center">
          <div
            id={!isMobile ? "p-" + product_gtin : undefined}
            onClick={!isMobile ? onClick : undefined}
            className="sf-underline text-lg font-normal line-height-2 flex-grow-1 md:flex-grow-0"
          >
            {productReviewed}
            {productTitle}
            {productSize}
          </div>
          {isLogged && favoriteDiv}
          {isLogged && !!listLink && deleteItemDiv}
        </div>
        <div className="text-sm text-color-secondary">
          <SfProductGtin
            styling="pr-2 text-sm font-normal text-color-secondary"
            gtin={product_gtin}
          />
          <SfReviewBadge
            mode="star-small"
            rating={avg_stars}
            tot={reviews_tot}
          />
          {productCategory && <span className="pr-2">{productCategory}</span>}
          {productSubtitle && !customNotes && (
            <span className="block md:inline">{productSubtitle}</span>
          )}
          {isLogged && getCustomNotes()}
        </div>
      </div>
    );
  }

  if (mode === "list" && SHOW_PICS) {
    const productReviewed = getProductReviewed();
    const productTitle = getProductTitle();
    const productSize = getProductSize();
    const productSubtitle = getProductSubtitle();
    const productCategory = getProductCategory();

    return (
      <div
        id={isMobile ? "p-" + product_gtin : undefined}
        onClick={isMobile ? onClick : undefined}
        className={
          "mb-2 ml-2 mr-2 md:ml-3 md:mr-3 pl-1 pr-1 flex" +
          (isMobile ? " sf-item-mobile" : "")
        }
      >
        <div className="flex shadow-7 w-4rem h-4rem border-round-md relative ml-1 mr-2 md:mb-2">
          <img
            src={product_pic_url || imgNotFoundProduct}
            className="w-4rem h-4rem surface-200 border-round-md"
            alt={product_gtin}
            style={{ aspectRatio: "1/1", objectFit: "cover" }}
          />
        </div>
        <div className="flex flex-column flex-grow-1">
          <div className="flex md:align-items-center">
            <div
              id={!isMobile ? "p-" + product_gtin : undefined}
              onClick={!isMobile ? onClick : undefined}
              className="sf-underline text-normal font-medium md:text-lg md:font-normal line-height-2 flex-grow-1 md:flex-grow-0"
            >
              {productReviewed}
              {productTitle}
              {productSize}
            </div>
            {isLogged && favoriteDiv}
            {isLogged && !!listLink && deleteItemDiv}
          </div>
          <div className="text-sm text-color-secondary">
            <SfProductGtin
              styling="pr-2 text-sm font-normal text-color-secondary"
              gtin={product_gtin}
            />
            <SfReviewBadge
              mode="star-small"
              rating={avg_stars}
              tot={reviews_tot}
            />
            {productCategory && <span className="pr-2">{productCategory}</span>}
            {productSubtitle && !customNotes && (
              <span className="block md:inline">{productSubtitle}</span>
            )}
            {isLogged && getCustomNotes()}
          </div>
        </div>
      </div>
    );
  }

  if (mode === "details") {
    return (
      <>
        <div className="p-1 pr-0 pt-2 md:pt-1 flex flex-column min-h-15rem relative">
          <div className="min-h-2rem flex flex-row align-items-center justify-content-start pb-3">
            {hasBackUrl() && getBackUrlDiv}
            <div className="font-semibold text-lg line-height-2">
              {getProductName()}
              {getProductSize()}
            </div>
            {isLogged && favoriteDiv}
            {getActionProductDiv()}
          </div>
          {isLogged && getCustomNotes()}
          <div className="flex flex-row">
            <div className="flex shadow-7 w-10rem h-10rem border-round-md relative ml-1">
              <Image
                preview={!!product_pic_url}
                src={product_pic_url || imgNotFoundProduct}
                imageClassName="w-10rem h-10rem surface-200 border-round-md"
                alt={product_gtin}
                imageStyle={{ aspectRatio: "1/1", objectFit: "cover" }}
              />
              <div
                title={datasource_url}
                className="absolute left-0 font-normal"
                style={{ bottom: "-1.25rem", fontSize: "0.75rem" }}
              >
                {t("datasource")}: {datasource_name}
              </div>
            </div>
            <div className="pl-2 md:pl-3 flex-grow-1 text-sm">
              <div>
                <span className="hidden md:inline white-space-nowrap">
                  {t("bar_code")}:
                </span>
                <SfProductGtin
                  styling="pl-0 md:pl-1 font-medium text-primary"
                  gtin={product_gtin}
                />
              </div>
              <div className="pt-3 flex align-items-center flex-wrap">
                <span className="pr-1">{t("brands")}:</span>
                <SfChip label={brand_name} image={brand_pic} />
                <SfChip label={brand_name_2} image={brand_pic_2} />
                <SfChip label={brand_name_3} image={brand_pic_3} />
              </div>
              <div className="pt-3">
                <span>{t("category")}:</span>
                <span className="pt-1 pl-1">{getProductCategory()}</span>
              </div>
              <div className="pt-3 font-italic text-sm">
                {(isMobile ? "" : product_desc) +
                  (product_year ? " (" + product_year + ")" : "")}
              </div>
            </div>
          </div>
          {isMobile && product_desc && (
            <div className="font-italic text-sm mt-4 mr-1">{product_desc}</div>
          )}
          <div className="flex mt-5 align-items-center">
            <div className="flex align-items-center">
              {isLogged && (
                <span
                  onClick={(e) => {
                    userCanEdit ? setEditForm(true) : setWarningForm(true);
                  }}
                  className="text-sm sf-underline pl-1"
                >
                  {userCanEdit ? t("edit_data") : t("wrong_data")}
                </span>
              )}
            </div>
            {userCanReview && !review_link && (
              <SfReviewHandler mode="add" gtin={product_gtin} />
            )}
            {userCanReview && review_link && (
              <SfReviewHandler
                mode="modify"
                gtin={product_gtin}
                reviewLink={review_link}
              />
            )}
            {isLogged && <SfAddToListHandler gtin={product_gtin} />}
          </div>
        </div>
        <SfDialog
          styling="md:w-26rem sf-dialog"
          header="header_form_product_warning"
          visible={warningForm}
          onHide={() => {
            setWarningForm(false);
          }}
        >
          <SfWrongProductForm
            gtin={product_gtin}
            onSuccess={closeWarningForm}
          />
        </SfDialog>
        <SfDialog
          styling="md:w-26rem xl:w-30rem sf-dialog"
          header="header_form_product_edit"
          visible={editForm}
          onHide={() => {
            setEditForm(false);
          }}
        >
          <SfProductEditForm product={product} />
        </SfDialog>
        <SfDialog
          styling="md:w-26rem xl:w-30rem sf-dialog"
          header="header_form_customize_product"
          visible={customizeForm}
          onHide={() => {
            setCustomizeForm(false);
          }}
        >
          <SfProductCustomizeForm
            gtin={product_gtin}
            customNotes={customNotes}
            customColor={customColor}
            onSuccess={closeCustomizeForm}
          />
        </SfDialog>
        {messageTag}
      </>
    );
  }
};

export default memo(SfProductCard, hasSameGtin);

function hasSameGtin(prevProduct, nextProduct) {
  return (
    prevProduct.product_gtin === nextProduct.product_gtin &&
    prevProduct.is_favorite === nextProduct.is_favorite
  );
}
