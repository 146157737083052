import { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import ShelfiveEnvContext from "../../../contexts/shelfive-env";
import useProduct from "../../../hooks/use-shelfive-product";
import useNavigation from "../../../hooks/use-navigation";
import SfButton from "../../ui/wrappers/SfButton";
import SfFormField from "../../ui/wrappers/SfFormField";
import SfSelectBase from "../../ui/wrappers/SfSelectBase";

let known_brands = {};
const MAX_BRANDS = 50;

const SfProductSearchFilters = ({ query }) => {
  const { t } = useTranslation();
  const envCtx = useContext(ShelfiveEnvContext);
  const { getProductList } = useProduct();
  const [isOpen, setisOpen] = useState(envCtx.searchFilterOpen);
  const [form, setForm] = useState({ ...query });
  const { routes, goTo } = useNavigation();

  const setfilter = (status) => {
    setisOpen(status);
    envCtx.setSearchFilterOpen(status);
  };

  const setValue = (e) => {
    setForm((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  useEffect(() => {
    const _fetch = async () => {
      const json = await getProductList({...query, brand_count: 1, limit: MAX_BRANDS});
      
      if (json?.length > 0) {
        known_brands[query.q] = json.map((brand) => {
          return {label: brand.brand_name + ' (' + brand.products + ')', value: brand.brand_name}
        });
      }
    };

    if (!known_brands[query.q]) {
      _fetch();
    }
  }, [getProductList, query]);

  const filterResultsHandler = (e) => {
    e.preventDefault();

    if (form.b.length > 0) {
      if (isMobile) {
        envCtx.setSearchFilterOpen(false);
      }
      goTo(routes.search, { qs: { ...form, s: 1, c: 1 } });
    }
  };

  if (isOpen) {
    return (
      <div
        style={{ zIndex: 1002 }}
        className={
          isMobile
            ? "fixed right-0 top-0 mr-2 mt-6 w-auto h-15rem shadow-5 fadeintop opacity-100 p-3 surface-overlay"
            : "fixed right-0 bottom-0 mr-5 mb-3 w-25rem h-15rem shadow-5 fadeinbottom opacity-80 p-3 surface-overlay"
        }
      >
        <div className="flex align-items-center justify-content-center mb-3">
          <div className="font-medium text-left flex-grow-1">
            {t("form_filter_open")}
          </div>
          <div className="text-right pl-1">
            <SfButton
              icon="pi pi-arrow-down"
              onClick={() => {
                setfilter(false);
              }}
              styling="p-button-sm p-2 border-circle"
            />
          </div>
        </div>
        <form onSubmit={filterResultsHandler}>
          <SfFormField id="b" label="filter_by_brand" icon="pi-filter">
            <SfSelectBase
              id="b"
              value={form.b ?? ""}
              onChange={setValue}
              options={known_brands[query.q]}
              styling="sf-dropdown sf-brand-filter"
              appendTo={'self'}
              filter={known_brands[query.q]?.length > 10 ? true : false}
              resetFilterOnHide
            />
          </SfFormField>
          <div className="field text-right pt-2">
            <SfButton
              label="filter"
              iconPos="right"
              type="submit"
              disabled={!form.b || form.b.length === 0}
            />
          </div>
        </form>
      </div>
    );
  }

  if (!isOpen) {
    return (
      <div
        style={{ zIndex: 1002 }}
        className={
          isMobile
            ? "fixed right-0 bottom-0 mr-2 mb-7 shadow-2 flex align-items-center justify-content-center p-1 opacity-80 surface-overlay"
            : "fixed right-0 bottom-0 mr-5 mb-3 shadow-2 flex align-items-center justify-content-center p-1 opacity-80 surface-overlay"
        }
      >
        <div className="font-normal pr-2">{t("form_filter_closed")}</div>
        <SfButton
          icon="pi pi-arrow-up"
          onClick={() => {
            setfilter(true);
          }}
          styling="p-button-sm p-2 border-circle"
        />
      </div>
    );
  }
};

export default SfProductSearchFilters;
