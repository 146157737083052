import { useState } from "react";
import { useTranslation } from "react-i18next";
import useMessage from "../../../hooks/use-message";
import useRoles from "../../../hooks/use-roles";
import SfButton from "../../ui/wrappers/SfButton";
import SfDialog from "../../ui/wrappers/SfDialog";
import SfChangePassword from "../../auth/SfChangePassword";
import SfCreateApiKey from "../../auth/SfCreateApiKey";
import SfDeleteAccount from "../../auth/SfDeleteAccount";

const SfUserSettings = () => {
  const { t } = useTranslation();
  const { profile } = useRoles();
  const { messageTag, showMessage } = useMessage();
  const [openDialog, setOpenDialog] = useState("");

  const closePasswordForm = () => {
    showMessage("msg_change_password", "msg_password_changed");
    setOpenDialog("");
  };

  return (
    <div className="p-2 md:p-5">
      <div>{t("change_password_message")}</div>
      <div className="mb-5 mt-2">
        <SfButton
          label="change_password"
          icon="pi pi-key"
          iconPos="left"
          onClick={() => {
            setOpenDialog("change-password");
          }}
        />
      </div>
      {profile !== "self registered" && (
        <>
          <div>{t("create_api_key_message")}</div>
          <div className="mb-5 mt-2">
            <SfButton
              label="create_api_key"
              icon="pi pi-key"
              iconPos="left"
              onClick={() => {
                setOpenDialog("create-api-key");
              }}
            />
          </div>
        </>
      )}
      <div className="mb-3 mt-3"></div>
      {profile !== "sys admin" && (
        <div>
          <div className="mt-8">
            {t("delete_account_message")}
            <span
              onClick={() => {
                setOpenDialog("delete-account");
              }}
              className="pl-1 sf-underline"
            >
              {t("click_here")}.
            </span>
          </div>
          <div className="text-sm mt-2">
            <i className="pi pi-exclamation-triangle mr-2" />
            {t("delete_account_notes")}
          </div>
          <div className="text-sm font-light mt-1">
            {t("delete_account_notes_2")}
            {t("delete_account_notes_3")}
          </div>
          <div className="text-sm font-light mt-1">
            {t("delete_account_notes_4")}
          </div>
        </div>
      )}
      <SfDialog
        styling="w-auto md:w-26rem sf-dialog"
        header="header_form_change_pwd"
        visible={openDialog === "change-password"}
        onHide={() => {
          setOpenDialog("");
        }}
      >
        <SfChangePassword onSuccess={closePasswordForm} />
      </SfDialog>
      <SfDialog
        styling="w-auto md:w-26rem sf-dialog"
        header="header_form_create_api_key"
        visible={openDialog === "create-api-key"}
        onHide={() => {
          setOpenDialog("");
        }}
      >
        <SfCreateApiKey />
      </SfDialog>
      <SfDialog
        styling="w-auto md:w-26rem sf-dialog"
        header="header_delete_account"
        visible={openDialog === "delete-account"}
        onHide={() => {
          setOpenDialog("");
        }}
      >
        <SfDeleteAccount />
      </SfDialog>
      {messageTag}
    </div>
  );
};

export default SfUserSettings;
