import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useRoles from "../../hooks/use-roles";
import useNavigation from "../../hooks/use-navigation";
import useTitle from "../../hooks/use-title";
import useShelfiveUser from "../../hooks/use-shelfive-user";
import { qsGetFromUrl } from "../../scripts/sw-utils";
import SfButton from "../ui/wrappers/SfButton";
import SfError from "../ui/SfError";
import SfPasswordFields from "./SfPasswordFields";

const SfResetPassword = () => {
  const { link } = useParams();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { isLogged } = useRoles();
  const { resetPassword, isLoading, error } = useShelfiveUser();
  const linkPass = qsGetFromUrl(searchParams)["link_pass"];
  const [form, setForm] = useState({});
  const [linkValid, setLinkValid] = useState(false);
  const [pwdUpdated, setPwdUpdated] = useState(false);
  const { goTo } = useNavigation();
  const setTitle = useTitle();

  useEffect(() => {
    setTitle("title_resetpwd");
  }, [setTitle]);

  useEffect(() => {
    const _check = async () => {
      const json = await resetPassword({
        link_pass: linkPass,
        user_link: link,
      });

      if (json?.status === "valid") {
        setLinkValid(true);
      }
    };

    if (link && linkPass) {
      _check();
    }
  }, [link, linkPass, resetPassword]);

  const setValue = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const resetPasswordHandler = async (e) => {
    e.preventDefault();

    const json = await resetPassword({
      link_pass: linkPass,
      user_link: link,
      user_pass: form.user_pass,
    });

    if (json.operation === "updated") {
      setPwdUpdated(true);
    }
  };

  useEffect(() => {
    if (pwdUpdated) {
        setTimeout(() => {goTo("/")}, 4000);
    }
  }, [goTo, pwdUpdated]);

  if (isLogged) {
    return (
      <div className="p-5">
        <div>{t("password_reset_logged_1")}</div>
        <div>{t("password_reset_logged_2")}</div>
      </div>
    );
  }

  if (pwdUpdated) {
    return (
      <div className="p-5 flex align-items-center">
        <i className="pi pi-check mr-2 font-bold text-green-500 text-4xl"></i>
        {t("password_reset_msg_1")}
        <br />
        {t("password_reset_msg_2")}
      </div>
    );
  }

  if (linkValid) {
    return (
      <form className="p-2 md:p-5 w-auto md:w-28rem" onSubmit={resetPasswordHandler}>
        <div className="field text-base">{t("reset_your_password")}</div>
        <SfPasswordFields form={form} setValue={setValue} />
        <div className="field text-right flex align-items-center justify-content-end mt-5">
          {error && <SfError error={error} />}
          <SfButton
            label="change_password"
            loading={isLoading}
            iconPos="right"
            type="submit"
            disabled={
              !form.user_pass || !form.user_pass_2 ||
              form.user_pass?.trim().length < 8 ||
              form.user_pass?.trim() !== form.user_pass_2?.trim()
            }
          />
        </div>
      </form>
    );
  }

  return <SfError error={error} mode="screen" />;
};

export default SfResetPassword;
