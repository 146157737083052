import { useTranslation } from "react-i18next";
import { ConfirmDialog } from "primereact/confirmdialog";

const SfConfirm = (props) => {
    const { t } = useTranslation("buttons");
    const i18Props = { ...props, header: t(props.header), message: t(props.message) };

    return (
        <ConfirmDialog
        header={i18Props.header}
        message={i18Props.message}
        visible={i18Props.visible}
        accept={i18Props.onAccept}
        onHide={() => i18Props.onHide(false)}
        reject={() => i18Props.onHide(false)}
        icon={"pi " + (i18Props.icon ?? "pi-exclamation-triangle")}
        acceptClassName="p-button-sm border-round-sm p-1 pl-3 pr-3 p-button-outlined"
        rejectClassName="p-button-sm border-round-sm p-1 pl-3 pr-3 mr-4"
        className="text-sm ml-2 mr-2"
        acceptLabel={t("confirm_yes")}
        rejectLabel={t("confirm_no")}
      />
    );
};

export default SfConfirm;