import { useContext } from "react";
import { useTranslation } from "react-i18next";
import ShelfiveAuthContext from "../../contexts/shelfive-auth";

const SfNoLogin = () => {
  const authCtx = useContext(ShelfiveAuthContext);
  const isAuthenticating = authCtx.isAuthenticating;
  const { t } = useTranslation();

  if (isAuthenticating) {
    return (
      <div className="mt-2 md:mt-5 ml-5 text-color-secondary">
        <i className="pi pi-cog pi-spin mr-1" />
        {t("checking_credentials")}
      </div>
    );
  } else {
    return (
      <div className="mt-2 md:mt-5 ml-5 text-color-secondary">
        {t("you_are_not_logged_in")}
      </div>
    );
  }
};

export default SfNoLogin;
