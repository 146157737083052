import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Password } from 'primereact/password';

const SfPassword = forwardRef((props, ref) => {
  const { t } = useTranslation("buttons");
  const i18Props = { ...props, placeholder: t(props.placeholder) };
  const classes = props.styling ? props.styling : "text-sm";

  return <Password {...i18Props} className={classes} feedback={false} ref={ref} />;
});

export default SfPassword;
