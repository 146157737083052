import { useState } from "react";
import { useTranslation } from "react-i18next";
import SfFormField from "../../ui/wrappers/SfFormField";
import SfInput from "../../ui/wrappers/SfInput";
import SfButton from "../../ui/wrappers/SfButton";
import SfCheckbox from "../../ui/wrappers/SfCheckbox";

const SfEditListForm = ({ list, onSubmit, isLoading }) => {
  const { t } = useTranslation();
  const [form, setForm] = useState(list);

  const setValue = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.id]: e.target.value ?? e.checked ?? "",
    }));
  };

  return (
    <form onSubmit={(e) => onSubmit(e, form)}>
      <SfFormField
        id="list_name"
        label="form_list_name"
        icon="pi-list"
        mandatory
      >
        <SfInput
          type="text"
          id="list_name"
          value={form.list_name ?? ""}
          onChange={setValue}
        />
      </SfFormField>
      <div className="field mt-4 text-sm mb-3 md:mb-2 pb-0 flex align-items-start">
        <div className="flex align-items-center">
          <SfCheckbox
            id="list_public"
            checked={form.list_public ?? false}
            onChange={setValue}
            icon="pi pi-share-alt"
          />
        </div>
        <div className="h-5rem">
          {t("public_list_help")}
          <div className={form.list_public ? "mt-2 block" : "hidden"}>
            <SfCheckbox
              id="list_writable"
              checked={(form.list_public && form.list_writable) ?? false}
              onChange={setValue}
              label={t("list_writable_help")}
            />
          </div>
        </div>
      </div>
      <div className="field text-right flex align-items-center justify-content-end pt-2">
        <SfButton
          label={form.list_link ? "modify_btn" : form ? "create_btn" : ""}
          loading={isLoading}
          iconPos="right"
          type="submit"
          disabled={!form.list_name || form.list_name.trim().length === 0}
        />
      </div>
    </form>
  );
};

export default SfEditListForm;
