import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { Dialog } from "primereact/dialog";

const SfDialog = (props) => {
  const { t } = useTranslation("buttons");
  const classes = props.styling ? props.styling : "sf-dialog";
  const i18Props = {
    ...props,
    draggable: props.draggable || false,
    header: t(props.header),
  };

  return (
    <Dialog
      {...i18Props}
      headerClassName="p-1 pl-3 pt-2 pr-2"
      resizable={false}
      className={classes}
      position={isMobile ? "top" : "center"}
    />
  );
};

export default SfDialog;
