import { useTranslation } from "react-i18next";

const SfDiv = (props) => {
    const { t } = useTranslation("buttons");
    const {styling, title, icon, label, click} = {...props};
    const classes = styling ? styling : "";

    return (
        <div onClick={click} className={classes} title={title ? t(title) : ""}>
          {icon && <i className={"pi " + icon} />}
          {label && t(label)}
        </div>
      );
};

export default SfDiv;