import badgeActive from "../../../resources/img/badge_active_v2.png";
import badgeInactive from "../../../resources/img/badge_inactive_v2.png";

const SfBadgeCard = ({ badge, achieved }) => {
  const userHasThis =
    achieved.filter((b) => b.badge_id === badge.badge_id).length > 0;

  return (
    <div
      className={
        "fadein flex mb-4 align-items-center justify-content-start p-2 col-12 md:col-6 " +
        (userHasThis ? "shadow-5" : "shadow-1")
      }
    >
      <div
        className="scalein flex flex-grow-0 flex-shrink-0 align-items-center justify-content-center mr-3 relative"
        style={{
          width: "64px",
          height: "62px",
          backgroundImage: `url(${userHasThis ? badgeActive : badgeInactive})`,
          backgroundSize: "cover",
        }}
      >
        <i
          className={"pi " + badge.badge_pic}
          style={{
            paddingTop: "3px",
            fontSize: "2rem",
            color: userHasThis ? "var(--primary-color)" : "#ccc",
          }}
        />
        {badge.badge_number && (
          <div
            className="absolute top-0 right-0 text-sm text-white font-semibold border-circle pl-1 pr-1 opacity-80"
            style={{ backgroundColor: userHasThis ? "#909" : "#bbb" }}
          >
            {badge.badge_number}
          </div>
        )}
      </div>
      <div className="flex flex-column">
        <div
          className={
            userHasThis
              ? "font-bold text-primary"
              : "font-light text-color-secondary opacity-70"
          }
        >
          {badge.badge_name}
        </div>
        <div
          className={
            userHasThis
              ? "text-sm font-semibold"
              : "text-sm font-light text-color-secondary opacity-70"
          }
        >
          {badge.badge_desc}
        </div>
      </div>
    </div>
  );
};

export default SfBadgeCard;
