import { useTranslation } from "react-i18next";

const SfLoading = ({ mode = "center" }) => {
  const { t } = useTranslation();

  if (mode === "center") {
    return (
      <div className="flex flex-grow-1 min-h-full align-items-center justify-content-center opacity-80">
        <div className="flex flex-grow-1 align-items-center justify-content-center">
          <i className="pi pi-spin pi-cog mr-1" />
          {t("searching_message")}...
        </div>
      </div>
    );
  }

  if (mode === "corner") {
    return (
      <div className="pl-5 pt-5">
        <i className="pi pi-spin pi-cog mr-1 text-lg" />
      </div>
    );
  }
};

export default SfLoading;
