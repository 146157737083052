import { useLocation } from "react-router-dom";
import useResize from "../../../hooks/use-resize";
import SfSearchEngineBar from "../../app/product/SfSearchEngineBar";

const SfHeaderSearch = () => {
  const location = useLocation();
  const renderSearchBar = location.pathname !== "/";

  const dimensions = useResize();
  if (dimensions.width < 700) return;

  return (
    <div className="flex flex-grow-1 align-items-center justify-content-start pl-2 md:pl-5 pr-2 md:pr-5">
      {renderSearchBar && <SfSearchEngineBar size="small" />}
    </div>
  );
};

export default SfHeaderSearch;
