import { useContext, useState, useRef, memo } from "react";
import { isMobile } from "react-device-detect";
import ShelfiveAuthContext from "../../../contexts/shelfive-auth";
import SfLocalSignInForm from "../../auth/SfLocalSignInForm";
import SfLocalSignUpForm from "../../auth/SfLocalSignUpForm";
import SfHeaderMyMenu from "./SfHeaderMyMenu";
import SfButton from "../../ui/wrappers/SfButton";
import SfDialog from "../../ui/wrappers/SfDialog";
import SfAvatar from "../../ui/wrappers/SfAvatar";

const SfHeaderButtons = () => {
  const authCtx = useContext(ShelfiveAuthContext);
  const isLogged = authCtx.isLogged;
  const whoAmI = authCtx.userData?.profile?.[0].user_name;
  const [signInDialog, setSignInDialog] = useState(false);
  const [signUpDialog, setSignUpDialog] = useState(false);
  const ref = useRef();

  const signUpHandler = () => {
    setSignInDialog(false);
    setSignUpDialog(true);
  };

  const signInHandler = () => {
    setSignUpDialog(false);
    setSignInDialog(true);
  };

  const myShelfiveHandler = (e) => {
    ref.current.toggle(e);
  };

  const signOutHandler = (e) => {
    authCtx.logout();
    window.location.href = window.location.origin;
  };

  return (
    <>
      <div className="flex align-items-center justify-content-end pr-0 flex-grow-1 md:flex-grow-0">
        {!isLogged && (
          <>
            <SfButton label="sign_up" onClick={signUpHandler} />
            <SfButton
              label="sign_in"
              styling="p-button-sm border-round-sm p-1 pl-2 pr-2 ml-1 mr-1 white-space-nowrap p-button-outlined"
              onClick={signInHandler}
            />
          </>
        )}
        {isLogged && (
          <>
          <span className="pr-2 hidden md:inline"><SfAvatar name={whoAmI} /></span>
            <span className="pr-2 hidden lg:inline">{whoAmI}</span>
            <SfButton label="my_shelfive" onClick={myShelfiveHandler} />
            <SfButton
              label={isMobile ? "" : "sign_out"}
              title={isMobile ? "sign_out" : ""}
              icon="pi pi-sign-out"
              iconPos="right"
              styling="p-button-sm border-round-sm p-1 pl-2 pr-2 ml-1 mr-1 white-space-nowrap p-button-outlined"
              onClick={signOutHandler}
            />
            <SfHeaderMyMenu ref={ref} />
          </>
        )}
      </div>
      <SfDialog
        styling="md:w-24rem sf-dialog"
        header="header_form_sign_in"
        visible={signInDialog}
        onHide={() => {
          setSignInDialog(false);
        }}
      >
        <SfLocalSignInForm onLogin={setSignInDialog} />
      </SfDialog>
      <SfDialog
        styling="max-w-26rem md:w-26rem h-22rem sf-dialog"
        header="header_form_sign_up"
        visible={signUpDialog}
        onHide={() => {
          setSignUpDialog(false);
        }}
      >
        <SfLocalSignUpForm onHide={setSignUpDialog} />
      </SfDialog>
    </>
  );
};

export default memo(SfHeaderButtons);
