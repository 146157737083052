import { useTranslation } from "react-i18next";

const SfReviewBadge = (props) => {
  const { t } = useTranslation();
  const { rating, mode, n, tot, perc, stars, setStars } = { ...props };

  if (!rating && !n) {
    return <span></span>;
  }

  const ratingRounded = (Math.round(rating * 10) / 10 + ".0").substring(0, 3);

  if (mode === "star-only") {
    const theStars = { __html: "&#x2B1F;".repeat(n) };

    return (
      // <span className="white-space-nowrap">
        <span
          className="font-semibold text-primary pl-1 pr-2"
          dangerouslySetInnerHTML={theStars}
        />
      //   <span className="text-sm md:text-base font-semibold text-primary pr-1 md:pr-2">
      //     {n}
      //   </span>
      // </span>
    );
  }

  if (mode === "star-small") {
    return (
      <>
        <span className="font-semibold text-primary text-base">
          {ratingRounded}
        </span>
        <span className="font-semibold text-primary pl-1 pr-2">&#x2B1F;</span>
        <span className="text-color-secondary pr-1 white-space-nowrap">
          ({tot})
        </span>
      </>
    );
  }

  const ratingToFive = 5 - rating;
  const overlayWidth = Math.round(ratingToFive * 20) + "%";

  if (mode === "star-big") {
    return (
      <>
        <div className="flex relative">
          <div className="flex text-primary text-xl relative">
            &#x2B1F;&#x2B1F;&#x2B1F;&#x2B1F;&#x2B1F;
            <div
              className="surface-ground h-full absolute top-0 right-0 opacity-80"
              style={{ width: overlayWidth }}
            ></div>
          </div>
          <div className="flex text-primary text-xl font-medium ml-2">
            {ratingRounded}
          </div>
        </div>
        <div className="mb-2 text-sm">
          ({t("count_review", { count: tot })})
        </div>
      </>
    );
  }

  if (mode === "bar-big") {
    return (
      <div className="flex text-secondary align-items-center mt-1 mb-1">
        <div className={"flex text-sm" + (n === stars ? " font-bold" : "")}>
          {n}
        </div>
        <div
          className={
            "flex ml-1 w-6rem relative" + (tot > 0 ? " cursor-pointer" : "")
          }
          title={tot > 0 ? t("filter_by_stars") : ""}
          onClick={() => {
            if (tot > 0) setStars(n);
          }}
        >
          <div className="w-full surface-200 h-1rem">&nbsp;</div>
          <div
            className={
              "absolute left-0 top-0 h-1rem " +
              (!stars || n === stars ? "bg-primary" : "surface-300")
            }
            style={{ width: perc + "%" }}
          >
            &nbsp;
          </div>
        </div>
        <div
          className={
            "flex ml-2 text-sm text-primary" + (n === stars ? " font-bold" : "")
          }
        >
          {tot}
        </div>
      </div>
    );
  }
};

export default SfReviewBadge;
