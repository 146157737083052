import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";
import useRoles from "../../../hooks/use-roles";
import useTitle from "../../../hooks/use-title";
import useMessage from "../../../hooks/use-message";
import SfNoLogin from "../../ui/SfNoLogin";

const SfRouteShare = () => {
  const { t } = useTranslation("buttons");
  const { isLogged, userLink } = useRoles();
  const setTitle = useTitle();
  const { messageTag, showMessage } = useMessage();
  const invitationLink = window.location.origin + "?act=signup&by=" + userLink;

  useEffect(() => {
    setTitle("title_share_link");
  }, [setTitle]);

  const copyLinkHandler = () => {
    showMessage("url_copied");
  };

  if (!isLogged) {
    return <SfNoLogin />
  };

  return (
    <>
      <div className="p-3 md:p-5">
        <div className="pb-3">
          <i className="pi pi-share-alt pr-2" />
          {t("share_msg_1")}
        </div>
        <div className="pb-2">{t("share_msg_2")}:</div>
        <span className="text-primary">{invitationLink}</span>
        <CopyToClipboard
          text={invitationLink}
          onCopy={() => {
            copyLinkHandler();
          }}
        >
          <i className="pi pi-copy cursor-pointer pl-1" />
        </CopyToClipboard>
      </div>
      {messageTag}
    </>
  );
};

export default SfRouteShare;
