import { createContext, useState, useCallback } from "react";
import { isMobile } from "react-device-detect";
import { getFromStorage, UI_THEME, saveToStorage } from "../scripts/sw-utils";

const ShelfiveEnvContext = createContext({
  currentUITheme: null,
  setUITheme: () => {},
  soundsEnabled: null,
  setSoundsEnabled: () => {},
  searchFilterOpen: null,
  setSearchFilterOpen: () => {},
  backUrl: null,
  setBackUrl: () => {},
});

const storageKeyForUITheme = "ui_theme";
const storageKeyForUISounds = "ui_sounds";

export const ShelfiveEnvContextProvider = ({ children }) => {
  const initialUITheme = getFromStorage(storageKeyForUITheme) ?? UI_THEME;
  const [currentUITheme, setCurrentUITheme] = useState(initialUITheme);
  const [searchFilterOpen, setSearchFilterOpen] = useState(!isMobile);
  const [backUrl, setBackUrl] = useState();
  const initialSoundsState = getFromStorage(storageKeyForUISounds) ?? "play";
  const [soundsEnabled, setSoundsEnabled] = useState(initialSoundsState);

  const setUITheme = useCallback((selectedTheme) => {
    setCurrentUITheme(selectedTheme);
    saveToStorage(storageKeyForUITheme, selectedTheme);
  }, []);

  const setSounds = useCallback((sounds) => {
    setSoundsEnabled(sounds);
    saveToStorage(storageKeyForUISounds, sounds);
  }, []);

  const context = {
    currentUITheme,
    setUITheme,
    searchFilterOpen,
    setSearchFilterOpen,
    backUrl,
    setBackUrl,
    soundsEnabled,
    setSounds,
  };

  return (
    <ShelfiveEnvContext.Provider value={context}>
      {children}
    </ShelfiveEnvContext.Provider>
  );
};

export default ShelfiveEnvContext;
