import { useState, useCallback } from "react";
import useApi from "./use-api";

const useShelfiveUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const invokeApi = useApi(setIsLoading, setError);

  const createUser = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/create-user",
        method: "POST",
        query: query,
      });
    },
    [invokeApi]
  );

  const createApiKey = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/create-api-key",
        method: "POST",
        query: query,
        auth: true,
      });
    },
    [invokeApi]
  );

  const activateUser = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/activate-user",
        method: "GET",
        query: query,
      });
    },
    [invokeApi]
  );

  const changePassword = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/change-password",
        method: "POST",
        query: query,
        auth: true,
      });
    },
    [invokeApi]
  );

  const resetPassword = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/reset-password",
        method: "POST",
        query: query,
      });
    },
    [invokeApi]
  );

  const forgotPassword = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/forgot-password",
        method: "POST",
        query: query,
      });
    },
    [invokeApi]
  );

  const getUserInfo = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/get-user-info",
        method: "GET",
        query: query,
        auth: true
      });
    },
    [invokeApi]
  );
  
  const modifyUser = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/modify-user",
        method: "POST",
        query: query,
        auth: true
      });
    },
    [invokeApi]
  );

  const deleteUser = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/delete-user",
        method: "POST",
        query: query,
        auth: true
      });
    },
    [invokeApi]
  );

  return {
    isLoading,
    error,
    createUser,
    activateUser,
    changePassword,
    resetPassword,
    forgotPassword,
    getUserInfo,
    modifyUser,
    deleteUser,
    createApiKey
  };
};

export default useShelfiveUser;
