import { useTranslation } from "react-i18next";

const SfInvalid = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full flex align-items-center justify-content-center text-color-secondary text-center">
      <div><i className="pi pi-exclamation-circle text-lg" /> {t("invalid_data_message")}</div>
    </div>
  );
};

export default SfInvalid;
