import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Menu } from "primereact/menu";

const SfMenu = forwardRef((props, ref) => {
  const { t } = useTranslation("buttons");
  const tItems = props.model.map((item) => {
    return { ...item, label: t(item.label) };
  });
  const i18Props = { ...props, model: tItems };
  const classes = props.styling ? props.styling : "sf-menu";

  return <Menu {...i18Props} className={classes} ref={ref} />;
});

export default SfMenu;
