import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ShelfiveAuthContext from "../../../contexts/shelfive-auth";
import useShelfiveCommon from "../../../hooks/use-shelfive-common";
import { getCurrentLanguage } from "../../../scripts/sw-utils";
import SfLoading from "../../ui/SfLoading";
import SfError from "../../ui/SfError";
import SfBadgeCard from "./SfBadgeCard";
import SfTooltip from "../../ui/wrappers/SfTooltip";

let badgesAnag = {};

const SfUserBadges = ({ selfMode = true, userBadges = []}) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = getCurrentLanguage(i18n);
  const { getConfigList, isLoading, error } = useShelfiveCommon();
  const authCtx = useContext(ShelfiveAuthContext);
  const { badges } = selfMode ? { ...authCtx.userData } : userBadges;
  const [badgeList, setBadgeList] = useState([]);

  useEffect(() => {
    const _badges = async () => {
      const json = await getConfigList({
        list_type: "badges",
        list_lang: currentLanguage,
      });

      setBadgeList(json);
      badgesAnag[currentLanguage] = json;
    };

    if (badgesAnag[currentLanguage]) {
      setBadgeList(badgesAnag[currentLanguage]);
    } else {
      _badges();
    }
  }, [currentLanguage, getConfigList]);

  if (isLoading) {
    return selfMode ? <SfLoading mode="corner" /> : null;
  }

  if (error) {
    return selfMode ? (
      <SfError mode="screen" error={{ code: "e_err_000" }} />
    ) : null;
  }

  if (badgeList.length === 0) return;
  if (!selfMode && !userBadges) return;

  if (selfMode) {
    return (
      <div className="p-2 md:p-5">
        <div className="mb-3">{t("your_badge_title")}:</div>
        {badgeList.map((badge) => {
          return (
            <SfBadgeCard key={badge.badge_id} badge={badge} achieved={badges} />
          );
        })}
        <div className="pt-1 text-sm text-color-secondary">
          <i className="pi pi-question-circle pr-1" />
          {t("badges_assigned_once_day")}
        </div>
      </div>
    );
  } else {
    return (
      <>
        <SfTooltip target=".user-badge-icon" styling="font-light" />
        {userBadges.map((userBadge) => {
          const badge = badgeList.filter(
            (b) => b.badge_id === userBadge.badge_id
          )[0];
          if (badge) {
            return (
              <i
                key={userBadge.badge_id}
                className={
                  "user-badge-icon fadein animation-duration-1000 mr-2 font-bold pi " +
                  badge.badge_pic
                }
                data-pr-tooltip={badge.badge_name}
                data-pr-position="left"
              />
            );
          } else {
            return null;
          }
        })}
      </>
    );
  }
};

export default SfUserBadges;
