import { Rating } from "primereact/rating";

const SfRating = (props) => {
  return (
    <Rating
      {...props}
      cancel={false}
      onIcon={<span className="text-primary text-2xl">&#x2B1F;</span>}
      offIcon={<span className="text-primary text-2xl">&#x2B20;</span>}
      className="sf-rating"
    />
  );
};

export default SfRating;
