import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useShelfiveGames from "../../../hooks/use-shelfive-games";
import useRoles from "../../../hooks/use-roles";
import useNavigation from "../../../hooks/use-navigation";
import useDate from "../../../hooks/use-date";
import SfNoLogin from "../../ui/SfNoLogin";
import SfLoading from "../../ui/SfLoading";
import SfError from "../../ui/SfError";
import { getRoundedScorePercent } from "../../../scripts/sw-games";

const gameId = 1;
const limit = 5;

const SfRouteScores = () => {
  const { t } = useTranslation();
  const { isLogged, userLink } = useRoles();
  const { routes, goTo } = useNavigation();
  const {
    gameScores,
    isLoading: userLoading,
    error: userError,
  } = useShelfiveGames();
  const {
    gameAllScores,
    isLoading: allLoading,
    error: allError,
  } = useShelfiveGames();
  const [userScores, setUserScores] = useState();
  const [allScores, setAllScores] = useState();
  const { i18date } = useDate();

  useEffect(() => {
    const _fetch = async () => {
      const json = await gameScores({ game_id: gameId, limit: limit });
      if (json) {
        setUserScores(json);
      }
    };

    if (!userScores) {
      _fetch();
    }
  }, [gameScores, userScores]);

  useEffect(() => {
    const _fetch = async () => {
      const json = await gameAllScores({ game_id: gameId, limit: limit });
      if (json) {
        setAllScores(json);
      }
    };

    if (!allScores) {
      _fetch();
    }
  }, [gameAllScores, allScores]);

  const backToHomeHandler = () => {
    goTo(routes.games);
  };

  const displayBackToHomeDiv = (
    <i
      className="pi pi-arrow-left text-2xl pr-2 pl-1 pt-1 cursor-pointer"
      title={t("back_to_home")}
      onClick={backToHomeHandler}
      style={{ color: "#F59E0B" }}
    />
  );

  const displayScore = (score, idx, mode) => {
    const isMine = score.link_user === userLink;

    return (
      <div
        key={idx}
        className="shadow-3 flex mb-2 p-0 align-items-center w-16rem"
      >
        <div className="flex align-items-center justify-content-center mr-3 ml-2 text-color-secondary">
          {idx + 1}.
        </div>
        <div className="font-bold text-3xl mr-4 text-primary">{score.play_score_user}</div>
        <div className="flex flex-column flex-grow-1 align-items-end mr-2">
          <div className="font-semibold">
            {getRoundedScorePercent(score.play_score_perc)}%
            <span className="font-light ml-2">({score.play_score_max})</span>
          </div>
          <div className="text-sm font-light">
            {mode === "user" && i18date(score.play_create, "year-month-day")}
            {mode === "all" && <span>{isMine ? t("its_my_record") : score.user_name}</span>}
          </div>
        </div>
      </div>
    );
  };

  if (!isLogged) {
    return <SfNoLogin />;
  }

  return (
    <>
      <div className="mt-2 ml-2 flex align-items-center">
        {displayBackToHomeDiv}
        <span className="font-bold ml-1">{t("highest_scores")}</span>
      </div>
      {(userLoading || allLoading) && <SfLoading />}
      <div className="flex flex-column align-items-center mt-4 md:mt-0">
        <div className="mb-1 text-sm font-semibold">{t("my_scores")}:</div>
        {userScores?.map((score, idx) => {
          return displayScore(score, idx, "user");
        })}
      </div>
      {userScores?.length === 0 && (
        <div className="mt-5 mb-5 font-light flex justify-content-center">{t("no_plays_so_far")}</div>
      )}
      <div className="flex flex-column align-items-center">
        <div className="mt-4 mb-1 text-sm font-semibold">{t("all_scores")}:</div>
        {allScores?.map((score, idx) => {
          return displayScore(score, idx, "all");
        })}
      </div>
      {userError && <SfError error={userError} />}
      {allError && <SfError error={allError} />}
    </>
  );
};

export default SfRouteScores;
