import { useState } from "react";
import SfButton from "../../ui/wrappers/SfButton";
import SfDiv from "../../ui/wrappers/SfDiv";
import SfDialog from "../../ui/wrappers/SfDialog";
import SfReviewForm from "./SfReviewForm";

const SfReviewHandler = (props) => {
  const { mode, gtin, isNew, reviewLink } = { ...props };
  const [formVisible, setFormVisible] = useState(false);

  const openReviewForm = () => {
    setFormVisible(true);
  };

  return (
    <>
      {mode === "add" && (
        <div className="flex flex-grow-1 justify-content-end">
          <SfButton
            icon="pi pi-pencil"
            label="create_review"
            onClick={openReviewForm}
          />
        </div>
      )}
      {mode === "modify" && (
        <div className="flex flex-grow-1 justify-content-end pr-2">
        <SfDiv
          styling="sf-underline text-sm"
          click={openReviewForm}
          label="modify_your_review"
        /></div>
      )}
      <SfDialog
        header={
          mode === "add"
            ? isNew
              ? "header_form_create_review_newprd"
              : "header_form_create_review"
            : "header_form_modify_review"
        }
        visible={formVisible}
        onHide={() => {
          setFormVisible(false);
        }}
      >
        <SfReviewForm gtin={gtin} reviewLink={reviewLink} />
      </SfDialog>
    </>
  );
};

export default SfReviewHandler;
