import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { useHtml5QrCode } from "react-html5-qrcode-reader";
import useNavigation from "../../hooks/use-navigation";
import { isValidGtin } from "../../scripts/sw-utils";
import SfButton from "../ui/wrappers/SfButton";

let scanner;

const SfBarCodeScanner = ({ onHide, visible }) => {
  const { t } = useTranslation();
  const [scannerError, setScannerError] = useState();
  const { routes, goTo } = useNavigation();
  const { Html5Qrcode } = useHtml5QrCode(
    process.env.PUBLIC_URL + "/js/html5-qrcode.min.v2.3.8.js"
  );

  const closeScanner = useCallback(
    (gtin) => {
      if (scannerError) {
        scanner = undefined;
        onHide(false);
      } else if (scanner) {
        scanner
          ?.stop()
          .then(() => {
            scanner = undefined;
            onHide(false);

            if (gtin && isValidGtin(gtin)) {
              goTo(routes.product + "/" + gtin, {
                state: { fromScanner: true },
              });
            }
          })
          .catch(() => {});
      } else {
        onHide(false);
      }
    },
    [onHide, goTo, routes.product, scannerError]
  );

  const onScanSuccess = useCallback(
    (decoded, result) => {
      const format = result?.result?.format?.formatName;
      const gtin = result?.result?.text;

      if (format === "EAN_13" || format === "EAN_8") {
        if (isValidGtin(gtin)) {
          closeScanner(gtin);
        }
      }
    },
    [closeScanner]
  );

  useEffect(() => {
    if (Html5Qrcode && visible) {
      scanner = new Html5Qrcode("reader", { formatsToSupport: [9, 10] });
      const config = {
        fps: 10,
        defaultZoomValueIfSupported: 1.2,
        qrbox: { width: 240, height: 240 },
      };
      scanner
        ?.start(
          { facingMode: isMobile ? "environment" : "user" },
          config,
          onScanSuccess
        )
        .catch((err) => {
          setScannerError(err.message ?? err);
        });
    }
  }, [Html5Qrcode, visible, onScanSuccess]);

  return (
    <div>
      <div className="text-right">
        <SfButton
          styling="p-button-rounded mb-1"
          onClick={closeScanner}
          icon="pi pi-times"
        />
      </div>
      {scannerError && (
        <div className="h-full flex align-items-center justify-content-center text-center font-semibold">
          {scannerError}
        </div>
      )}
      <div
        id="reader"
        style={{
          width: isMobile && window.innerWidth < 500 ? "100%" : "500px",
        }}
      ></div>
      {!scannerError && (
        <div className="text-sm font-light pt-2 pb-0 mb-0">
          {t("code_scanner_message")}
        </div>
      )}
    </div>
  );
};

export default SfBarCodeScanner;
