import { useState, useCallback } from "react";
import useApi from "./use-api";

const useShelfiveReview = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const invokeApi = useApi(setIsLoading, setError);

  const getReviewList = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/get-review-list",
        method: "GET",
        query: query,
        auth: true,
      });
    },
    [invokeApi]
  );

  const createReview = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/create-review",
        method: "POST",
        query: query,
        auth: true,
      });
    },
    [invokeApi]
  );

  const modifyReview = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/modify-review",
        method: "POST",
        query: query,
        auth: true,
      });
    },
    [invokeApi]
  );

  const deleteReview = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/delete-review",
        method: "POST",
        query: query,
        auth: true,
      });
    },
    [invokeApi]
  );

  const createReviewWarning = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/create-review-warning",
        method: "POST",
        query: query,
        auth: true,
      });
    },
    [invokeApi]
  );

  const createAnswer = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/create-answer",
        method: "POST",
        query: query,
        auth: true,
      });
    },
    [invokeApi]
  );

  const deleteAnswer = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/delete-answer",
        method: "POST",
        query: query,
        auth: true,
      });
    },
    [invokeApi]
  );

  return {
    isLoading,
    error,
    getReviewList,
    createReview,
    modifyReview,
    deleteReview,
    createReviewWarning,
    createAnswer,
    deleteAnswer,
  };
};

export default useShelfiveReview;
