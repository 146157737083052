import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useShelfiveGames from "../../../../hooks/use-shelfive-games";
import useNavigation from "../../../../hooks/use-navigation";
import { getRoundedScorePercent } from "../../../../scripts/sw-games";
import {
  getName,
  getNameLength,
  highlightPicked,
  sortByLength,
} from "../../../../scripts/sw-games";

const SfGameResults = ({
  gameId,
  score,
  maxScore,
  scorePercent,
  products,
  picked,
}) => {
  const { gameScores } = useShelfiveGames();
  const [userRecord, setUserRecord] = useState();
  const { t } = useTranslation();
  const { routes, goTo } = useNavigation();
  const roundedPercent = getRoundedScorePercent(scorePercent);
  const longestProducts = sortByLength(highlightPicked(products, picked));

  const highestScoresHandler = () => {
    goTo(routes.scores);
  };

  useEffect(() => {
    const getRecord = async () => {
      const json = await gameScores({ game_id: gameId });
      if (json) {
        setUserRecord(json?.[0]?.play_score_user);
      }
    };

    if (!userRecord) {
      getRecord();
    }
  }, [gameId, gameScores, userRecord]);

  const displayIsNewRecord = () => {
    const highestDiv = (
      <span
        onClick={highestScoresHandler}
        className="sf-underline ml-1 text-sm white-space-nowrap cursor-pointer"
      >
        {t("see_records")}
      </span>
    );

    if (!!userRecord && score > userRecord) {
      return (
        <div className="ml-1 text-lg font-normal">
          {t("its_new_record")} ({t("previous_record_was")}: {userRecord}).
          {highestDiv}
        </div>
      );
    } else if (!!userRecord && score < userRecord) {
      return (
        <div className="ml-1 text-sm">
          {t("its_not_a_record")} ({t("your_record_is")}
          <span className="font-semibold ml-1">{userRecord}</span>).{highestDiv}
        </div>
      );
    } else if (!!userRecord && score === userRecord) {
      return (
        <div className="ml-1 text-sm">
          {t("its_same_record")}
          {highestDiv}
        </div>
      );
    }
  };

  return (
    <>
      <div className="mt-4 mb-1 ml-1 md:flex md:align-items-center">
        <div className="mr-3 flex align-items-center">
          {t("your_score")}:
          <span className="font-bold text-2xl ml-1">{score}</span>
        </div>
        <div className="mr-3 flex align-items-center">
          {t("max_score_was")}:
          <span className="font-bold text-2xl ml-1">{maxScore}</span>
        </div>
        <div className="mr-3 flex align-items-center">
          {roundedPercent === 100 ? t("score_percent_100") : t("score_percent")}
          :<span className="font-bold text-2xl ml-1">{roundedPercent} %</span>
        </div>
      </div>
      {displayIsNewRecord()}
      <div className="mt-3 mb-6 md:mb-4 ml-1">
        <div className="mb-1">{t("in_this_game_play")}:</div>
        <ul className="pl-4 m-0">
          {longestProducts?.map((product) => {
            return (
              <li
                className={
                  product.picked
                    ? "text-primary font-medium text-sm"
                    : "font-light text-sm"
                }
                key={product.product_gtin}
              >
                {getName(product)}
                <span className="text-sm ml-1">({getNameLength(product)})</span>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default SfGameResults;
