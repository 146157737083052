import { useState } from "react";
import { useTranslation } from "react-i18next";
import SfButton from "../../ui/wrappers/SfButton";
import SfDialog from "../../ui/wrappers/SfDialog";
import SfBarCodeScanner from "../../misc/SfBarCodeScanner";

const SfMobileBar = () => {
  const { t } = useTranslation();
  const [showScanner, setShowScanner] = useState(false);

  const openScanner = () => {
    setShowScanner(true);
  };

  return (
    <>
      <div className="absolute bottom-0 left-0 w-12">
        <SfButton
          styling="w-12 pt-2 pb-2 border-none"
          icon="pi pi-camera"
          onClick={openScanner}
        />
        <div
          style={{ marginBottom: "0.35rem" }}
          className="absolute right-0 bottom-0 text-sm mr-2 text-white text-right opacity-40 line-height-1"
          onClick={openScanner}
        >
          {t("mobile_bar_scan_1")}
          <br />
          {t("mobile_bar_scan_2")}
        </div>
      </div>
      <SfDialog
        styling="sf-dialog-nohead w-full md:w-auto"
        visible={showScanner}
        closable={false}
        showHeader={false}
      >
        <SfBarCodeScanner visible={showScanner} onHide={setShowScanner} />
      </SfDialog>
    </>
  );
};

export default SfMobileBar;
