import { forwardRef } from "react";
import { OverlayPanel } from "primereact/overlaypanel";

const SfOverlay = forwardRef((props, ref) => {
  const classes = props.styling ? props.styling : "";

  return <OverlayPanel {...props} className={classes} ref={ref}>{props.children}</OverlayPanel>;
});

export default SfOverlay;
