import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import useNavigation from "../../../hooks/use-navigation";
import SfRouteHome from "../../routes/SfRouteHome";
import SfRouteSearch from "../../routes/SfRouteSearch";
import SfRouteProduct from "../../routes/SfRouteProduct";
import SfRouteProfile from "../../routes/auth/SfRouteProfile";
import SfRouteShare from "../../routes/auth/SfRouteShare";
import SfRouteLists from "../../routes/auth/SfRouteLists";
import SfRouteListItems from "../../routes/SfRouteListItems";
import SfReviewList from "../../app/review/SfReviewList";
import SfRouteUserInfo from "../../routes/SfRouteUserInfo";
import SfFAQ from "../../misc/SfFAQ";
import SfResetPassword from "../../auth/SfResetPassword";
import SfActivateAccount from "../../auth/SfActivateAccount";
import SfRouteGames from "../../routes/auth/SfRouteGames";
import SfGamefive from "../../app/game/SfGamefive";
import SfRouteScores from "../../routes/auth/SfRouteScores";
import SfRouteSEO from "../../routes/SfRouteSEO";

const SfCanvass = () => {
  const location = useLocation();
  const { routes } = useNavigation();
  
  return (
    <div className="flex-grow-1 p-0 md:p-2 overflow-auto mt-1">
      <Routes>
        <Route path="/" element={<SfRouteHome key={location.key} />} />
        <Route path={routes.search} element={<SfRouteSearch key={location.key} />} />
        <Route path={routes.product + "/:gtin/:review"} element={<SfRouteProduct key={location.key} />} />
        <Route path={routes.product + "/:gtin"} element={<SfRouteProduct key={location.key} />} />
        <Route path={routes.random} element={<SfRouteProduct random key={location.key} />} />
        <Route path={routes.list + "/:link"} element={<SfRouteListItems key={location.key} />} />
        <Route path={routes.favorite} element={<SfRouteSearch favorite key={location.key} />} />
        <Route path={routes.reviews} element={<SfReviewList reviewType="myreview" key={location.key} />} />
        <Route path={routes.answers} element={<SfReviewList reviewType="myanswer" key={location.key} />} />
        <Route path={routes.profile} element={<SfRouteProfile key={location.key} />} />
        <Route path={routes.share} element={<SfRouteShare key={location.key} />} />
        <Route path={routes.lists} element={<SfRouteLists key={location.key} />} />
        <Route path={routes.games} element={<SfRouteGames key={location.key} />} />
        <Route path={routes.play} element={<SfGamefive key={location.key} />} />
        <Route path={routes.scores} element={<SfRouteScores key={location.key} />} />
        <Route path={routes.user + "/:link"} element={<SfRouteUserInfo key={location.key} />} />
        <Route path={routes.faq} element={<SfFAQ />} />
        <Route path={routes.reset + "/:link"} element={<SfResetPassword />} />
        <Route path={routes.activate + "/:link"} element={<SfActivateAccount />} />
        <Route path={routes.seo + "/:page"} element={<SfRouteSEO />} />
        <Route path={routes.seo} element={<SfRouteSEO />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
};

export default SfCanvass;
