import { useState, useCallback } from "react";
import useApi from "./use-api";

const useShelfiveLists = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const invokeApi = useApi(setIsLoading, setError);

  const listGet = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/lists-get",
        method: "GET",
        query: query,
        auth: true,
      });
    },
    [invokeApi]
  );

  const listCreate = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/lists-manage",
        method: "POST",
        query: query,
        auth: true,
      });
    },
    [invokeApi]
  );

  const listDelete = useCallback(
    async (query) => {
      return await invokeApi({
        endpoint: "/lists-delete",
        method: "POST",
        query: query,
        auth: true,
      });
    },
    [invokeApi]
  );

  const listAddProduct = useCallback(
    async (list_link, product_gtin) => {
      return await invokeApi({
        endpoint: "/lists-add-product",
        method: "POST",
        query: {list_link, product_gtin},
        auth: true,
      });
    },
    [invokeApi]
  );

  const listRemoveProduct = useCallback(
    async (list_link, product_gtin) => {
      return await invokeApi({
        endpoint: "/lists-remove-product",
        method: "POST",
        query: {list_link, product_gtin},
        auth: true,
      });
    },
    [invokeApi]
  );

  return {
    isLoading,
    error,
    listGet,
    listCreate,
    listDelete,
    listAddProduct,
    listRemoveProduct,
  };
};

export default useShelfiveLists;
