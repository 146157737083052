import { memo } from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import useNavigation from "../../../hooks/use-navigation";
import useRoles from "../../../hooks/use-roles";
import useDate from "../../../hooks/use-date";
import SfUserLink from "../user/SfUserLink";
import SfReviewBadge from "./SfReviewBadge";
import SfButton from "../../ui/wrappers/SfButton";
import { SHOW_PICS } from "../../../scripts/sw-utils";
import imgNotFoundProduct from "../../../resources/img/picture_not_found.png";

const SfReviewCard = ({
  review,
  mode = "product",
  warningHandler,
  answerHandler,
}) => {
  const { canAnswer, canReportReview, canReportAnswer } = useRoles();
  const { t } = useTranslation();
  const { routes, goTo } = useNavigation();
  const { i18date } = useDate();
  const modeProduct = mode === "product";
  const modeUser = mode === "user";
  const modeMyReview = mode === "myreview";
  const showPics = SHOW_PICS && (modeMyReview || modeUser);

  const {
    review_link,
    review_stars,
    review_user_name,
    review_link_user,
    is_review_author,
    review_create,
    review_text,
    review_approved,
    review_rejected,
    review_ans_user_name,
    review_ans_link_user,
    is_answer_author,
    review_ans_create,
    review_ans_text,
    review_ans_approved,
    review_ans_rejected,
    product_name,
    product_gtin,
    product_pic_url,
    brand_name,
    brand_name_2,
    // brand_name_3,
  } = { ...review };

  const userCanAswer = canAnswer(review);
  const userCanReportReview = canReportReview(review);
  const userCanReportAnswer = canReportAnswer(review) && !userCanAswer;

  const goToProduct = (gtin) => {
    goTo(routes.product + "/" + gtin);
  };

  const displayReviewText = (text) => {
    if (text === "[under approval]") {
      return (
        <div className="text-sm font-light text-color-secondary pl-1 pr-3">
          {t("text_under_approval")}
        </div>
      );
    } else if (text === "[removed]") {
      return (
        <div className="text-sm font-light text-color-secondary pl-1 pr-3 flex align-items-center">
          <i className="pi pi-ban pr-1" />
          {t("text_removed")}
        </div>
      );
    } else if (text) {
      return (
        <div className="text-color-secondary pl-1 pr-3">
          <span className="text-lg font-medium">&ldquo;</span>
          <span style={{ whiteSpace: "pre-wrap" }}>{text}</span>
          <span className="text-lg font-medium">&rdquo;</span>
        </div>
      );
    } else {
      return (
        <div className="text-color-secondary text-sm font-light pl-1 pr-3 pb-1">
          {t("no_comment_left")}
        </div>
      );
    }
  };

  const getUnderApprovalMessage = (isAuthor, isApproved, isRejected) => {
    if (isAuthor && isRejected) {
      return (
        <span className="font-semibold text-sm pl-2">
          ({t("text_rejected")})
        </span>
      );
    }
    if (isAuthor && !isApproved) {
      return (
        <span className="font-semibold text-sm pl-2">
          ({t("text_under_approval")})
        </span>
      );
    }
  };

  const getProductName = () => {
    return product_name?.length > 0
      ? product_name
      : "(" +
          t("noname_product") +
          ")" +
          (brand_name ? " " + brand_name : "") +
          (brand_name_2 ? " (" + brand_name_2 + ")" : "");
  };

  const displayProductName = () => {
    if (modeMyReview || modeUser) {
      return (
        <div
          className="font-medium pl-1 pr-2 sf-underline"
          onClick={(e) => {
            goToProduct(product_gtin);
          }}
        >
          {getProductName()}
        </div>
      );
    }
  };

  const displayReportLink = (show, type) => {
    if (modeProduct && show) {
      return (
        <div
          className="absolute right-0 bottom-0 pb-2 pr-2 text-sm sf-underline"
          onClick={() => {
            warningHandler(review_link, type);
          }}
        >
          {t("report_abuse")}
        </div>
      );
    }
  };

  const displayReviewDate = (date) => {
    return (
      <span className="text-sm pl-2 font-light white-space-nowrap">
        {i18date(date, "relative")}
      </span>
    );
  };

  const displayAnswerButton = () => {
    if (userCanAswer && modeProduct) {
      return (
        <div className="text-right mb-1">
          <SfButton
            label={is_answer_author ? "modify_answer" : "answer_review"}
            icon="pi pi-pencil"
            onClick={() => answerHandler(review)}
          />
        </div>
      );
    }
  };

  const displayProductPic = () => {
    if (showPics) {
      return (
        <div className="flex shadow-7 w-4rem h-4rem border-round-md relative ml-1 mr-1 mb-1 md:mt-1">
          <img
            src={product_pic_url || imgNotFoundProduct}
            className="w-4rem h-4rem surface-200 border-round-md"
            alt={product_gtin}
            style={{ aspectRatio: "1/1", objectFit: "cover" }}
          />
        </div>
      );
    }
  }

  return (
    <div
      className={
        "mb-2 ml-2 mr-2 pt-2 pl-1 pr-1 pb-0 sf-reviewcard flex" +
        (isMobile ? " sf-item-mobile" : " shadow-1")
      }
    >
      {displayProductPic()}
      <div className="flex-grow-1">
        <div className={(showPics ? "mt-0" : "mt-2") + " mb-1 md:mb-2 relative"}>
          {displayProductName()}
          <SfReviewBadge n={review_stars} mode="star-only" />
          {modeProduct && (
            <SfUserLink
              name={review_user_name}
              link={review_link_user}
              self={is_review_author}
            />
          )}
          {displayReviewDate(review_create)}
          {getUnderApprovalMessage(
            is_review_author,
            review_approved,
            review_rejected
          )}
          {displayReportLink(userCanReportReview, "review")}
          {displayReviewText(review_text)}
        </div>
        {review.review_ans_text && (
          <div className="mt-2 relative">
            {is_answer_author && (
              <span className="pl-1 pr-1 text-sm font-medium">
                {t("answer")}:
              </span>
            )}
            {!is_answer_author && (
              <span className="pl-1 pr-1 text-sm font-medium ">
                {t("answer_by")}
              </span>
            )}
            <SfUserLink
              name={review_ans_user_name}
              link={review_ans_link_user}
              self={is_answer_author}
            />
            {displayReviewDate(review_ans_create)}
            {getUnderApprovalMessage(
              is_answer_author,
              review_ans_approved,
              review_ans_rejected
            )}
            {displayReportLink(userCanReportAnswer, "answer")}
            {displayReviewText(review_ans_text)}
          </div>
        )}
        {displayAnswerButton()}
      </div>
    </div>
  );
};

export default memo(SfReviewCard, hasSameLink);

function hasSameLink(prevReview, nextReview) {
  return prevReview.review_link === nextReview.review_link;
}
