import { useEffect, useContext } from "react";
import { isMobile } from "react-device-detect";
import ShelfiveAuthContext from "./contexts/shelfive-auth";
import ShelfiveEnvContext from "./contexts/shelfive-env";
import useResize from "./hooks/use-resize";
import { applyUITheme } from "./scripts/sw-utils";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./App.css";

import SfHeader from "./components/layout/header/SfHeader";
import SfCanvass from "./components/layout/canvass/SfCanvass";
import SfMobileBar from "./components/layout/footer/SfMobileBar";
import SfAbout from "./components/layout/about/SfAbout";

let firstRender = 0;

function App() {
  // Autologin
  const authCtx = useContext(ShelfiveAuthContext);

  useEffect(() => {
    if (firstRender === 0) {
      authCtx.autologin();
    }
    firstRender = 1;
  }, [authCtx]);

  // Apply UI theme
  const envCtx = useContext(ShelfiveEnvContext);
  const currentUITheme = envCtx.currentUITheme;

  useEffect(() => {
    applyUITheme(currentUITheme);
  }, [currentUITheme]);

  const dimensions = useResize();
  const windowHeight = dimensions.height - (isMobile ? 0 : 1) ?? "100vh";
  
  return (
    <div
      style={{ height: windowHeight }}
      className="flex flex-column w-screen surface-ground"
    >
      <SfHeader />
      <SfCanvass />
      {isMobile && <SfMobileBar />}
      <SfAbout />
    </div>
  );
}

export default App;
