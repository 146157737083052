import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useNavigation from "../../../hooks/use-navigation";
import useShelfiveReview from "../../../hooks/use-shelfive-review";
import useMessage from "../../../hooks/use-message";
import SfFormField from "../../ui/wrappers/SfFormField";
import SfButton from "../../ui/wrappers/SfButton";
import SfTextarea from "../../ui/wrappers/SfTextarea";
import SfRating from "../../ui/wrappers/SfRating";
import SfError from "../../ui/SfError";
import SfConfirm from "../../ui/wrappers/SfConfirm";

const SfReviewForm = (props) => {
  const { gtin, reviewLink } = { ...props };
  const [deleteMode, setDeleteMode] = useState(false);
  const [form, setForm] = useState({
    product_gtin: gtin,
    review_link: reviewLink,
  });
  const { t } = useTranslation();
  const { routes, goTo } = useNavigation();
  const {
    createReview,
    getReviewList,
    modifyReview,
    deleteReview,
    isLoading,
    error,
  } = useShelfiveReview();
  const { messageTag, showMessage } = useMessage();

  useEffect(() => {
    const _fetch = async () => {
      const json = await getReviewList({ review_link: reviewLink });
      if (json?.results?.[0]?.review_stars) {
        const review = json.results[0];
        setForm((prev) => ({
          ...prev,
          review_stars: review.review_stars,
          review_desc: review.review_text,
        }));
      }
    };

    if (reviewLink) {
      _fetch();
    }
  }, [getReviewList, reviewLink]);

  const setValue = (e) => {
    setForm((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const saveReviewHandler = async (e) => {
    e.preventDefault();

    const json = reviewLink
      ? await modifyReview(form)
      : await createReview(form);

    if (json?.review_link) {
      showMessage("msg_review_creation", "msg_review_inserted");
      goTo(routes.product + "/" + gtin);
    }
  };

  const openDeleteConfirm = () => {
    setDeleteMode(true);
  };

  const deleteReviewHandler = async () => {
    setDeleteMode(false);
    const json = await deleteReview({ review_link: reviewLink });

    if (json?.operation === "deleted") {
      showMessage("msg_review_deletion", "msg_review_deleted");
      goTo(routes.product + "/" + gtin);
    }
  };

  return (
    <>
      <form onSubmit={saveReviewHandler} className="">
        <div className="flex justify-content-between">
          <SfFormField mandatory id="review_stars" label="review_star_label">
            <SfRating
              id="review_stars"
              value={form.review_stars ?? ""}
              onChange={setValue}
              disabled={deleteMode}
            />
          </SfFormField>
          {reviewLink && (
            <>
              <div
                onClick={openDeleteConfirm}
                className="justify-content-end pb-2 pr-2 text-right flex-grow-1 text-sm font-light sf-underline"
              >
                {t("delete_review")}
              </div>
              <SfConfirm
                header="confirm_delete_review_head"
                message="confirm_delete_review_msg"
                visible={deleteMode}
                onHide={setDeleteMode}
                onAccept={deleteReviewHandler}
              />
            </>
          )}
        </div>
        <SfFormField id="review_desc" label="review_text_label">
          <SfTextarea
            id="review_desc"
            styling="w-auto md:w-30rem h-10rem"
            value={form.review_desc ?? ""}
            onChange={setValue}
            disabled={deleteMode}
          />
        </SfFormField>
        <div className="field text-left text-sm">
          {t("review_moderation_help")}
        </div>
        <div className="field text-right flex align-items-center justify-content-end">
          {error && <SfError error={error} />}
          <SfButton
            label="save_review"
            loading={isLoading}
            iconPos="right"
            type="submit"
            disabled={!form.review_stars || deleteMode}
          />
        </div>
      </form>
      {messageTag}
    </>
  );
};

export default SfReviewForm;
