import { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import SfSidebar from "../ui/wrappers/SfSidebar";
import SfA2hs from "../misc/SfA2hs";

const SfA2hsLink = () => {
  const [a2hsHelp, setA2hsHelp] = useState(false);
  const [displayMode, setDisplayMode] = useState("browser");
  const { t } = useTranslation();

  const a2hsHandler = () => {
    setA2hsHelp((prev) => !prev);
  };

  useEffect(() => {
    if (window.matchMedia("(display-mode: standalone)").matches) {
      setDisplayMode("standalone");
    }
  }, []);

  if (!isMobile || displayMode === "standalone") {
    return;
  }

  return (
    <>
      <div
        onClick={a2hsHandler}
        className="flex w-full m-0 p-0 mb-6 sf-linkactive align-items-center justify-content-center font-semibold text-base cursor-pointer"
      >
        <i className="pi pi-globe mr-1" />
        {t("link_a2hs")}
      </div>
      <SfSidebar
        visible={a2hsHelp}
        position="top"
        onHide={() => setA2hsHelp(false)}
        modal={false}
        dismissable
        icons={() => (
          <span className="w-full text-left font-bold text-lg">
            {t("help_a2hs_title")}
          </span>
        )}
        styling="h-auto"
      >
        <SfA2hs />
      </SfSidebar>
    </>
  );
};

export default SfA2hsLink;
