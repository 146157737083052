import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const useTitle = () => {
    const { t } = useTranslation();

    const setTitle = useCallback((title, detail) => {
        let pageTitle = title ? t(title) : "";

        if (detail) {
            pageTitle += (title ? ": " : "") + detail;
        }

        if (pageTitle.length === 0) {
            pageTitle = t("title_home");
        }

        document.title = pageTitle;
    }, [t]);

    return setTitle;
};

export default useTitle;