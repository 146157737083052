import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Badge } from "primereact/badge";
import useDate from "../../../hooks/use-date";
import { getCurrentLanguage } from "../../../scripts/sw-utils";
import imgDefaultStandardUser from "../../../resources/img/user_standard.png";
import imgDefaultBrandAdminUser from "../../../resources/img/user_brand_admin.png";
import imgDefaultSysAdminUser from "../../../resources/img/user_sysadmin.png";
import SfUserBadges from "./SfUserBadges";

const SfUserCard = ({ userInfo }) => {
  const { t, i18n } = useTranslation();
  const { i18date } = useDate();
  const currentLanguage = getCurrentLanguage(i18n);
  const { user_name, user_create, count_reviews_tot, badges } = userInfo;
  const country_name = userInfo["country_name_" + currentLanguage] ?? "";

  const getUserPic = () => {
    if (userInfo?.user_pic) {
      return userInfo.user_pic;
    } else if (userInfo?.user_profile === "sys admin") {
      return imgDefaultSysAdminUser;
    } else if (userInfo?.user_profile === "brand admin") {
      return imgDefaultBrandAdminUser;
    } else {
      return imgDefaultStandardUser;
    }
  };

  return (
    <div className="flex p-2 md:p-0 mb-5">
      <div className="w-10rem h-10rem shadow-5 mr-3">
        <img
          src={getUserPic()}
          alt="User pic"
          style={{
            width: "10rem",
            height: "10rem",
            aspectRatio: "1/1",
            objectFit: "cover",
          }}
        />
      </div>
      <div>
        <div className="md:flex align-content-center mb-2">
          <div className="text-primary font-semibold">{user_name}</div>
          <div className="ml-0 md:ml-2 mt-1 md:mt-0 sf-linkactive flex flex-row-reverse justify-content-end">
            <SfUserBadges selfMode={false} userBadges={badges} />
          </div>
        </div>
        <div className="mt-2">
          {t("user_created_lbl")} {i18date(user_create, "year-month")}
        </div>
        {country_name && (
          <div className="mt-2">
            {t("user_country_lbl")}: {country_name}
          </div>
        )}
        <div className="mt-2">
          {t("user_tot_rev_lbl")}:{" "}
          <Badge style={{ padding: "0" }} value={count_reviews_tot} />
        </div>
      </div>
    </div>
  );
};

export default memo(SfUserCard);
