import { useCallback } from "react";
import useFetch from "./use-fetch";
import { getFromStorage } from "../scripts/sw-utils";

const useApi = (setIsLoading, setError) => {
  const fetchData = useFetch();

  const _invokeApi = useCallback(
    async (props) => {
      setIsLoading && setIsLoading(true);

      const token = props.auth ? getFromStorage("access_token") : null;

      const json = await fetchData({
        ...props,
        token: token,
      });

      setIsLoading && setIsLoading(false);

      let error = null;
      if (!json || !!json.error_code || !!json.http_error_code) {
        error = {
          code: json.error_code,
          httpCode: json.http_error_code,
          params: json.error_params,
        };
      }
      setError && setError(error);

      return json;
    },
    [fetchData, setIsLoading, setError]
  );

  return _invokeApi;
};

export default useApi;
