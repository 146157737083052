import { useState } from "react";
import useMessage from "../../../hooks/use-message";
import SfButton from "../../ui/wrappers/SfButton";
import SfDialog from "../../ui/wrappers/SfDialog";
import SfAddToListForm from "./SfAddToListForm";

const SfAddToListHandler = ({ gtin }) => {
  const { messageTag, showMessage } = useMessage();
  const [formVisible, setFormVisible] = useState(false);

  const openListsForm = () => {
    setFormVisible(true);
  };

  const onSaveHandler = () => {
    showMessage("list_item_added");
    setFormVisible(false);
  };

  return (
    <>
      <div className="flex">
        <SfButton
          icon="pi pi-list"
          onClick={openListsForm}
          styling="p-button-sm border-round-sm p-1 ml-1 mr-2"
          title="add_to_list"
        />
      </div>
      <SfDialog
        styling="max-w-24rem md:w-28rem h-26rem sf-dialog"
        header="header_list_items"
        visible={formVisible}
        onHide={setFormVisible}
      >
        <SfAddToListForm gtin={gtin} onSave={onSaveHandler} />
      </SfDialog>
      {messageTag}
    </>
  );
};

export default SfAddToListHandler;
