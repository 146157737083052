import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const useDate = () => {
  const { t } = useTranslation();

  const i18date = useCallback(
    (date, format) => {
      if (format === "relative") {
        const dateObj = new Date(date?.replace(/-/g, "/"));
        const now = new Date();
        const days = Math.floor(
          (Date.UTC(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate()) -
            Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())) /
            (1000 * 60 * 60 * 24)
        );

        if (days === 0) {
          return t("_date_today");
        } else if (days >= -30) {
          return t("_date_relative", { val: days });
        } else if (days >= -365) {
          const months =
            dateObj.getMonth() -
            now.getMonth() +
            12 * (dateObj.getFullYear() - now.getFullYear());

          return t("_date_relative_m", { val: months });
        } else {
          const years = dateObj.getFullYear() - now.getFullYear();
          return t("_date_relative_y", { val: years });
        }
      } else if (format === "year-month") {
        return t("_date_year_month", {
          val: new Date(date),
          formatParams: {
            val: { year: "numeric", month: "long" },
          },
        });
      } else if (format === "year-month-day") {
        return t("_date_year_month", {
          val: new Date(date),
          formatParams: {
            val: { year: "numeric", month: "numeric", day: "numeric" },
          },
        });
      }

      return date;
    },
    [t]
  );

  return { i18date };
};

export default useDate;
