import { useContext } from "react";
import { useTranslation } from "react-i18next";
import ShelfiveAuthContext from "../../../contexts/shelfive-auth";
import SfChip from "../../ui/wrappers/SfChip";

const SfUserRoles = () => {
  const { t } = useTranslation();
  const authCtx = useContext(ShelfiveAuthContext);
  const { roles } = { ...authCtx.userData };

  if (!roles) return;

  return (
    <div className="p-2">
      <div className="font-semibold">{t("you_have_special_roles")}</div>
      <div>
        {roles.map((role) => {
          return (
            <div key={role.role_id_brand} className="flex flex-column md:flex-row align-items-start mt-4 mb-4">
              <div className="pl-0 md:pl-2">
                <SfChip
                  label={role.brand_name}
                  image={role.brand_pic_small}
                />
              </div>
              <div className="pl-2 text-sm md:text-base">{t("role " + role.role_type)}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SfUserRoles;
