import { useState } from "react";
import { useTranslation } from "react-i18next";
import useShelfiveProduct from "../../../hooks/use-shelfive-product";
import SfTextarea from "../../ui/wrappers/SfTextarea";
import SfSelect from "../../ui/wrappers/SfSelect";
import SfButton from "../../ui/wrappers/SfButton";
import SfError from "../../ui/SfError";
import { UI_NOTE_COLORS } from "../../../scripts/sw-utils";

const SfProductCustomizeForm = ({
  gtin,
  customNotes,
  customColor,
  onSuccess,
}) => {
  const [form, setForm] = useState({
    product_gtin: gtin,
    product_notes: customNotes,
    note_color: customColor,
  });
  const { t } = useTranslation();
  const { customizeProduct, isLoading, error } = useShelfiveProduct();

  const setValue = (e) => {
    setForm((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const customizeProductHandler = async (e) => {
    e.preventDefault();
    const json = await customizeProduct(form);

    if (json?.operation === "updated") {
      onSuccess({ ...form });
    }
  };

  return (
    <form onSubmit={customizeProductHandler}>
      <div className="field text-left text-sm">
        {t("custom_note_help")}
        <SfTextarea
          id="product_notes"
          styling={"w-full h-6rem mt-1 sf-" + UI_NOTE_COLORS[form.note_color].label}
          value={form.product_notes ?? ""}
          onChange={setValue}
        />
      </div>
      <div className="field text-left text-sm">
        <div className="mb-1">{t("custom_note_color")}</div>
        <SfSelect
          id="note_color"
          value={form.note_color}
          options={UI_NOTE_COLORS}
          onChange={setValue}
          translate
        />
      </div>
      <div className="field text-right flex align-items-center justify-content-end mt-3">
        {error && <SfError error={error} />}
        <SfButton
          label="save_custom_note"
          loading={isLoading}
          iconPos="right"
          type="submit"
        />
      </div>
    </form>
  );
};

export default SfProductCustomizeForm;
