import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import SfErrorBoundary from "./components/error/SfErrorBoundary";
import "./scripts/i18n";

import { ShelfiveEnvContextProvider } from "./contexts/shelfive-env";
import { ShelfiveAuthContextProvider } from "./contexts/shelfive-auth";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <SfErrorBoundary>
    <ShelfiveAuthContextProvider>
      <ShelfiveEnvContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ShelfiveEnvContextProvider>
    </ShelfiveAuthContextProvider>
  </SfErrorBoundary>
);
