import { memo } from "react";
import { useTranslation } from "react-i18next";

const SfSorter = (props) => {
  const { t } = useTranslation();
  const { sortFields, sortHandler, currentSorting } = { ...props };

  if (!sortFields || sortFields.length === 0) return;

  return (
    <div className="text-sm white-space-nowrap flex align-items-center justify-content-end">
      {/* {t("sort_by")}: */}
      <i className="pi pi-sort-amount-down-alt mr-1" />
      {sortFields.map((field, idx) =>
        idx === currentSorting ? (
          <span key={idx} className="sf-linkactive font-bold ml-1 md:ml-2 mr-1 text-base">
            {t(field.label)}
          </span>
        ) : (
          <span
            key={idx}
            className="sf-underline cursor-pointer ml-1 md:ml-2 mr-1"
            onClick={() => {
              sortHandler(idx);
            }}
          >
            {t(field.label)}
          </span>
        )
      )}
    </div>
  );
};

export default memo(SfSorter);
