import { useEffect, useState, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import useShelfiveLists from "../../hooks/use-shelfive-lists";
import useShelfiveProduct from "../../hooks/use-shelfive-product";
import useRoles from "../../hooks/use-roles";
import useNavigation from "../../hooks/use-navigation";
import useTitle from "../../hooks/use-title";
import useMessage from "../../hooks/use-message";
import SfProductCard from "../app/product/SfProductCard";
import SfLoading from "../ui/SfLoading";
import SfError from "../ui/SfError";
import SfNodata from "../ui/SfNodata";
import SfButton from "../ui/wrappers/SfButton";

const defaultQuery = {
  list_link: "",
  limit: 100,
  row_count: 1,
  sorting: "oby_lists",
};

const SfRouteListItems = () => {
  const { isLogged } = useRoles();
  const { link } = useParams();
  const { routes, goTo, setBackUrl } = useNavigation();
  const { t } = useTranslation();
  const { messageTag, showMessage } = useMessage();
  const [listInfo, setListInfo] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const { listGet, isLoading, error } = useShelfiveLists();
  const { listCreate, isLoading: isCreating, error: createError } = useShelfiveLists();
  const {
    getProductList,
    isLoading: isLoadingProduct,
    error: errorProduct,
  } = useShelfiveProduct();
  const setTitle = useTitle();

  const theQuery = useMemo(() => {
    let query = { ...defaultQuery };
    query.list_link = link;

    return query;
  }, [link]);

  useEffect(() => {
    if (listInfo?.list_name) {
      setTitle("", listInfo?.list_name);
    }
    else {
      setTitle("title_listitems");
    }
  }, [setTitle, listInfo]);

  useEffect(() => {
    setBackUrl(routes.list + "/" + link);
  }, [setBackUrl, routes.list, link]);

  useEffect(() => {
    const _fetch = async () => {
      const json = await listGet({ list_link: link });

      if (json?.results) {
        setListInfo(json.results[0]);
      }
    };

    if (listInfo === null) {
      _fetch();
    }
  }, [listGet, link, listInfo]);

  useEffect(() => {
    const _fetch = async () => {
      const json = await getProductList(theQuery);

      if (json?.results) {
        setSearchResults(json);
      }
    };

    if (listInfo?.tot_products > 0) {
      _fetch();
    }
  }, [listInfo, getProductList, theQuery]);

  const goToProduct = useCallback(
    (e) => {
      const productGtin = e.currentTarget?.id?.substring(2) ?? e.gtin;
      if (productGtin) {
        goTo(routes.product + "/" + productGtin);
      }
    },
    [goTo, routes.product]
  );

  const bookmarkListHandler = async () => {
    const json = await listCreate({list_link: link, linked: 1});

    if (json?.updated === "ok") {
      showMessage("msg_list_bookmarked");
      setListInfo((prev) => ({...prev, list_linked: "y"}));
    }
  };

  if (isLoading) {
    return <SfLoading />;
  }

  if (error || errorProduct || createError) {
    return <SfError mode="screen" error={error ?? errorProduct ?? createError} />;
  }

  if (!listInfo) {
    return <SfNodata message="list_not_found" />;
  }

  const getMyListLink = () => {
    if (isLogged && listInfo?.list_owner === "y") {
      return (
        <div className="flex align-items-center ml-5 text-sm">
          <i className="pr-1 pi pi-external-link sf-linkactive" />
          <span className="sf-underline" onClick={() => goTo(routes.lists)}>
            {t("goto_lists_page")}
          </span>
        </div>
      );
    }
  };

  const getBookmarkBtn = () => {
    if (isLogged && (listInfo?.list_owner === "n") && (listInfo?.list_linked === "n")) {
      return (
        <div className="flex align-items-center ml-5 text-sm">
          <SfButton
          label="bookmark_list_btn"
          icon="pi pi-list"
          iconPos="left"
          onClick={bookmarkListHandler}
          loading={isCreating}
        />
        </div>
      );
    }
  };

  return (
    <div className="mt-0 ml-0 pl-0 mb-6">
      <div className="w-full flex justify-content-end pr-2 mb-2">
        {isMobile && <span className="pt-2 pr-2">{getMyListLink()}</span>}
        {isMobile && <span className="pt-2">{getBookmarkBtn()}</span>}
      </div>
      <div className="text-base font-medium flex align-items-center mb-3 ml-3">
        &#128205; {listInfo.list_name}
        {listInfo.user_name && (
          <span className="flex align-items-center pl-1 text-sm text-color-secondary">
            (<i className="pr-1 pi pi-user" />
            {listInfo.user_name})
          </span>
        )}
        :
        <span className="font-semibold text-primary text-lg pl-1 pr-1">
          {!isLoadingProduct && (searchResults?.row_count ?? 0)}
          {isLoadingProduct && <i className="pi pi-cog pi-spin" />}
        </span>
        {!isMobile && getMyListLink()}
        {!isMobile && getBookmarkBtn()}
      </div>
      {isLoadingProduct && <SfLoading />}
      {!isLoading && listInfo?.tot_products === 0 && (
        <SfNodata message="empty_list" />
      )}
      <div className="mt-2">
        {searchResults?.results?.map((product) => (
          <div key={product.product_gtin}>
            <SfProductCard
              mode="list"
              product={product}
              onClick={goToProduct}
              listLink={listInfo?.list_owner === "y" || listInfo?.list_writable === 1 ? link : null}
            />
          </div>
        ))}
      </div>
      {messageTag}
    </div>
  );
};

export default SfRouteListItems;
