import imgNotFoundProduct from "../../../../resources/img/picture_not_found.png";
import { getNameLength } from "../../../../scripts/sw-games";

const SfGamePickedProducts = ({ products, picked, max }) => {
  const displayPickedProduct = (n) => {
    if (n < picked?.length) {
        const product = products[picked[n]];

      return (
        <div
          key={n}
          className="flex shadow-7 w-4rem h-4rem border-round-md relative ml-2 mr-2"
        >
          <img
            src={product.product_pic_url || imgNotFoundProduct}
            className="w-4rem h-4rem surface-200 border-round-md zoomin"
            alt={product.product_gtin}
            style={{ aspectRatio: "1/1", objectFit: "cover" }}
          />
          <div className="absolute bottom-0 right-0 pb-0 pr-1 text-white font-semibold text-xl" style={{ textShadow: "0 0 2px #000000" }}
>{getNameLength(product)}</div>
        </div>
      );
    } else {
      return (
        <div
          key={n}
          className="flex shadow-1 w-4rem h-4rem border-round-md ml-2 mr-2 text-300 align-items-center justify-content-center text-2xl"
        >{n+1}</div>
      );
    }
  };

  return (
    <div className="flex justify-content-center mt-3 mb-3">
      {[...Array(max).keys()].map((i) => {
        return displayPickedProduct(i);
      })}
    </div>
  );
};

export default SfGamePickedProducts;
