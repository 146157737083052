import SfHeaderHome from "./SfHeaderHome";
import SfHeaderSearch from "./SfHeaderSearch";
import SfHeaderButtons from "./SfHeaderButtons";
import SfHeaderSettings from "./SfHeaderSettings";
import { isSEO } from "../../../scripts/sw-utils";

const SfHeader = () => {
  if (isSEO()) return;
  
  return (
    <div className="flex-shrink-0 shadow-2 p-0">
      <div className="flex">
        <SfHeaderHome />
        <SfHeaderSearch />
        <SfHeaderButtons />
        <SfHeaderSettings />
      </div>
    </div>
  );
};

export default SfHeader;
