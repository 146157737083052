import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useShelfiveUser from "../../hooks/use-shelfive-user";
import useTitle from "../../hooks/use-title";
import SfUserCard from "../app/user/SfUserCard";
import SfReviewList from "../app/review/SfReviewList";
import SfLoading from "../ui/SfLoading";
import SfNodata from "../ui/SfNodata";
import SfError from "../ui/SfError";

const SfRouteUserInfo = () => {
  const { link } = useParams();
  const [userInfo, setUserInfo] = useState();
  const setTitle = useTitle();
  const { getUserInfo, isLoading, error } = useShelfiveUser();

  useEffect(() => {
    setTitle("title_user", userInfo?.user_name);
  }, [setTitle, userInfo]);

  useEffect(() => {
    const _fetch = async () => {
      const json = await getUserInfo({ user_link: link });

      if (json?.[0]) {
        setUserInfo(json[0]);
      }
      else if (json?.results?.[0]?.user_link) {
        // for sys admins
        setUserInfo(json.results[0]);
      }
      else {
        setUserInfo("nouser");
      }
    };

    if (!userInfo) {
      _fetch();
    }
  }, [getUserInfo, link, userInfo]);

  if (!userInfo) return;

  if (isLoading) {
    return <SfLoading />;
  }

  if (error) {
    return <SfError mode="screen" error={error} />;
  }

  if (userInfo === "nouser") {
    return <SfNodata message="no_data_message" />;
  }

  return (
    <div className="p-1 md:p-3">
      <SfUserCard userInfo={userInfo} />
      <SfReviewList link={link} />
    </div>
  );
};

export default SfRouteUserInfo;
