import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import { APP_BUILD } from "./sw-utils";

i18next
  .use(initReactI18next)
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    backend: {
      queryStringParams: { v: APP_BUILD }
    },
    supportedLngs: ["en", "it"],
    fallbackLng: "en",
    nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false,
    },
    // react: {
    //   useSuspense: false,
    // },
    // debug:true
  });

export default i18next;
