import { Chip } from "primereact/chip";

const SfChip = (props) => {
  const classes = (props.styling ?? "") + " text-sm sf-chip white-space-nowrap";

  if (!props.label) return <></>;
  
  return <Chip {...props} className={classes} />;
};

export default SfChip;
