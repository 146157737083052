import { useTranslation } from "react-i18next";

const SfFormField = ({ id, icon, label, mandatory, children }) => {
  const { t } = useTranslation("buttons");

  return (
    <div className="field mb-2 mr-1">
      {label && (
        <div style={{ marginBottom: "0.15rem" }}>
          {mandatory && (
            <span className="vertical-align-top text-primary">*</span>
          )}
          <label className="text-sm" htmlFor={id}>
            {t(label)}
          </label>
          :
        </div>
      )}
      <div className="p-inputgroup">
        {icon && (
          <span className="p-inputgroup-addon">
            <i className={"pi " + icon}></i>
          </span>
        )}
        {children}
      </div>
    </div>
  );
};

export default SfFormField;
